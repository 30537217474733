<div class="kkm-mat-calendar-header">
  <div *ngIf="isYearOrMonthView" class="mat-calendar-controls">
    <button mat-button type="button" class="mat-calendar-period-button"
            (click)="goToPreviousView()" [attr.aria-label]="periodButtonLabel"
            [attr.aria-describedby]="_buttonDescriptionId"
            cdkAriaLive="polite">
      <span [attr.id]="_buttonDescriptionId">{{periodButtonText}}</span>
      <mat-icon [svgIcon]="'datepicker:triangle-black'" class="mat-calendar-controls__arrow-down"></mat-icon>
    </button>

    <div class="kkm-mat-calendar-header__buttons">
      <button mat-icon-button type="button" class="mat-calendar-previous-button"
              [disabled]="!previousEnabled()" (click)="previousClicked()"
              [attr.aria-label]="prevButtonLabel">
        <mat-icon [svgIcon]="'datepicker:arrow-left'" class="mat-calendar-previous-button__arrow-left"></mat-icon>
      </button>

      <button mat-icon-button type="button" class="mat-calendar-next-button"
              [disabled]="!nextEnabled()" (click)="nextClicked()"
              [attr.aria-label]="nextButtonLabel">
        <mat-icon [svgIcon]="'datepicker:arrow-right'" class="mat-calendar-next-button__arrow-right"></mat-icon>
      </button>
    </div>
  </div>
</div>
