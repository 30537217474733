import { Inject, Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, retry, takeUntil } from 'rxjs/operators';
import { EventBusService } from '../../../services/event-bus/event-bus.service';
import { ITaxpayer, IReceiptList, IReceipt } from '@common/interfaces';
import { ReceiptService } from '../../../services/receipt.service';
import {ReceiptGridService} from "../../../services/receipt-grid.service";
import {FdErrorFilter, SpecialErrors, VoucherListCRsType} from '@common/enums';
import { isEmpty } from 'lodash';

@Component({
  selector: 'kkm-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.styl']
})
export class ReceiptListComponent implements OnInit, OnDestroy {
  TRANSLATION_READ_PATH = 'common.components.stores-locator.receipt-list';
  receipts: IReceipt[];
  @Input() taxpayer: ITaxpayer;
  @Input() isOpened: boolean = false;
  @Input() cashRegisters: {id: string, sn: string}[];
  private polling$ = new Subject();
  private pollingDelay: number;
  private specialErrorSubscription: Subscription = Subscription.EMPTY;
  isLoaded: boolean = false;
  showFailedToLoadData: boolean = false;
  selectedReceiptId: string;
  constructor(
    @Inject('env') env,
    private eventBus: EventBusService,
    private receiptService: ReceiptService,
    private receiptGridService: ReceiptGridService,
  ) {
    this.pollingDelay = env.defaults.pollingDelay;
  }

  ngOnInit() {
    this.specialErrorSubscription = this.eventBus
      .on('special-errors/loading-error', (specialError: SpecialErrors) => this.processSpecialError(specialError));

    this.polling$
      .pipe(() =>
        timer(0, this.pollingDelay).pipe(
          switchMap(_ =>
            this.receiptService.getReceipts({
              lpId: this.taxpayer.id,
              crSearchType: VoucherListCRsType.ALL,
              errorsFilter: FdErrorFilter.VALID,
            })
          ),
          retry(3),
          takeUntil(this.polling$)
        )
      )
      .subscribe((response: IReceiptList) => {
        this.isLoaded = true;
        this.receipts = response == null || response.items == null
          ? []
          : response.items.map((receipt: IReceipt) => this.receiptGridService.extendReceiptErrorsData(receipt));
      }, (error) => {
        console.log(error);
        this.isLoaded = true;
        this.receipts = [];
      });
  }

  ngOnDestroy(): void {
    this.polling$.next();
  }

  showDetailedInfo(ev: MouseEvent, receipt: IReceipt): void {
    ev.preventDefault();
    this.selectedReceiptId = receipt.id;
  }

  clearSelectedReceipt(ev: MouseEvent): void {
    this.selectedReceiptId = null;
  }

  public hasSupplierTin(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin);
  }

  public isAgentReceipt(receipt: IReceipt): boolean {
    return this.hasSupplierTin(receipt) && receipt.supplierTin === this.taxpayer.tin;
  }

  public isPrincipalReceipt(receipt: IReceipt): boolean {
    return this.hasSupplierTin(receipt) && receipt.supplierTin !== this.taxpayer.tin;
  }

  private processSpecialError(specialError: SpecialErrors): void {
    switch (specialError) {
      case SpecialErrors.LastVouchersLoadingError:
        this.showFailedToLoadData = true;
        break;
    }
  }
}
