<kkm-sidebar [isOpened]="isOpened" [title]="title" (onClose)="close($event)" [isLoading]="isLoading" *transloco="let t; read: TRANSLATION_READ_PATH">
  <ng-container title>
    <h1 class="sidebar-title medium" [textContent]="title"></h1>
  </ng-container>

  <div class="d-flex flex-column content-container m_b-4" *ngIf="reportModel">
    <div class="d-flex flex-column p_l-12 p_r-6">
      <div class="body_text m_b-2">{{taxpayerName}}, {{t('tin')}} {{reportModel.data.tin}}</div>
      <div class="body_text m_b-6">{{reportModel.data.crData.locationName}}, {{reportModel.data.crData.locationAddress}}</div>
      <div class="body_text m_b-2"><kkm-date [date]="reportModel.data.dateTime" format="medium"></kkm-date></div>
      <div class="d-flex flex-row justify-content-start m_b-6">
        <div class="body_text m_r-8">{{t('shift-number')}} №{{reportModel.data.crData.shiftNumber}}</div>
        <div class="body_text m_r-8">{{t('cashier-name')}}: {{reportModel.data.crData.cashierName}}</div>
      </div>
      <div class="row negative-x-margin border-box m_b-3">
        <kkm-info-item class="col-3" *ngFor="let info of infoItems; trackByField:'label'" [title]="info.label">{{info.value || '-'}}</kkm-info-item>
      </div>

      <div class="row negative-x-margin border-box m_b-3">
        <kkm-info-item class="col-3" *ngFor="let info of documentInfoItems; trackByField:'label'" [title]="info.label">{{info.value || '-'}}</kkm-info-item>
      </div>

      <div *ngIf="type !== shiftReportTypes.OPENING && !isLoading">
        <h3 class="report-header m_t-5">{{t('receipts-title')}}</h3>
        <kkm-shift-operations-table
              [countersColumns]="countersColumns"
              [countersRows]="countersRows">
        </kkm-shift-operations-table>
      </div>

      <div *ngIf="!hideCorrections && type !== shiftReportTypes.OPENING && !isLoading">
        <h3 class="report-header m_t-5">{{t('adjustment-receipts-title')}}</h3>
        <kkm-shift-operations-table
              [countersColumns]="countersColumns"
              [countersRows]="correctionCountersRows">
        </kkm-shift-operations-table>
      </div>

      <div class="m_t-2 body_text" *ngIf="summaryRows && summaryRows.length">
        <div class="d-flex flex-row justify-content-end summary_row" *ngFor="let row of summaryRows; trackByField:'name'">
          <div class="summary_row_name" [textContent]="row.name"></div>
          <div class="summary_row_value">
            <kkm-money *ngIf="row.isMoney" [amount]="row.value"></kkm-money>
            <span *ngIf="!row.isMoney" [textContent]="row.value"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</kkm-sidebar>
