<div class="receipt-list-container box_shadow" [class.p_y-6]="!!receipts?.length" [class.isOpened]="isOpened" *transloco="let t; read: TRANSLATION_READ_PATH">
  <div *ngIf="!isLoaded" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>
  <ng-container *ngIf="isLoaded">
    <ng-container *ngIf="!showFailedToLoadData">
      <div *ngIf="!receipts?.length" class="no-data d-flex flex-column justify-content-center align-items-center text-uppercase body_text semi_bold">{{t('noReceiptsFound')}}</div>
      <ng-container *ngIf="receipts && receipts.length">
        <h3 class="p_x-6 ">{{t('lastReceipts')}}</h3>
        <ng-container *ngFor="let receipt of receipts; trackById">
          <div class="receipt-item" (click)="showDetailedInfo($event, receipt)"
               [class.isSelected]="selectedReceiptId === receipt.id">
            <div class="d-flex justify-content-between small_text m_b-3">
              <kkm-date [date]="receipt.dateTime" format="medium"></kkm-date>

              <div class="kkm-receipt-icons">
                <mat-icon *ngIf="isPrincipalReceipt(receipt)" svgIcon="common:agent-mark-p"></mat-icon>
                <mat-icon *ngIf="isAgentReceipt(receipt)" svgIcon="common:agent-mark-currentColor"></mat-icon>

                <mat-icon *ngIf="receipt.hasErrors"
                          [svgIcon]="receipt.errorIcon"
                          class="receipt-error m_l-3"
                          [matTooltip]="t(receipt.errorTooltipCaption, {errorsCodes: receipt.errorCodes})"
                          matTooltipPosition="before"></mat-icon>
              </div>
            </div>
            <div class="m_b-2 d-flex justify-content-between">
              <div class="muted_text small_text">{{t('cashRegister')}}</div>
              <div class="small_text">{{receipt.crRegisterNumber}}</div>
            </div>
            <div class="m_b-2 d-flex justify-content-between">
              <div class="muted_text small_text">{{t('fiscalMemoryDevice')}}</div>
              <div class="small_text">{{receipt.fnSerialNumber}}</div>
            </div>
            <div class="m_b-3 d-flex justify-content-between">
              <div class="muted_text small_text">{{t('fiscalMark')}}</div>
              <div class="small_text">{{receipt.documentFiscalMark}}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="small_text text-uppercase semi_bold">{{t('total')}}</div>
              <div class="small_text semi_bold">
                <kkm-money [amount]="receipt.ticketTotalSum || 0"></kkm-money>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showFailedToLoadData">
      <h3 class="p_x-6 p_t-6 p_b-5">{{t('lastReceipts')}}</h3>
      <span class="body_text muted_text p_x-6" *transloco="let p; read: 'common.notifications'" [textContent]="p('failed-to-load-data.text')"></span>
    </ng-container>
  </ng-container>
</div>
<kkm-receipt-details [cashRegisters]="cashRegisters" [id]="selectedReceiptId" [taxpayer]="taxpayer" (onClose)="clearSelectedReceipt($event)"></kkm-receipt-details>
