<div class="d-flex flex-column sidebar-container" #sidebar
     [class.isOpened]="isOpened"
     [style.width]="width"
     [style.transform]="'transform translateX(' + width + ')'"
     [style.top]="top"
     [style.bottom]="bottom"
     [style.height]="height">
  <div *ngIf="isLoading" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>
  <div class="sidebar-header d-flex justify-content-between align-items-center p_t-6 p_b-3 p_r-8" [class.p_l-12]="titleSize === 'medium'" [class.p_l-8]="titleSize === 'small'">

    <div #titleRef><ng-content select="[title]"></ng-content></div>
    <ng-template *ngIf="!titleRef.hasChildNodes()">
      <h1 *ngIf="!!title" [ngClass]="['sidebar-title', titleSize]">{{title}}</h1>
    </ng-template>

    <button class="icon-button" (click)="handleClose($event)">
      <mat-icon svgIcon="close:dialog"></mat-icon>
    </button>
  </div>
  <ng-content></ng-content>
</div>
