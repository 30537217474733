import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  IDetailedReceipt,
  IFormatAndLogicControlError,
  IReceipt,
  IReceiptItem,
  ITaxpayer,
  ITaxRate
} from '@common/interfaces';
import {ReceiptService} from '../../services/receipt.service';
import {
  CalculationTypesCodes,
  CorrectionReceiptTypes,
  FormatAndLogicControlErrorsSeverityType,
  PaymentTypeMarks,
  SyntheticReceiptTypes,
  TaxTypes
} from '@common/enums';
import {getReceiptType} from '@common/utils';
import {TranslocoService} from '@ngneat/transloco';
import {isEmpty, sumBy} from 'lodash';
import {TaxSystemsService} from '../../services/dictionaries/tax-systems.service';
import {EnvironmentInitService} from '../../services/environment.service';
import {BusinessObjectsDictionaryService} from "../../services/dictionaries/business-objects-dictionary.service";

interface IDetailedReceiptWithSubtotal extends IDetailedReceipt {
  subtotal: number;
}

@Component({
  selector: 'kkm-receipt-details',
  templateUrl: './receipt-details.component.html',
  styleUrls: ['./receipt-details.component.styl']
})
export class ReceiptDetailsComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() taxpayer: ITaxpayer;
  @Input() isGuest: boolean;
  @Input() withoutSidebar: boolean;
  @Input() cashRegisters: {id: string, sn: string}[];
  @Output() onClose = new EventEmitter<MouseEvent>();
  @Input() isShowFdNumber?: boolean;

  public readonly TRANSLATION_READ_PATH = 'common.components.receipt-details';
  isLoading = false;
  isOpened = false;
  notFound = false;
  receipt: IDetailedReceiptWithSubtotal;
  taxTypes = TaxTypes;
  correctionReceiptTypes = CorrectionReceiptTypes;
  _taxRates: ITaxRate[] = [];
  qrUrl: string;

  constructor(
    private receiptService: ReceiptService,
    private translocoService: TranslocoService,
    private taxSystemsServices: TaxSystemsService,
    private environmentService: EnvironmentInitService,
    private dictionaries: BusinessObjectsDictionaryService
  ) {
  }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const {id} = changes;
    if (id) {
      const {currentValue, previousValue} = changes.id;
      if (currentValue && previousValue !== currentValue) {
        await this.loadData(currentValue);
      }
    }
  }

  get isCorrection(): boolean {
    return this.receipt
    && (this.receiptType === SyntheticReceiptTypes.CORRECTION_INCOME
      || this.receiptType === SyntheticReceiptTypes.CORRECTION_INCOME_RETURN
      || this.receiptType === SyntheticReceiptTypes.CORRECTION_EXPENDITURE
      || this.receiptType === SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN);
  }

  get receiptType(): SyntheticReceiptTypes {
    return getReceiptType(this.receipt);
  }

  get isReturn(): boolean {
    return this.receiptType === SyntheticReceiptTypes.EXPENDITURE_RETURN
    || this.receiptType === SyntheticReceiptTypes.INCOME_RETURN;
  }

  get title(): string {
    switch(this.receiptType) {
      case SyntheticReceiptTypes.INCOME:
        return this.translate('receipt-title.receiptHeaderIncome');
      case SyntheticReceiptTypes.INCOME_RETURN:
        return this.translate('receipt-title.receiptHeaderIncomeReturn');
      case SyntheticReceiptTypes.EXPENDITURE:
        return this.translate('receipt-title.receiptHeaderExpense');
      case SyntheticReceiptTypes.EXPENDITURE_RETURN:
        return this.translate('receipt-title.receiptHeaderExpenseReturn');
      case SyntheticReceiptTypes.CORRECTION_INCOME:
        return this.translate('receipt-title.receiptHeaderCorrectionIncome');
      case SyntheticReceiptTypes.CORRECTION_INCOME_RETURN:
        return this.translate('receipt-title.receiptHeaderCorrectionIncomeReturn');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE:
        return this.translate('receipt-title.receiptHeaderCorrectionExpense');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN:
        return this.translate('receipt-title.receiptHeaderCorrectionExpenseReturn');
    }
  }

  get received(): number {
    return this.receipt.ticketTotalCashSum || this.receipt.ticketTotalCashlessSum;
  }

  get paymentType(): string {
    if (this.receipt.ticketTotalCashSum > 0) {
      return this.translate('cash');
    }

    if (this.receipt.ticketTotalCashlessSum > 0) {
      return this.translate('cashless');
    }
  }

  get paymentTypeMarkKey(): string {
    if (this.receipt == null || this.receipt.items == null || this.receipt.items.length == 0) {
      return null;
    }

    const paymentTypeMark = this.receipt.items[0].paymentType;
    switch (paymentTypeMark) {
      case PaymentTypeMarks.FullSettlement:
        return 'payment-mark.full-settlement';
      case PaymentTypeMarks.Advance:
        return 'payment-mark.advance';
      case PaymentTypeMarks.PartialSettlementWithCredit:
        return 'payment-mark.partial-settlement-with-credit';
      case PaymentTypeMarks.Credit:
        return 'payment-mark.credit';
      case PaymentTypeMarks.CreditSettlement:
        return 'payment-mark.credit-settlement';
      default:
        return null;
    }
  }

  get taxSystem(): string {
    const taxSystem = this.taxSystems.find(t => t.code === this.receipt.taxSystem);
    if (taxSystem) {
      return taxSystem.taxName;
    }

    return '';
  }

  get taxCounters() {
    return this.receipt.taxCounters == null
      ? []
      : this.receipt.taxCounters.sort((a, b) => ('' + b.type).localeCompare(a.type));
  }

  private get allErrors(): IFormatAndLogicControlError[] {
    if (this.receipt.errors == null) {
      return [];
    }

    const errorCodes = Object
      .keys(this.receipt.errors)
      .map((errorCode: string) => errorCode.replace("error_", ""))
      .map((errorCode: string) => Number(errorCode));

    const errors = this.dictionaries
      .formatAndLogicControlErrors
      .filter((error: IFormatAndLogicControlError) => errorCodes.includes(error.code));

    return errors;
  }

  public get criticalErrors(): IFormatAndLogicControlError[] {
    return this.allErrors
      .filter((error: IFormatAndLogicControlError) => error.type === FormatAndLogicControlErrorsSeverityType.CRITICAL);
  }

  public get acceptableErrors(): IFormatAndLogicControlError[] {
    return this.allErrors
      .filter((error: IFormatAndLogicControlError) => error.type === FormatAndLogicControlErrorsSeverityType.ACCEPTABLE);
  }

  public isAgentReceipt(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin);
  }

  public isAgentReceiptAsPrincipal(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin) && receipt.supplierTin !== this.taxpayer.tin;
  }

  public isAgentReceiptAsAgent(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin) && receipt.supplierTin === this.taxpayer.tin;
  }

  public isCurrencyReceiptItem(receiptItem: IReceiptItem): boolean {
    if (receiptItem == null) {
      return false;
    }

    return receiptItem.calcAttributeCode === CalculationTypesCodes.BankingServices
        || receiptItem.calcAttributeCode === CalculationTypesCodes.ForeignCurrencyExchange;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close(null);
  }

  close(ev: MouseEvent): void {
    this.id = null;
    this.receipt = null;
    this.isOpened = false;
    this.onClose.emit(ev);
  }

  checkGoodNomenclatureCode(code: string) {
    return code && code.includes('\u0000') ? null : code;
  }

  private async loadData(id: string): Promise<void> {
    this.isOpened = true;
    this.isLoading = true;
    this.notFound = false;
    try {
      this._taxRates = await this.receiptService.getTaxRates();
      const receipt = await this.receiptService.getReceipt(id, this.isGuest).toPromise();
      this.receipt = this.transformReceipt(receipt);
      this.qrUrl = this.generateUrl(receipt);
    } catch (err) {
      console.error(err);
      this.notFound = true;
    } finally {
      this.isLoading = false;
    }
  }

  private transformReceipt(receipt: IDetailedReceipt): IDetailedReceiptWithSubtotal {
    const taxes: number = sumBy(receipt.taxCounters, i => i.sum);
    const subtotal = receipt.ticketTotalSum - taxes;
    receipt.items?.forEach((item) => {
      if (item.goodNomenclatureCode != null && item.goodNomenclatureCode.startsWith('444D')) {
        item.sgtin = this.hex2ascii(item.goodNomenclatureCode.substr(16));
      }
    })
    return {
      ...receipt,
      subtotal,
    }
  }

  private translate(key: string): string {
    return this.translocoService.translate(`${this.TRANSLATION_READ_PATH}.${key}`);
  }

  private hex2ascii(hex): string {
    if (!(typeof hex === 'number' || typeof hex === 'string')) {
      return ''
    }
    hex = hex.toString().replace(/\s+/gi, '')
    const stack = []
    for (let i = 0; i < hex.length; i += 2) {
      const code = parseInt(hex.substr(i, 2), 16)
      if (!isNaN(code) && code !== 0) {
        stack.push(String.fromCharCode(code))
      }
    }
    return stack.join('')
  }

  private generateUrl(receipt: IDetailedReceipt) {
    const taxServiceLink = this.environmentService.config?.aisOfd?.taxServiceLink
      ? this.environmentService.config?.aisOfd?.taxServiceLink
      : 'https://tax.test.kkmnewsrv18.fls.cloud/';
    const url = new URL(taxServiceLink + '/tax-web-control/client/api/v1/ticket');

    url.searchParams.append("date", receipt.dateTime.replace(/-/g, '').replace(/:/g, '').replace(/Z/g, ''));
    url.searchParams.append("type", String(receipt.type));
    url.searchParams.append("operation_type", String(receipt.operationType));
    url.searchParams.append("fn_number", String(receipt.fnSerialNumber));
    url.searchParams.append("fd_number", String(receipt.fdNumber));
    url.searchParams.append("fm", String(receipt.documentFiscalMark));
    url.searchParams.append("tin", String(receipt.tin));
    url.searchParams.append("regNumber", String(receipt.crRegisterNumber));
    url.searchParams.append("sum", String(receipt.ticketTotalSum));

    return url.toString();
  }

  getTaxRate(code: number, type: string): number | string {
    const rateValue = this._taxRates.find(taxRate => taxRate.code === code && taxRate.taxRateType === type)?.taxRateValue;
    return rateValue !== undefined ? rateValue : '';
  }

  getSerialNumber(regNumber: string) {
    return this.cashRegisters?.find(cr => cr.id === regNumber)?.sn || null;
  }

  get taxSystems() {
    return this.taxSystemsServices.taxSystems;
  }

  get taxServiceWebsite() {
    const taxServiceWebsite = this.environmentService.config?.aisOfd?.taxServiceWebsite;
    const url = new URL(taxServiceWebsite ? taxServiceWebsite : 'https://tax.test.kkmnewsrv18.fls.cloud/');
    return {
      link: url.toString(),
      name: url.hostname
    };
  }

  public joinStrings(values: string[]): string {
    return values
      .filter((value: string) => value != null && value.trim() !== "")
      .join(", ");
  }
}
