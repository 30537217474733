import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DEFAULT_LANG, LANG_LIST } from '@common/constants';

@Component({
  selector: 'kkm-money',
  template: `<span [textContent]="amount | centsToDollars:isDollars:isCurrency | currency:'':'':(round ? roundedDigitsInfo : notRoundedDigitsInfo):locale"></span>`,
  styleUrls: ['./money.component.styl']
})

export class MoneyComponent implements OnInit, OnDestroy {
  @Input() amount: number;
  @Input() round?: boolean;
  @Input() roundedDigitsInfo = '1.0-0';
  @Input() notRoundedDigitsInfo = '1.2-2';
  @Input() isDollars?: boolean;
  @Input() isCurrency?: boolean = false;

  locale: string;
  private translocoSubscription: Subscription = Subscription.EMPTY;

  constructor(private translocoService: TranslocoService) {
    this.locale = this.getLocaleByLang(this.translocoService.getActiveLang());
    this.translocoSubscription = this.translocoService
      .langChanges$
      .subscribe((lang: string) => {
        this.locale = this.getLocaleByLang(lang);
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.translocoSubscription.unsubscribe();
  }

  private getLocaleByLang(lang: string): string {
    const locale = LANG_LIST.find((lng) => lng.value === lang);
    return locale ? locale.langCode : DEFAULT_LANG.langCode;
  }
}
