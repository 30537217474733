import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {BusinessObjectsDictionaryService} from "../services/dictionaries/business-objects-dictionary.service";
import {ICodeName} from "@common/interfaces";
import {Directive} from "@angular/core";

// Валидатор для автокомплита "ТНО"
@Directive({
  selector: "[existingTaxAuthorityDepartmentCode]",
  providers: [
    {provide: NG_VALIDATORS, useExisting: TaxAuthorityDepartmentValidator, multi: true},
  ],
})
export class TaxAuthorityDepartmentValidator implements Validator {
  constructor(private businessObjectsDictionaryService: BusinessObjectsDictionaryService) {
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null) {
      return;
    }

    const hasTaxAuthorityDepartmentCode = this.businessObjectsDictionaryService
      .taxAuthorityDepartments
      .some((dept: ICodeName) => dept.code === control.value);

    if (hasTaxAuthorityDepartmentCode) {
      return null;
    }

    return {
      "codeNotExists": {
        "translateKey": "incorrect-tax-authority-department",
        "value": control.value,
      }
    };
  }
}
