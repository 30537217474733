import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ITokens } from '../session/session.interfaces';
import {postLogin} from "../../api";


export interface ILoginInfo {
  login: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
  ) { }

  signIn(credentials: ILoginInfo): Observable<ITokens | any> {
    return this.http.post<ITokens>(postLogin(), credentials).pipe(
      catchError((error) => of(error)),
    ).pipe(first());
  }
}
