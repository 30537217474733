import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'kkm-currency-catalog-filter',
  templateUrl: './currency-catalog-filter.component.html',
  styleUrls: ['./currency-catalog-filter.component.styl']
})
export class CurrencyCatalogFilterComponent implements OnInit, OnDestroy {

  @Input() public clearSearchSubject: Subject<void>;
  @Output() public search: EventEmitter<string> = new EventEmitter<string>();

  public searchForm: FormGroup;
  public isFilterOpened: boolean = false;
  public isCodeOrNameFilterApplied: boolean = false;

  private clearSearchSubscription: Subscription = Subscription.EMPTY;

  constructor() { }

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      codeOrName: new FormControl(),
    });

    this.clearSearchSubscription = this.clearSearchSubject.subscribe(() => {
      this.searchForm.setValue({ codeOrName: null });
      this.isCodeOrNameFilterApplied = false;
    });
  }

  public ngOnDestroy(): void {
    this.clearSearchSubscription.unsubscribe();
  }

  get showClearSearchButton(): boolean {
    return this.searchForm.value.codeOrName != null && this.searchForm.value.codeOrName.trim() !== '';
  }

  public openFilter(): void {
    this.isFilterOpened = true;
  }

  public submit(): void {
    this.search.emit(this.searchForm.value.codeOrName);
    this.isCodeOrNameFilterApplied = this.searchForm.value.codeOrName != null && this.searchForm.value.codeOrName.trim() !== '';
  }

  public clearSearchInput(): void {
    this.searchForm.setValue({ codeOrName: null });
    this.isCodeOrNameFilterApplied = false;

    this.search.emit(this.searchForm.value.codeOrName);
    this.isFilterOpened = false;
  }
}
