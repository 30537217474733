<mat-form-field appearance="fill"
                cdkOverlayOrigin
                #originOverlay="cdkOverlayOrigin"
                class="kkm-form-field compact w-fluid">
  <input matInput [readonly]="true" [value]="selectedDataText">
  <mat-icon matSuffix svgIcon="datepicker:calendar"></mat-icon>
</mat-form-field>
<ng-template cdkConnectedOverlay
             cdkConnectedOverlayHasBackdrop
             [cdkConnectedOverlayOrigin]="originOverlay"
             [cdkConnectedOverlayOpen]="showPanel$ | async"
             [cdkConnectedOverlayPositions]="positions"
             cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop">

  <div class="kkm-datepicker__wrapper">
    <kkm-calendar [headerComponent]="exampleHeader"
                  [dateFilterArray]="dateFilterArray"
                  [isRange]="isRange"
                  [selected]="selectedData.date"
                  [selectedData]="selectedData"
                  (selectedDataChange)="handleSelectedDataChange($event)"
                  (closeDatepicker)="handleCloseDatePicker($event)"></kkm-calendar>
  </div>

</ng-template>
