import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface IPaginatorState {
  label: string;
  hasNext: boolean;
  hasPrev: boolean;
}

const defaultPaginatorState = {
  label: '-',
  hasPrev: false,
  hasNext: false,
};


@Component({
  selector: 'kkm-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.styl']
})
export class PaginatorComponent implements OnInit {
  @Input() state: IPaginatorState = defaultPaginatorState;
  @Input() currentPage: number;
  @Output() onPageChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  setCurrentPage(num: number): void {
    this.onPageChange.emit(num);
  }
}
