import urlPrefix from "./apiPrefix";

export * from './authenticationApi';
export * from './manageControllerApi';
export * from './taxSystemApi';
export * from './taxRateApi';
export * from './calculationItemAttributeApi';
export * from './baseCatalogueItemApi';
export * from './baseCatalogueVersionApi';
export * from './applyingConditionsApi';
export * from './userAccessApi';
export * from './userApi';
export * from './risksControllerApi';
export * from './taxShiftTicketControllerApi';
export * from './lkApi';
export * from './taxCRControllerApi';
export * from './additionalStatsApi';
export * from './statsApi';
export * from './errorsStatsApi';
export * from './directoryControllerApi';
export * from './taxAuthorityDepartmentApi';
export * from './lotteryApi';
export * from './geospatialApi';
export * from './fdErrorsInformationApi';
export * from './entrepreneurshipObjectApi';
export * from './businessActivityApi';

// test api
export const putCacheClearAll = () => `${urlPrefix.value}/tax-web-control/test/cache/clearall`;

// unknown controller apis
export const postPaymentsClientsListWithStats = () => `${urlPrefix.value}/tax-web-control/api/payments/clients/list/with-stats`;
export const postPaymentsOrganizationsListWithStats = () => `${urlPrefix.value}/tax-web-control/api/payments/organizations/list/with-stats`;
export const postPaymentsPointsList = () => `${urlPrefix.value}/tax-web-control/api/payments/points/list`;
export const postPaymentsPointsListShortLegalPerson = (clientTin: number) => `${urlPrefix.value}/tax-web-control/api/payments/points/list/short/legal-person/${clientTin}`;
export const postPaymentsOperationsStatsDaily = () => `${urlPrefix.value}/tax-web-control/api/payments/operations/stats/daily`;
export const postPaymentsOperationsList = () => `${urlPrefix.value}/tax-web-control/api/payments/operations/list`;

export const serviceProvidersRegistry = () => `${urlPrefix.value}/tax-web-control/api/service-providers/registry`;
export const postFinancialInstitutionsExport = () => `${urlPrefix.value}/tax-web-control/api/financial-institutions/export`;
