import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { DEFAULT_LANG, LANG_LIST } from '@common/constants';

@Pipe({
  name: 'abbreviate'
})
export class AbbreviatePipe implements PipeTransform, OnDestroy {

  public subject: Subject<void> = new Subject<void>();

  private locale: string;
  private suffixes: string[] = [];
  private translocoSubscription: Subscription = Subscription.EMPTY;
  private suffixesSubscription: Subscription = Subscription.EMPTY;

  constructor(private translocoService: TranslocoService) {
    this.locale = this.getLocaleByLang(this.translocoService.getActiveLang());
    this.translocoSubscription = this.translocoService
      .langChanges$
      .subscribe((lang: string) => {
        this.locale = this.getLocaleByLang(lang);
        this.suffixesSubscription = this.translocoService.selectTranslate([
          "common.numberAbbr.thousands",
          "common.numberAbbr.millions",
          "common.numberAbbr.billions",
          "common.numberAbbr.trillions",
          "common.numberAbbr.quadrillions",
        ]).subscribe((value: string[]) => {
          this.suffixes = [
            "",
            value[0],
            value[1],
            value[2],
            value[3],
            value[4],
          ];

          this.subject.next();
        });
      });
  }

  public ngOnDestroy(): void {
    this.suffixesSubscription.unsubscribe();
    this.translocoSubscription.unsubscribe();
  }

  public transform(value: number): string {
    if (Math.abs(value) < 1000) {
      return String(value);
    }

    const suffixIndex = Math.floor((String(value)).length / 3);
    const shortValue = parseFloat((suffixIndex !== 0 ? (value / Math.pow(1000, suffixIndex)) : value).toPrecision(3));

    return formatNumber(shortValue, this.locale, '1.0-2') + this.suffixes[suffixIndex];
  }

  private getLocaleByLang(lang: string): string {
    const locale = LANG_LIST.find((lng) => lng.value === lang);
    return locale ? locale.langCode : DEFAULT_LANG.langCode;
  }
}
