import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'kkm-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.styl']
})
export class FileUploadComponent {
  @Input() label: string;
  @Output() onUpload = new EventEmitter<File>();

  fileToUpload: File = null;

  handleFileInput(target) {
    this.fileToUpload = (target as HTMLInputElement).files.item(0);
    this.onUpload.emit(this.fileToUpload);
  }
}
