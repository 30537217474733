import {NgModule} from "@angular/core";
import {UiModule} from '@kkm-ui/ui';
import {environment} from '@ais-ofd/env';
import {TranslocoRootModule} from "../../transloco-root.module";
import {CommonModule} from "@angular/common";
import {ServerErrorScreenComponent} from "./server-error-screen.component";
import {HeaderModule} from "../../inner-app/components/header/header.module";

@NgModule({
  declarations: [ServerErrorScreenComponent],
  imports: [
    UiModule.forRoot(environment),
    TranslocoRootModule,
    CommonModule,
    HeaderModule
  ],
  exports: [ServerErrorScreenComponent],
  providers: []
})

export class ServerErrorScreenModule {
}
