import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {IReceipt} from '@common/interfaces';
import {AnalyticsSummaryTypes} from '@common/enums';
import {ReceiptGridService} from '../../../services/receipt-grid.service';
import {isEmpty} from 'lodash';

@Component({
  selector: 'kkm-receipts-grid',
  templateUrl: './receipts-grid.component.html',
  styleUrls: ['./receipts-grid.component.styl']
})
export class ReceiptsGridComponent implements AfterViewInit {
  TRANSLATION_READ_PATH = 'common.components.sales-analytics.receipts-grid';
  analyticsSummaryTypes = AnalyticsSummaryTypes;
  @Input() selectedReceiptId: string;
  @Input() isFilterActive: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isSkeletonLoading: boolean = false;
  @Input() columns: string[] = [];
  @Input() type: AnalyticsSummaryTypes;
  @Input() additionalClass = '';
  @Input() redrawCallback: () => void;
  @Input() tin: string;
  @Input() isShowFdNumber?: boolean;

  @Input() set data(data) {
    this.dataSource.data = data != null && data.length > 0
      ? data.map((receipt) => this.receiptGridService.extendReceiptData(receipt))
      : [];
    this.mockSource.data = Array(6).fill(mockData);
  }

  @Output() onRowClick = new EventEmitter<any>();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  mockSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor(private receiptGridService: ReceiptGridService) {
  }

  public ngAfterViewInit(): void {
    if(this.redrawCallback) {
      setTimeout(() => this.redrawCallback(), 500);
    }
  }

  get hasReceipts(): boolean {
    return !!this.dataSource?.data?.length;
  }

  selectReceipt(ev: MouseEvent, receipt: IReceipt): void {
    this.onRowClick.emit(receipt);
  }

  public isAgentReceipt(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin);
  }

  public isSupplierReceipt(receipt: IReceipt) {
    return receipt.supplierTin === this.tin;
  }
}

const mockData = {
  crData: {shiftNumber: null},
  crRegisterNumber: null,
  dataFormatVersion: null,
  dateTime: null,
  documentFiscalMark: null,
  fdNumber: null,
  fnSerialNumber: null,
  id: null,
  operationType: null,
  taxSystem: null,
  ticketNumber: null,
  ticketTotalSum: null,
  tin: null,
  type: null
}
