<div class="currency-catalog-filter" *transloco="let t; read: 'common.components.currency-catalog.filter'">

  <!-- open filter button -->
  <button mat-stroked-button color="primary" type="button"
          class="kkm-button brand-color currency-catalog-filter__open-button"
          [class.currency-catalog-filter__open-button_hidden]="isFilterOpened"
          (click)="openFilter()">
    <mat-icon matPrefix svgIcon="search:magnifyingGlass" class="brand-color"></mat-icon>
  </button>

  <form class="flex-grow-1 currency-catalog-filter__search-field"
        [class.currency-catalog-filter__search-field_hidden]="!isFilterOpened"
        [formGroup]="searchForm" (ngSubmit)="submit()">
    <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid">
      <mat-icon matPrefix svgIcon="search:magnifyingGlass" [class.search-applied]="isCodeOrNameFilterApplied"></mat-icon>
      <input matInput type="text" autocomplete="on" formControlName="codeOrName" placeholder="{{t('search-by-code-or-name.placeholder')}}">
      <mat-icon class="small dark search-field__close-button"
                [class.search-field__clear-button]="showClearSearchButton"
                matSuffix svgIcon="close:dialog-currentColor"
                (click)="clearSearchInput()"></mat-icon>
    </mat-form-field>
  </form>

  <div class="currency-catalog-filter__extra-buttons"
       [class.currency-catalog-filter__extra-buttons_hidden]="isFilterOpened">
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>
