import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecoveryService {

  private COMMON_ENDPOINT: string;

  constructor(
    @Inject('env') private env,
    private http: HttpClient
  ) {
    this.COMMON_ENDPOINT = env?.shared?.services?.recovery?.commonEndpoint || null;
  }

  sendEmail(email: string) {
    return this.http.post(`${this.COMMON_ENDPOINT}/recovery`, {login: email});
  }

  sendPassword(token: string, password: string) {
    const body = { token, password };
    return this.http.post(`${this.COMMON_ENDPOINT}/recovery/password`, body);
  }

}
