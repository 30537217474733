import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session/session.service';
import { User } from '../../../services/session/session.models';
import { first } from "rxjs/operators";
import { SettingsService } from '../../../services/settings/settings.service';
import { ILang } from '@common/interfaces';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'aisno-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {
  @Input() isLogoutVisible: boolean = true;
  public accountOpen: boolean = false;
  public languages = SettingsService.LANGUAGES;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private _adapter: DateAdapter<any>
  ) { }

  ngOnInit(): void {
    this.sessionService.getCurrentUser().pipe(first()).subscribe(() => {});
  }

  public get user(): User {
    return User.getInstance();
  }

  selectLanguage(lang: ILang) {
    this._adapter.setLocale(lang.value); // TODO
    this.settingsService.setLanguage(lang.value);
  }

  get selectedLang() {
    return SettingsService.language;
  }

  public logout(): void {
    this.sessionService.close();
  }
}
