import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';

export const onlyFloatNumber = (event, control?: FormControl) => {
  let charCode = (event.query) ? event.query : event.keyCode;
  if (charCode > 31 && (charCode < 46 || charCode === 47 || charCode > 57)) {
    if (control) {
      control.markAsDirty();
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    }
    return false;
  }
  return true;
}

export const onlyNumber = (event, control?: FormControl) => {
  let charCode = (event.query) ? event.query : event.keyCode;
  if (charCode > 31 && (charCode < 46 || charCode > 57)) {
    if (control) {
      control.markAsDirty();
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    }
    return false;
  }
  return true;
}

export const clearIfCorrect = (control: FormControl) => {
  if (!control.value) {
    control.markAsPristine();
  } else {
    control.markAsUntouched();
  }
  if (control.hasError('incorrect')) {
    delete control.errors['incorrect'];
    control.updateValueAndValidity();
  }
}

export const passwordValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const v = String(control.value);
    if (v.length < 6 || !v.match(/[!_\-\^\&]/gi)) {
      return {'password': true};
    }
    return null;
  };
}