import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from "@ais-no/env";
import {ISessionService} from "./services.interfaces";
import {IKeycloakUserDto, ITokens, IUser} from "./session.interfaces";
import {User} from "./session.models";
import urlPrefix from "../../api/apiPrefix";

@Injectable({
  providedIn: 'root',
})
export class SessionKeycloakService implements ISessionService {
  private _tokens?: ITokens

  constructor(private http: HttpClient) {
    const now = new Date();

    this._tokens = {
      accessToken: "",
      accessTokenTTL: new Date(now.getFullYear() + 50, now.getMonth(), now.getDate()),
      refreshToken: "",
      refreshTokenTTL: new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()),
    }
  }

  public get tokens(): ITokens | undefined {
    return this._tokens
  }

  public set tokens(tokens: ITokens | undefined) {
    this._tokens = tokens
  }

  public get isTokenExpired(): boolean {
    return false;
  }

  public get isRefreshTokenExpired(): boolean {
    return false;
  }

  public open(tokens: ITokens): void {}

  public close(): void {
    User.clearInstance();
    localStorage.removeItem('token')
    this.http
      .post(environment.ssoEndpoints.logout, null)
      .toPromise()
      .catch((error: any) => console.error(error))
      .then(() => this.redirectToAuth());
  }

  public getCurrentUser(token?: string): Observable<User>{
    if (!User.getInstance().isEmpty) return of(User.getInstance())
    if (token) {
      try {
        return this.authWithToken(token)
      } catch (e) {
        localStorage.removeItem('token');
        return this.auth()
      }
    }
    return this.auth()
  }

  private auth() {
    urlPrefix.setClient()
    return this.http
      .get<IKeycloakUserDto>(environment.ssoEndpoints.user)
      .pipe(
        map((userDto: IKeycloakUserDto) => {
          if (userDto == null) {
            this.redirectToAuth();
            return null;
          }

          const user: IUser = {
            email: userDto.username,
            firstName: userDto.firstName,
            fullName: `${userDto.firstName} ${userDto.lastName}`,
            lastName: userDto.lastName,
            login: userDto.username,
            middleName: "",
            position: "",
            roles: [...userDto.roles],
          };

          return User.getInstance(user);
        }),
        catchError((error) => {
          this.redirectToAuth();

          return throwError(error);
        })
      );
  }

  private authWithToken(token: string) {
    urlPrefix.setServerResource()
    return this.http
      .get<{ access_token: string;
        refresh_token: string; }>(environment.ssoEndpoints.token, { headers: {
          'Access-Token': token,
        }}).pipe(switchMap((tokens) => {
        if (!tokens) throw new Error('No tokens')
        const now = new Date()
        this.tokens = {
          accessToken: tokens.access_token,
          refreshToken: tokens.refresh_token,
          accessTokenTTL: new Date(now.getFullYear() + 50, now.getMonth(), now.getDate()),
          refreshTokenTTL: new Date(now.getFullYear() + 100, now.getMonth(), now.getDate()),
        }
        localStorage.setItem('token', token);
        return this.http.get<{
          username: string,
          given_name: string,
          family_name: string,
          realm_roles: string[]
        }>(environment.ssoEndpoints.tokenAuth, {
          headers: {
            Authorization: `Bearer ${tokens.access_token}`,
          },
        }).pipe(map((userDto) => {
                  const hasFullName = userDto.given_name && userDto.family_name
                  const user: IUser = {
                    email: userDto.username,
                    firstName: userDto.given_name,
                    fullName: hasFullName ? `${userDto.given_name} ${userDto.family_name}` : userDto.username,
                    lastName: userDto.family_name,
                    login: userDto.username,
                    middleName: "",
                    position: "",
                    roles: [...userDto.realm_roles],
                  };
                  return User.getInstance(user)
                }),
                catchError((error) => {
                  localStorage.removeItem('token');
                  this.redirectToAuth();

                  return throwError(error);
                }))
      }))
  }

  public doRefreshToken(): Observable<ITokens>{
    const now = new Date();
    this._tokens.accessTokenTTL = new Date(now.getFullYear() + 50, now.getMonth(), now.getDate())
    this._tokens.refreshTokenTTL = new Date(now.getFullYear() + 100, now.getMonth(), now.getDate())

    return of(this._tokens);
  }

  public redirectToAuth() {
    location.href = environment.ssoEndpoints.login;
  }
}
