<div *transloco="let t, read: TRANSLATION_READ_PATH">
  <div class="cr_details" [class.compact]="compact" *ngIf="records && records.length > 1">

    <mat-accordion multi class="h-fluid w-fluid of-auto">
      <mat-expansion-panel class="w-fluid kkm-expansion-panel white-area"
                            hideToggle
                            [class.no-shadow]="compact"
                            [class.m_b-0]="compact"
                            (opened)="regHistoryExpanded = true"
                            (closed)="regHistoryExpanded = false">
        <mat-expansion-panel-header [collapsedHeight]="compact ? '' : '6rem'" [expandedHeight]="compact ? '' : '6rem'">
          <div class="cr_details-panel_header w-fluid d-flex flex-wrap align-content-center" [style.height]="compact ? '' : '6rem'">
            <div class="panel-arrow-container" [class.p_l-6]="!compact" [ngClass]="compact ? 'p_r-2' : 'p_r-4'">
              <mat-icon svgIcon="action:down" class="panel-arrow" [class.expanded]="regHistoryExpanded"></mat-icon>
            </div>
            <h3 class="bold flex-grow-1">
              {{t('reg-history-title')}}
            </h3>
          </div>

        </mat-expansion-panel-header>

        <div [class.p_b-4]="!compact" class="cr_details-body">
          <div class="cr_details-data_row header_row small_text muted_text">
            <div class="cr_details-data_row-info">
              <span class="cr_details-data_row-info-date" [textContent]="t('reg-date-label')"></span>
              <span class="cr_details-header_row-info-reason" [textContent]="t('reason-label')"></span>
            </div>
            <div class="cr_details-data_row-data" [textContent]="t('data-label')"></div>
          </div>

          <div class="cr_details-data_row body_text" *ngFor="let record of recordView">
            <div class="cr_details-data_row-info">
              <span class="cr_details-data_row-info-date"><kkm-date [date]="record.dateTime" format="mediumDate"></kkm-date></span>
              <span class="cr_details-data_row-info-date-sufix">, </span>
              <span class="cr_details-data_row-info-reason" [textContent]="record.reason"></span>
            </div>
            <div class="cr_details-data_row-data">
              <div class="cr_details-data_row-data-title muted_text small_text m_b-1">{{t('data-label')}}</div>
              <div *ngIf="record.viewAddress" class="body_text_extended">{{record.viewAddress}}</div>
              <div *ngIf="record.viewTaxSystems || record.viewVatPayer || record.viewCalcItemChanged" class="body_text_extended">
                <span *ngIf="record.viewTaxSystems">
                  <span class="muted_text">{{t('tax-systems')}}</span> {{record.viewTaxSystems}}.
                </span>
                <span  *ngIf="record.viewVatPayer">
                  <span class="muted_text">{{t('vat-payer')}}: </span> {{record.viewVatPayer}}.
                </span>
              </div>

              <div *ngIf="record.viewEntrepreneurshipObject" class="body_text_extended">
                <span class="muted_text">{{t('entrepreneurship-object')}}</span>{{record.viewEntrepreneurshipObject}}
              </div>
              <div *ngIf="record.viewBusinessActivity" class="body_text_extended">
                <span class="muted_text">{{t('business-activity')}}</span>{{record.viewBusinessActivity}}
              </div>
              <div *ngIf="record.viewTaxAuthorityDepartment" class="body_text_extended">
                <span class="muted_text">{{t('tax-authority-department')}}</span>{{record.viewTaxAuthorityDepartment}}
              </div>

              <div *ngIf="record.viewCalcItemChanged">
                {{record.viewCalcItemChanged}}.
              </div>
            </div>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>
