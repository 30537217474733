import { DateRange } from '@angular/material/datepicker';
import { DateFilterEnum, ISelectedDate, IYear } from './kkm-datepicker.interfaces';
import moment from 'moment';

export function getYearsArray(): IYear[] {
  const MAX_YEARS = 3;
  const result: IYear[] = [];

  const now = new Date();
  for (let i = MAX_YEARS - 1; i >= 0; i--) {
    result.push({
      year: now.getFullYear() - i,
      isSelected: false,
    });
  }

  return result;
}

export function getDateRange<D>(selectedData: ISelectedDate<D>, filterEnum: DateFilterEnum): DateRange<D> {
  switch (filterEnum) {
  case DateFilterEnum.Day:
    return new DateRange<D>(selectedData.dateRange.start, selectedData.dateRange.end);

  case DateFilterEnum.Month:
    return getMonthRange(selectedData.date);

  case DateFilterEnum.Quarter:
    return getQuarterRange(selectedData.date);

  case DateFilterEnum.Year:
    return getYearRange(selectedData.date);

  default:
    throw new Error(`Unknown filter: ${filterEnum}`);
  }
}

export function getMonthRange<D>(date: D): DateRange<D> {
  const start = moment(date).startOf('month');
  const end = moment(date).endOf('month');

  // @ts-ignore
  return new DateRange<D>(start.toDate(), end.toDate());
}

export function getQuarterRange<D>(date: D): DateRange<D> {
  const start = moment(date).startOf('quarter');
  const end = moment(date).endOf('quarter');

  // @ts-ignore
  return new DateRange<D>(start.toDate(), end.toDate());
}

export function getYearRange<D>(date: D): DateRange<D> {
  const start = moment(date).startOf('year');
  const end = moment(date).endOf('year');

  // @ts-ignore
  return new DateRange<D>(start.toDate(), end.toDate());
}
