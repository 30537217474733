import { Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EMPTY, iif, merge, Observable, Subscription } from 'rxjs';
import { CdkConnectedOverlay, ConnectedPosition, ScrollStrategy } from '@angular/cdk/overlay';
import { FocusMonitor } from '@angular/cdk/a11y';
import { DateAdapter } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { delay, filter, mapTo, switchMap } from 'rxjs/operators';
import { KkmCalendarHeaderComponent } from './kkm-calendar-header/kkm-calendar-header.component';
import { DateFilterEnum, IQuarter, ISelectedDate, Quarters, QUARTERS } from './kkm-datepicker.interfaces';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { DateRange } from '@angular/material/datepicker';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'kkm-datepicker',
  templateUrl: './kkm-datepicker.component.html',
  styleUrls: ['./kkm-datepicker.component.styl'],
  providers: [DatePipe],
})
export class KkmDatepickerComponent<D> implements OnInit, OnDestroy {
  exampleHeader = KkmCalendarHeaderComponent;

  showPanel$: Observable<boolean>;
  scrollStrategy: ScrollStrategy;
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      panelClass: 'kkm-datepicker__overlay_start_bottom_start_top'
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'bottom',
    },
  ];

  @Input() dateFilterArray: DateFilterEnum[] = [DateFilterEnum.Year, DateFilterEnum.Quarter, DateFilterEnum.Month, DateFilterEnum.Day];
  @Input() isRange: boolean = false;
  @Input() selectedData: ISelectedDate<D>;
  @Input() returnAfterClose: boolean = false;
  @Output() readonly selectedDataChange: EventEmitter<ISelectedDate<Date>> = new EventEmitter<ISelectedDate<Date>>();

  @ViewChild(MatInput, { read: ElementRef, static: true })
  private inputEl: ElementRef;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private connectedOverlay: CdkConnectedOverlay;

  private isPanelVisible$: Observable<boolean>;
  private isPanelHidden$: Observable<boolean>;
  private isOverlayDetached$: Observable<void>;

  private initData: ISelectedDate<D>;
  private backdropClickSubscription = Subscription.EMPTY;
  private attachSubscription = Subscription.EMPTY;

  constructor(private focusMonitor: FocusMonitor,
              private dateAdapter: DateAdapter<D>,
              private datePipe: DatePipe,
              private translocoService: TranslocoService) {
  }

  get selectedDataText(): string {
    if (this.selectedData == null) {
      return '';
    }

    switch (this.selectedData.dateFilterType) {
      case DateFilterEnum.Year:
        return `${this.selectedData.year}`;

      case DateFilterEnum.Quarter:
        const quarter = this.translocoService.translate('common.quarters.' + this.selectedData.quarter);
        return `${quarter} ${this.selectedData.year}`;

      case DateFilterEnum.Month:
        const monthKey = moment(this.selectedData.month + 1, 'M').format('MMMM').toLowerCase();
        const month = this.translocoService.translate('common.months.' + monthKey);
        return `${month} ${this.selectedData.year}`;

      case DateFilterEnum.Day:
        const activeLang = this.translocoService.getActiveLang();

        if (this.isRange) {
          if (this.selectedData.dateRange) {
            if (this.isSameDate(this.selectedData.dateRange)) {
              return this.datePipe.transform((moment(this.selectedData.dateRange.start)).toDate(), 'mediumDate', null, activeLang);
            }

            const dateFromStr = this.selectedData.dateRange.start
              ? this.datePipe.transform((moment(this.selectedData.dateRange.start)).toDate(), 'mediumDate', null, activeLang)
              : '';

            const dateToStr = this.selectedData.dateRange.end
              ? this.datePipe.transform((moment(this.selectedData.dateRange.end)).toDate(), 'mediumDate', null, activeLang)
              : '';

            if (!this.selectedData.dateRange.start && !this.selectedData.dateRange.end) {
              return '';
            }

            if (this.selectedData.dateRange.start && !this.selectedData.dateRange.end) {
              return `${dateFromStr}`;
            }

            return `${dateFromStr} - ${dateToStr}`;
          }
        } else {
          const date = (moment(this.selectedData.date)).toDate();
          return this.datePipe.transform(date, 'mediumDate', null, activeLang);
        }
    }
  }

  ngOnInit(): void {
    this.isPanelVisible$ = this.focusMonitor
      .monitor(this.inputEl)
      .pipe(
        filter((focused) => !!focused),
        mapTo(true)
      );

    this.isOverlayDetached$ = this.isPanelVisible$.pipe(
      delay(0),
      switchMap(() =>
        iif(
          () => !!this.connectedOverlay.overlayRef,
          this.connectedOverlay.overlayRef.detachments(),
          EMPTY
        )
      )
    );

    this.isPanelHidden$ = merge(
      this.isOverlayDetached$,
      this.connectedOverlay.backdropClick
    ).pipe(mapTo(false));

    this.showPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);

    const activeDate = this.dateAdapter.today();
    const month = this.dateAdapter.getMonth(activeDate);
    const currentQuarter = QUARTERS.find((quarter: IQuarter) => quarter.startMonth <= month && month <= quarter.endMonth);

    if (this.selectedData == null) {
      this.selectedData = {
        dateFilterType: DateFilterEnum.Year,
        year: this.dateAdapter.getYear(activeDate),
        quarter: currentQuarter ? currentQuarter.quarter : Quarters.Q1,
        month: month,
        day: this.dateAdapter.getDate(activeDate),
        date: this.dateAdapter.today(),
        dateRange: new DateRange<D>(null, null)
      };
    } else {
      this.selectedData = {
        ...this.selectedData,
        date: this.dateAdapter.deserialize(this.selectedData.date),
        dateRange: this.selectedData.dateRange || new DateRange<D>(null, null)
      };
    }

    this.attachSubscription = this.connectedOverlay.attach.subscribe(() => {
      this.initData = cloneDeep(this.selectedData);
    });

    this.backdropClickSubscription = this.connectedOverlay.backdropClick.subscribe(() => {
      this.selectedData = cloneDeep(this.initData);
    });
  }

  ngOnDestroy(): void {
    this.attachSubscription.unsubscribe();
    this.backdropClickSubscription.unsubscribe();
  }

  handleSelectedDataChange(selectedData: ISelectedDate<D>): void {
    this.selectedData = selectedData;
    !this.returnAfterClose && this.emitData();
  }

  handleCloseDatePicker(close: boolean): void {
    if (close) {
      this.returnAfterClose && this.emitData();
      this.connectedOverlay.overlayRef.detach();
    }
  }

  private isSameDate(dateRange: DateRange<D>): boolean {
    return dateRange.start != null && dateRange.end != null && this.dateAdapter.sameDate(dateRange.start, dateRange.end);
  }

  private emitData() {
    const normalizedSelectedData = {
      dateFilterType: this.selectedData.dateFilterType,
      year: this.selectedData.year,
      quarter: this.selectedData.quarter,
      month: this.selectedData.month,
      day: this.selectedData.day,
      date: (moment(this.selectedData.date)).toDate(),
      dateRange: new DateRange<Date>(
        this.selectedData.dateRange?.start ? (moment(this.selectedData.dateRange.start)).toDate() : null,
        this.selectedData.dateRange?.end ? (moment(this.selectedData.dateRange.end)).toDate() : null
      ),
    };

    this.selectedDataChange.emit(normalizedSelectedData);
  }
}

