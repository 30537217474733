import moment from "moment";

export class DateRangeViewModel {
  private readonly isSameYears: boolean;
  private readonly startMoment: any;
  private readonly endMoment: any;
  private readonly ShortViewDateFormat = 'MMM D';
  private readonly FullViewDateFormat = `${this.ShortViewDateFormat}, YYYY`;

  constructor(startDate: Date | any | string, endDate: Date | any | string) {
    this.startMoment = !!startDate ? moment(startDate) : null;
    this.endMoment = !!endDate
      ? moment(endDate)
      : !!startDate ? moment(startDate) : null;
    if (this.startMoment !== null && this.endMoment !== null) {
      this.isSameYears = this.startMoment.year() === this.endMoment.year();
    }
  }

  private get isValid() {
    return !!this.startMoment;
  }

  toViewString() {
    if (!this.isValid) {
      return '';
    }
    const startStr = this.startMoment.format(this.isSameYears ? this.ShortViewDateFormat : this.FullViewDateFormat);
    const endStr = this.endMoment.format(this.FullViewDateFormat);
    return `${startStr}-${endStr}`;
  }
}
