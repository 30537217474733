<form [formGroup]="form" *transloco="let t; read: translationPath">
  <mat-form-field floatLabel="never" appearance="fill" (click)="togglePanel()"
                  class="kkm-form-field kkm-mat-chip-form-field compact w-fluid">
    <mat-chip-list formArrayName="items">
      <mat-chip *ngIf="selectedItemsCount"
                [removable]="true"
                (removed)="removeAll()"
                (click)="togglePanel()">
        {{ t('selected-items') + selectedItemsCount }}
        <mat-icon matChipRemove svgIcon="close:dialog-currentColor"></mat-icon>
      </mat-chip>

      <span class="input-placeholder" *ngIf="!selectedItemsCount" [textContent]="t('select-items-title')"></span>
      <input #itemsTrigger="matAutocompleteTrigger" [matAutocomplete]="itemsAutocomplete" [hidden]="true" (click)="togglePanel()">

    </mat-chip-list>

    <mat-autocomplete #itemsAutocomplete="matAutocomplete" class="select-autocomplete" [panelWidth]="panelWidth" (opened)="setFocus()">

      <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid select-autocomplete__input">
        <mat-icon matPrefix svgIcon="search:magnifyingGlass"></mat-icon>
        <input #searchEl type="text" matInput autocomplete="off" formControlName="search" placeholder="{{t('search.placeholder')}}">
        <button *ngIf="searchControl.value" type="button" class="kkm-button kkm-icon-button" matSuffix mat-icon-button (click)="clearSearchControl($event)">
          <mat-icon svgIcon="close:narrowInput" class="small"></mat-icon>
        </button>
      </mat-form-field>

      <mat-option *ngFor="let item of filteredCheckboxItems | async" [value]="item.code" class="select-autocomplete__option">
        <mat-checkbox class="d-block w-fluid h-fluid body_text"
                      [checked]="item.checked"
                      (change)="selectItem(item)"
                      (click)="$event.stopPropagation()">{{ item.name }}</mat-checkbox>
      </mat-option>

      <mat-option *ngIf="!hasFilteredCheckboxItems" [disabled]="true">
        {{ t('data-not-found') }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
