import { Pipe, PipeTransform } from '@angular/core';
import { CURRENCY_FRACTURE_PART } from '@common/constants';

@Pipe({
  name: 'centsToDollars'
})
export class CentsToDollarsPipe implements PipeTransform {

  transform(value: number = 0, isDollars?: boolean, isCurrency: boolean = false): number {
    if (isCurrency) {
      return value / CURRENCY_FRACTURE_PART;
    }

    return isDollars ? value : value / 100;
  }

}
