import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { icons } from './icon.constants';

@NgModule()
export class IconModule {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    (icons || []).forEach((iconSet) => {
      (iconSet.items || []).forEach((icon) => {
        iconRegistry.addSvgIconInNamespace(
          iconSet.namespace,
          icon.name,
          sanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${icon.url}`),
        );
      });
    });
  }
}