import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: 'kkm-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.styl'],
})

export class UserMenuComponent {
  @Input() username: string;
  @Input() email: string;
  @Input() useAvatarOnMobile: boolean;
  @Output() logout: EventEmitter<any> = new EventEmitter<any>();

  public get hasEmail(): boolean {
    return this.email != null && this.email.trim() !== "";
  }
}
