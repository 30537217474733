import {Inject, Injectable} from '@angular/core';
import {ICodeName, IFormatAndLogicControlError} from '@common/interfaces';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class BusinessObjectsDictionaryService {
  constructor(@Inject('env') private env, private http: HttpClient) {
  }

  private _entrepreneurshipObjects: ICodeName[] = [];
  public get entrepreneurshipObjects(): ICodeName[] {
    return this._entrepreneurshipObjects;
  }

  private _businessActivities: ICodeName[] = [];
  public get businessActivities(): ICodeName[] {
    return this._businessActivities;
  }

  private _taxAuthorityDepartments: ICodeName[] = [];
  public get taxAuthorityDepartments(): ICodeName[] {
    return this._taxAuthorityDepartments;
  }

  private _legalPersonActivities: ICodeName[] = [];
  public get legalPersonActivities(): ICodeName[] {
    return this._legalPersonActivities;
  }

  private _formatAndLogicControlErrors: IFormatAndLogicControlError[] = [];
  public get formatAndLogicControlErrors(): IFormatAndLogicControlError[] {
    return this._formatAndLogicControlErrors;
  }

  private get isLoaded(): boolean {
    return this.entrepreneurshipObjects.length > 0
      && this.businessActivities.length > 0
      && this.taxAuthorityDepartments.length > 0
      && this.formatAndLogicControlErrors.length > 0;
  }

  public async loadDictionaries(prefix: string = ''): Promise<void> {
    if (this.isLoaded) {
      return;
    }

    await Promise.all([
      this.getEntrepreneurshipObjects(prefix),
      this.getBusinessActivities(prefix),
      this.getTaxAuthorityDepartments(prefix),
      this.getLegalPersonActivities(prefix),
      this.getFormatAndLogicControlErrors(prefix),
    ]);
  }

  private async getEntrepreneurshipObjects(prefix: string = ''): Promise<void> {
    try {
      this._entrepreneurshipObjects = await this.http
        .get<ICodeName[]>(prefix + this.env.dictionaries.entrepreneurshipObjects)
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  private async getBusinessActivities(prefix: string = ''): Promise<void> {
    try {
      this._businessActivities = await this.http
        .get<ICodeName[]>(prefix + this.env.dictionaries.businessActivities)
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  private async getTaxAuthorityDepartments(prefix: string = ''): Promise<void> {
    try {
      this._taxAuthorityDepartments = await this.http
        .get<ICodeName[]>(prefix + this.env.dictionaries.taxAuthorityDepartments)
        .toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  private async getLegalPersonActivities(prefix: string = ''): Promise<void> {
    if (this.env?.dictionaries?.legalPersonActivities) {
      try {
        this._legalPersonActivities = await this.http
          .get<ICodeName[]>(prefix + this.env.dictionaries.legalPersonActivities)
          .toPromise();
      } catch (e) {
        console.error(e);
      }
    }
  }

  private async getFormatAndLogicControlErrors(prefix: string = ''): Promise<void> {
    if (this.env?.dictionaries?.formatAndLogicControlErrors) {
      try {
        this._formatAndLogicControlErrors = await this.http
          .get<IFormatAndLogicControlError[]>(prefix + this.env.dictionaries.formatAndLogicControlErrors)
          .toPromise();
      } catch (e: any) {
        console.error(e);
      }
    }
  }
}
