import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import IMask from 'imask';

interface IMaskedNumberOptions {
  radix: string;
  thousandsSeparator: string;
  mapToRadix: Array<string>;
  min: number;
  max: number;
  scale: number;
  signed: boolean;
  normalizeZeros: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MaskServiceService {

  private lang: string;
  private translocoSubscription: Subscription = Subscription.EMPTY;

  private readonly enCommonOptions: IMaskedNumberOptions;
  private readonly ruCommonOptions: IMaskedNumberOptions;

  constructor(private translocoService: TranslocoService) {
    this.enCommonOptions = {
      scale: 2,
      signed: false,
      thousandsSeparator: '',
      normalizeZeros: true,
      radix: '.',
      mapToRadix: [','],
      min: 0,
      max: 99999999.99
    };

    this.ruCommonOptions = {
      scale: 2,
      signed: false,
      thousandsSeparator: '',
      normalizeZeros: true,
      radix: ',',
      mapToRadix: ['.'],
      min: 0,
      max: 99999999.99
    };

    this.lang = this.translocoService.getActiveLang();
    this.translocoSubscription = this.translocoService
      .langChanges$
      .subscribe((lang: string) => {
        this.lang = lang;
      });
  }

  get localizedInputPriceMask(): IMask.MaskedNumber {
    switch (this.lang) {
      case 'en': return new IMask.MaskedNumber({
        mask: Number,
        ...this.enCommonOptions
      });

      case 'ru':
      case 'ky':
        return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
      });

      default: return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
      });
    }
  }

  get localizedInputQuantityMask(): IMask.MaskedNumber {
    switch (this.lang) {
      case 'en': return new IMask.MaskedNumber({
        mask: Number,
        ...this.enCommonOptions,
        scale: 3,
      });

      case 'ru':
      case 'ky':
        return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
        scale: 3,
      });

      default: return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
        scale: 3,
      });
    }
  }

  get localizedInputCurrencyMask(): IMask.MaskedNumber {
    switch (this.lang) {
      case 'en': return new IMask.MaskedNumber({
        mask: Number,
        ...this.enCommonOptions,
        scale: 4,
      });

      case 'ru': return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
        scale: 4,
      });
    }
  }

  public get localizedInputLatitudeMask(): IMask.MaskedNumber {
    switch (this.lang) {
      case 'en': return new IMask.MaskedNumber({
        mask: Number,
        ...this.enCommonOptions,
        scale: 6,
        min: -90,
        max: 90,
        signed: true,
      });

      case 'ru': return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
        scale: 6,
        min: -90,
        max: 90,
        signed: true,
      });
    }
  }

  public get localizedInputLongitudeMask(): IMask.MaskedNumber {
    switch (this.lang) {
      case 'en': return new IMask.MaskedNumber({
        mask: Number,
        ...this.enCommonOptions,
        scale: 6,
        min: -180,
        max: 180,
        signed: true,
      });

      case 'ru': return new IMask.MaskedNumber({
        mask: Number,
        ...this.ruCommonOptions,
        scale: 6,
        min: -180,
        max: 180,
        signed: true,
      });
    }
  }

}
