import { DateRange } from '@angular/material/datepicker';

export enum KkmMatCalendarView {
  Month = 'month',
  Year = 'year',
  Quarter = 'quarter',
  MultiYear = 'multi-year'
}

export enum DateFilterEnum {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Day = 'DAY'
}

export enum Quarters {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export interface IYear {
  year: number;
  isSelected: boolean;
}

export interface IQuarter {
  quarter: Quarters;
  isSelected: boolean;
  startMonth: number;
  endMonth: number;
}

export interface ISelectedDate<D> {
  dateFilterType: DateFilterEnum;
  year: number;
  quarter: Quarters;
  month: number;
  day: number;
  date: D;
  dateRange: DateRange<D>;
}

export const QUARTERS: IQuarter[] = [
  {
    quarter: Quarters.Q1,
    isSelected: false,
    startMonth: 0,
    endMonth: 2,
  },
  {
    quarter: Quarters.Q2,
    isSelected: false,
    startMonth: 3,
    endMonth: 5,
  },
  {
    quarter: Quarters.Q3,
    isSelected: false,
    startMonth: 6,
    endMonth: 8,
  },
  {
    quarter: Quarters.Q4,
    isSelected: false,
    startMonth: 9,
    endMonth: 11,
  }
];
