import {Component, Input} from "@angular/core";

@Component({
  selector: 'kkm-aisno-server-error-screen',
  templateUrl: './server-error-screen.component.html',
  styleUrls: ['./server-error-screen.component.styl']
})
export class ServerErrorScreenComponent {

  @Input() showHeader: boolean = true;

  constructor() { }

}
