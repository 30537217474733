import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ReceiptService} from '../../../services/receipt.service';
import {ICrList, IIdName, IReceipt, IReceiptList} from '@common/interfaces';
import {
  AnalyticsSummaryTypes,
  FdErrorFilter,
  ReceiptOperationTypes,
  ReceiptTypes,
  VoucherListCRsType
} from '@common/enums';
import {identity, isEmpty, isEqual, last, pickBy} from 'lodash';
import {format} from 'date-fns';
import {TranslocoService} from '@ngneat/transloco';
import {EventBusService, EventData} from '../../../services/event-bus/event-bus.service';
import {Subscription} from 'rxjs';

const defaultFormValues = {
  date: {
    start: '',
    end: '',
  },
  shiftNumber: '',
  receiptType: ReceiptTypes.TICKET,
  receiptOperationType: 'ALL',
  errorsFilter: FdErrorFilter.VALID,
}

@Component({
  selector: 'kkm-receipts-summary',
  templateUrl: './receipts-summary.component.html',
  styleUrls: ['./receipts-summary.component.styl']
})
export class ReceiptsSummaryComponent implements OnInit, OnChanges, OnDestroy {
  private RECEIPTS_LIMIT_PER_PAGE: number = 50;
  @Input() taxpayerId: string | number;
  @Input() taxpayer: any;
  @Input() selectedReceiptId: string;
  @Input() mainFilter;
  @Input() searchFilter;
  @Input() cashRegisters: ICrList[];
  @Input() analyticsSummaryType: AnalyticsSummaryTypes;
  @Input() isFilterActive: boolean = false;
  @Input() currentPage: number;
  @Input() isAgentsReceipts: boolean = false;
  @Input() isShowFdNumber?: boolean;

  @Output() afterLoad = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<IReceipt>();
  @Output() clearCurrent = new EventEmitter<void>();
  @Output() appliedFiltersCount = new EventEmitter<number>();
  receiptTypes: IIdName[] = [];
  receiptOperationTypes: IIdName[] = [];
  fdErrorFilters: IIdName[] = [];
  analyticsSummaryTypes = AnalyticsSummaryTypes;
  defaultFilter;
  offset: Map<number, string> = new Map<number, string>();
  minDate: Date;
  maxDate: Date;
  form: FormGroup;
  isFilterChanged: boolean = false;
  isLoaded: boolean = false;
  isLoading: boolean = false;
  receipts: IReceipt[] = [];

  @ViewChild('summaryEl') summaryEl: ElementRef<HTMLDivElement>;

  private loadReceiptsSubscription: Subscription = Subscription.EMPTY;
  private findReceiptsSubscription: Subscription = Subscription.EMPTY;
  private filterChangedSubscription: Subscription = Subscription.EMPTY;

  constructor(private ref: ChangeDetectorRef,
              private fb: FormBuilder,
              private receiptService: ReceiptService,
              private translocoService: TranslocoService,
              private eventBus: EventBusService) {
    this.form = this.fb.group({
      date: this.fb.group({
        start: [''],
        end: ['']
      }),
      shiftNumber: [''],
      receiptType: ReceiptTypes.TICKET,
      receiptOperationType: 'ALL',
      errorsFilter: FdErrorFilter.VALID,
    });
  }

  public async ngOnInit(): Promise<void> {
    this.receiptTypes = [
      { id: 'ALL', name: 'all_receipt_types' },
      { id: ReceiptTypes.TICKET, name: 'receipt' },
      { id: ReceiptTypes.CORRECTION_TICKET, name: 'correction_ticket' },
    ];

    this.receiptOperationTypes = [
      { id: 'ALL', name: 'all_receipt_operation_types' },
      { id: ReceiptOperationTypes.INCOME, name: 'income' },
      { id: ReceiptOperationTypes.INCOME_RETURN, name: 'income_return' },
      { id: ReceiptOperationTypes.EXPENDITURE, name: 'expenditure' },
      { id: ReceiptOperationTypes.EXPENDITURE_RETURN, name: 'expenditure_return' },
    ];

    this.fdErrorFilters = [
      { id: FdErrorFilter.VALID, name: 'all_receipts' },
      { id: FdErrorFilter.NO_ERRORS, name: 'valid_receipts' },
      { id: FdErrorFilter.ACCEPTABLE_ERRORS, name: 'receipts_with_errors' },
    ];

    this.form.get("date").setValue({
      start: this.mainFilter.from,
      end: this.mainFilter.to,
    })

    this.filterChangedSubscription = this.form
      .valueChanges
      .subscribe(() => this.isFilterChanged = true);

    this.setFilter();
    this.loadReceipts();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const {mainFilter, searchFilter, currentPage, analyticsSummaryType} = changes;

    if (analyticsSummaryType) {
      const summaryType = analyticsSummaryType.currentValue as AnalyticsSummaryTypes;
      switch (summaryType) {
        case AnalyticsSummaryTypes.BY_RECEIPTS:
          this.form.get("errorsFilter").setValue(FdErrorFilter.VALID);
          break;
        case AnalyticsSummaryTypes.BY_AGENT_RECEIPTS:
          this.form.get("errorsFilter").setValue(FdErrorFilter.NO_ERRORS);
          break;
        case AnalyticsSummaryTypes.BY_CASH_REGISTERS:
          this.form.get("errorsFilter").setValue(FdErrorFilter.VALID);
          break;
        case AnalyticsSummaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS:
          this.form.get("errorsFilter").setValue(FdErrorFilter.CRITICAL_ERRORS);
          break;
      }
    }

    if (mainFilter) {
      defaultFormValues.date.start = mainFilter.currentValue.from;
      defaultFormValues.date.end = mainFilter.currentValue.to;

      if (!mainFilter.firstChange && !isEqual(mainFilter.previousValue, mainFilter.currentValue)) {
        this.clearFilter();
        this.loadReceipts();
        return;
      }
    }

    if (searchFilter && !searchFilter.firstChange && !isEmpty(searchFilter) && !isEqual(searchFilter.currentValue, searchFilter.previousValue)) {
      const {fm, fd} = searchFilter.currentValue;
      this.offset.clear();
      if (fm || fd) {
        this.clearFilter();
        this.findReceipts();
      } else {
        this.loadReceipts();
      }
    }

    if (currentPage && !currentPage.firstChange) {
      this.loadReceipts();
    }
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    this.loadReceiptsSubscription.unsubscribe();
    this.findReceiptsSubscription.unsubscribe();
  }

  onAfterLoad(payload: IReceiptList, clearSearchFilter = false): void {
    const { page = 1, size = this.RECEIPTS_LIMIT_PER_PAGE, total = 1 } = payload;
    const startOffset = (page - 1) * size + 1;
    const endOffset = startOffset - 1 + this.RECEIPTS_LIMIT_PER_PAGE >= total
      ? total === 0 ? 1 : total
      : startOffset - 1 + this.RECEIPTS_LIMIT_PER_PAGE;
    const label = `${startOffset}-${endOffset}`;

    const paginatorState = {
      hasNext: startOffset - 1 + this.RECEIPTS_LIMIT_PER_PAGE >= total ? false : true,
      hasPrev: this.currentPage > 1,
      label,
    };

    this.afterLoad.emit({
      paginatorState,
      areFiltersApplied: this.areFiltersApplied,
      clearSearchFilter,
    });
  }

  clearFilter() {
    this.form.setValue(defaultFormValues);
    this.offset.clear();
    this.setFilter();
  }

  clearFormField(ev: MouseEvent, name: string): void {
    ev.stopPropagation();
    this.form.get(name)?.setValue('');
  }

  setFilter(): void {
    const {from, to} = this.mainFilter;
    this.minDate = new Date(from);
    this.maxDate = new Date(to);
    this.defaultFilter = {...this.form.value, date: this.date};
    this.ref.detectChanges();
  }

  checkDates(): void {
    const start = this.form.get('date').get('start');
    const end = this.form.get('date').get('end');
    if (!!start.value && !end.value) {
      end.setValue(start.value);
    }
  }

  clearDates(ev: MouseEvent) {
    ev.stopPropagation();
    this.form.get('date').setValue(defaultFormValues.date);
  }

  setOffset(payload: IReceiptList): void {
    this.offset.set(this.currentPage, last(payload.items)?.id);
  }

  get areFiltersApplied(): boolean {
    const currentFilter = {...this.form.value, date: this.date};
    return !isEqual(this.defaultFilter, currentFilter);
  }

  get gridColumns(): string[] {
    return [
      'dateTime',
      'cashRegisterNumber',
      'shiftNumber',
      'fdNumber',
      'credit',
      'advance',
      'ticketTotalSum',
      'operationType',
    ];
  }

  get params() {
    let params = {
      ...this.mainFilter,
      ...this.filter,
      limit: this.RECEIPTS_LIMIT_PER_PAGE,
      lpId: this.taxpayerId,
    };

    if (this.currentPage > 1 && this.offset.has(this.currentPage - 1)) {
      params = {...params, offset: this.offset.get(this.currentPage - 1)};
    }

    return params;
  }

  get date() {
    const {date: {start, end}} = this.form.value;
    if (start && end) {
      return {
        start: format(start, 'YYYY-MM-DD'),
        end: format(end, 'YYYY-MM-DD')
      }
    }
    return {start: '', end: ''};
  }

  get filter() {
    const {date, receiptType, receiptOperationType, ...rest} = this.form.value;
    const {start, end} = this.date;
    let params = pickBy(rest, identity);
    const ops = [];
    const types = [];
    if (start && end) {
      params = {...params, from: start, to: end};
    }

    if (receiptType === 'ALL') {
      types.push(...this.receiptTypes.filter(t => t.id !== 'ALL').map(t => t.id));
    } else {
      types.push(receiptType);
    }

    if (receiptOperationType === 'ALL') {
      ops.push(...this.receiptOperationTypes.filter(t => t.id !== 'ALL').map(t => t.id));
    } else {
      ops.push(receiptOperationType);
    }

    return {...params, ops, types};
  }

  loadReceipts(onApplyFilters?: boolean): void {
    if (this.form.invalid) {
      return;
    }

    if (onApplyFilters && this.currentPage !== 1) {
      this.clearCurrent.next();
      return;
    }

    this.findReceiptsSubscription.unsubscribe();

    this.isLoading = true;
    const appliedFiltersCount = this.countFilters();
    this.isFilterChanged = false;
    const params = { ...this.params, page: this.currentPage };
    delete params.kktIds;
    const kktIds = this.mainFilter?.kktIds || [];
    const cashRegisterNumbers = kktIds.length ? kktIds : [...this.cashRegisters].map(cr => cr.id);

    const applyData = res => {
      this.receipts = res.items || [];
      this.eventBus.emit(new EventData("receipts-summary/load-receipts-count", this.receipts.length));
      this.appliedFiltersCount.emit(appliedFiltersCount);

      this.onAfterLoad(res, true);
      this.setOffset(res);
      this.isLoading = false;
    }

    if (cashRegisterNumbers.some((cr: string) => this.cashRegisters.map(a => a.id).includes(cr))) {
      this.loadReceiptsSubscription = this.receiptService
      .getReceipts({
        ...params,
        cashRegisterNumbers,
        crSearchType: this.isAgentsReceipts ? VoucherListCRsType.AGENT : VoucherListCRsType.LEGAL_PERSON,
      })
      .subscribe((response: IReceiptList) => applyData(response));
    } else {
      applyData({items: []});
    }
  }

  async findReceipts(): Promise<void> {
    this.loadReceiptsSubscription.unsubscribe();

    this.isLoading = true;
    const params = pickBy(this.searchFilter, identity);
    const kktIds = this.mainFilter?.kktIds || [];
    const cashRegisterNumber = kktIds.length ? kktIds : this.cashRegisters?.map(cr => cr.id);

    const applyData = res => {
      this.receipts = res.items || [];
      this.onAfterLoad(res);
      this.setOffset(res);

      this.emitScrollToSummary();
      this.isLoading = false;
    }

    if (cashRegisterNumber.some((cr: string) => this.cashRegisters.map(a => a.id).includes(cr))) {
      this.findReceiptsSubscription = this.receiptService
      .findReceipts({...params, cashRegisterNumber})
      .subscribe((response: IReceiptList) => applyData(response));
    } else {
      applyData({items: []});
    }
  }

  get startControl() {
    return this.form.get('date').get('start');
  }

  get endControl() {
    return this.form.get('date').get('end');
  }

  get dateControl(): FormGroup {
    return this.form.get('date') as FormGroup;
  }

  async resetFilter(): Promise<void> {
    this.form.setValue(this.defaultFilter);
  }

  stringDate(date) {
    return format(date, 'YYYY-MM-DD');
  }

  openReceipt(receipt: IReceipt): void {
    this.onRowClick.emit(receipt);
  }

  private emitScrollToSummary(): void {
    const uiElementsHeight = 58;
    const offset = this.summaryEl.nativeElement.offsetTop - uiElementsHeight;
    this.eventBus.emit(new EventData("summary-info/scroll-to-summary", offset));
  }

  private countFilters(): number {
    const { date, shiftNumber, receiptType, receiptOperationType, errorsFilter } = this.form.value;
    let result = 0;

    if (!isEmpty(date.start) || !isEmpty(date.end)) {
      result = result + 1;
    }

    if (!isEmpty(shiftNumber)) {
      result = result + 1;
    }

    if (receiptType !== ReceiptTypes.TICKET) {
      result = result + 1;
    }

    if (receiptOperationType !== 'ALL') {
      result = result + 1;
    }

    if (this.analyticsSummaryType === AnalyticsSummaryTypes.BY_RECEIPTS && errorsFilter !== FdErrorFilter.VALID) {
      result = result + 1;
    }

    return result;
  }
}
