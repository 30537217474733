import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";

@Injectable()
export class AppInitService {
 
  constructor(private settingsService: SettingsService) { }
  
  async Init() {
    return await this.settingsService.initMapLang()
  }

}