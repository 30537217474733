import {AfterContentInit, ContentChild, Directive, ElementRef, Input, OnDestroy} from "@angular/core";
import {MatDateRangePicker, MatEndDate, MatStartDate} from '@angular/material/datepicker';
import {fromEvent, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Directive({
  selector: '[kkmDateRangePickerInput]'
})
export class DateRangePickerInputDirective implements OnDestroy, AfterContentInit {
  @Input('rangePicker') rangePicker: MatDateRangePicker<any>;

  @ContentChild(MatStartDate, {read: ElementRef}) startDateInput: ElementRef;
  @ContentChild(MatEndDate, {read: ElementRef}) endDateInput: ElementRef;

  private destroy$ = new Subject<any>();

  constructor() {
  }

  ngAfterContentInit() {
    this.inputs.forEach((input: HTMLElement) => {
      input.setAttribute('autocomplete', 'off');
      fromEvent(input, 'focus')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.openPickerSaveFocus(input))
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private get inputs(): HTMLElement[] {
    return [
      this.startDateInput.nativeElement as HTMLElement,
      this.endDateInput.nativeElement as HTMLElement
    ];
  }

  private openPickerSaveFocus(focusTargetElem: HTMLElement) {
    if (!this.rangePicker.opened) {
      this.rangePicker.open();
      setTimeout(() => focusTargetElem.focus(), 0)
    }
  }
}
