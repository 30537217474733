<div *ngIf="isCashRegistersLoading" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>
<div *ngIf="!isCashRegistersLoading" class="summary-container p_t-2 p_l-8 p_r-6 position-relative" #summaryContainer>

  <mat-accordion [multi]="false" [hideToggle]="true">
    <mat-expansion-panel *ngFor="let cashRegister of pagedCashRegisters; let i = index"
                         class="kkm-expansion-panel w-fluid secondary-area box_shadow"
                         (opened)="loadShifts(cashRegister, i)"
                         (afterCollapse)="clearShifts(cashRegister)" #expansionPanel>
      <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto" *transloco="let t; read: TRANSLATION_READ_PATH">
        <div class="cr-header-container body_text" #summaryElements>
          <div class="indicator"></div>

          <div class="panel-header__title">
            <span class="panel-header__number" [textContent]="cashRegister.regNumber"></span>
            <div class="panel-header__column d-flex flex-row align-items-center justify-content-end">
              <div class="panel-header__column d-flex flex-row align-items-center justify-content-start m_r-12">
                <div class="label-value">
                  <div class="label-value__label small_text muted_text" [textContent]="t('advance')"></div>
                  <div class="label-value__value body_text"><kkm-money [amount]="calculateTotalAdvance(cashRegister)"></kkm-money></div>
                </div>
                <div class="label-value">
                  <div class="label-value__label small_text muted_text" [textContent]="t('credit')"></div>
                  <div class="label-value__value body_text"><kkm-money [amount]="calculateTotalCredit(cashRegister)"></kkm-money></div>
                </div>
                <div class="label-value">
                  <div class="label-value__label small_text muted_text" [textContent]="t('revenue')"></div>
                  <div class="label-value__value body_text"><kkm-money [amount]="calculateTotalRevenue(cashRegister)"></kkm-money></div>
                </div>
                <div class="label-value">
                  <div class="label-value__label small_text muted_text">
                    {{ sklonenie(cashRegister.totalStat?.totalTicketCount || 0, [
                    t('receipts-one'),
                    t('receipts-three'),
                    t('receipts-five'),
                    t('receipts-one')]) }}
                  </div>
                  <div class="label-value__value body_text">{{ (cashRegister.totalStat?.totalTicketCount || 0) | number }}</div>
                </div>
              </div>

              <!-- FILTER BUTTON -->
              <button mat-stroked-button color="primary" type="button"
                      class="kkm-button kkm-button__filters narrow fixed-height brand-color"
                      [class.kkm-button__filters_show]="filtersOpenedState.get(cashRegister.regNumber)"
                      [class.kkm-button__filters_closed]="!expansionPanel.expanded"
                      [disabled]="!expansionPanel.expanded"
                      (click)="toggleFilter($event, cashRegister, expansionPanel.expanded)">
                <mat-icon [svgIcon]="'funnel:' + color" [class.m_r-2]="expansionPanel.expanded"></mat-icon>
                <i *ngIf="!expansionPanel.expanded && filtersAppliedCount.get(cashRegister.regNumber) > 0"
                   class="kkm-button__filters-count kkm-button__filters-count-badge">{{ filtersAppliedCount.get(cashRegister.regNumber) }}</i>
                <span *ngIf="expansionPanel.expanded"
                      class="d-inline-flex align-items-center justify-content-center">
                  {{ t('buttons.filters') }}
                  <i *ngIf="filtersAppliedCount.get(cashRegister.regNumber) > 0"
                     class="kkm-button__filters-count">{{ filtersAppliedCount.get(cashRegister.regNumber) }}</i>
              </span>
              </button>

            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <kkm-shift-list [cashRegister]="cashRegister"
                        [isFilterActive]="filtersOpenedState.get(cashRegister.regNumber)"
                        [selectedReceiptId]="selectedReceiptId"
                        [shifts]="shiftsMap[cashRegister.regNumber]"
                        [redrawCallback]="emitScrollToSummary.bind(this)"
                        [isLoading]="cashRegisterLoadingRegNumber === cashRegister.regNumber"
                        [taxpayer]="taxpayer"
                        [isShowFdNumber]="isShowFdNumber"
                        (onFilter)="setFiltersAppliedState($event)"
                        (onReceiptClick)="openReceipt($event)"></kkm-shift-list>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
