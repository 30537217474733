import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CdkConnectedOverlay,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { BehaviorSubject, EMPTY, iif, merge, Observable } from 'rxjs';
import { delay, filter, mapTo, switchMap } from 'rxjs/operators';

@Component({
  selector: 'kkm-overlay-panel',
  templateUrl: './overlay-panel.component.html',
  styleUrls: ['./overlay-panel.component.styl']
})
export class OverlayPanelComponent implements OnInit, OnChanges {
  @Input() showOverlay: boolean = false;
  @Input() showCloseButton: boolean = true;
  @Input() width: string = 'auto';
  @Output() emitResetShowOverlay: EventEmitter<void> = new EventEmitter<void>();

  public showPanel$: Observable<boolean>;
  public positions: ConnectedPosition[];

  @ViewChild(CdkConnectedOverlay, { static: true })
  public connectedOverlay: CdkConnectedOverlay;

  private showOverlaySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showOverlay);
  private isPanelVisible$: Observable<boolean>;
  private isPanelHidden$: Observable<boolean>;
  private isOverlayDetached$: Observable<void>;

  constructor() {
    this.positions = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        panelClass: 'kkm-overlay-panel__overlay_start_bottom_start_top',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'bottom',
      },
    ];
  }

  public ngOnInit(): void {
    this.isPanelVisible$ = this.showOverlaySubject$
      .pipe(
        filter((value) => !!value),
        mapTo(true)
      );

    this.isOverlayDetached$ = this.isPanelVisible$.pipe(
      delay(0),
      switchMap(() =>
        iif(
          () => !!this.connectedOverlay.overlayRef,
          this.connectedOverlay.overlayRef.detachments(),
          EMPTY
        )
      )
    );

    this.isPanelHidden$ = merge(
      this.isOverlayDetached$,
      this.connectedOverlay.backdropClick
    ).pipe(mapTo(false));

    this.showPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showOverlaySubject$.next(changes.showOverlay.currentValue);
  }

  public resetShowOverlay(): void {
    this.connectedOverlay.overlayRef.detach();
    this.emitResetShowOverlay.emit();
  }
}
