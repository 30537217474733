import { Directive, Host, Input } from '@angular/core';
import { NgForOf } from '@angular/common';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngForTrackByField]'
})
export class NgForTrackByFieldDirective<T> {
  @Input()
  public ngForTrackByField: keyof T;

  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, item: T) => {
      return this.ngForTrackByField && item.hasOwnProperty(this.ngForTrackByField)
        ? item[this.ngForTrackByField]
        : item;
    };
  }
}
