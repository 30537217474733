import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ICurrency } from '@common/interfaces';

export class CurrencyCatalogValidators {
  public static currencyUniqueCodeValidator(currencyList: ICurrency[]): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      const code = control.value;
      const isUnique = currencyList
        .filter((currency: ICurrency) => !currency.isEdit)
        .every((currency: ICurrency) => currency.code !== code);

      return isUnique
        ? null
        : { codeNotUnique: { code: code } };
    };
  }

  public static currencyUniqueNameValidator(currencyList: ICurrency[]): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      const name = control.value;
      const isUnique = currencyList
        .filter((currency: ICurrency) => !currency.isEdit)
        .every((currency: ICurrency) => currency.name !== name);

      return isUnique
        ? null
        : { nameNotUnique: { code: name } };
    };
  }
}
