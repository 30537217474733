import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {
  IReceipt,
  IShift,
  IXReport,
  IZReport,
  XReportModel,
  ZReportModel
} from '@common/interfaces';
import {catchError, first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {of} from 'rxjs';

@Injectable()
export class ShiftService {
  private COMMON_ENDPOINT: string;

  constructor(
    @Inject('env') private env,
    private http: HttpClient,
  ) {
    this.COMMON_ENDPOINT = env?.shared?.services?.shift?.commonEndpoint || '';
  }

  getShifts(params): Observable<IShift[]> {
    return this.http.get<IShift[]>(`${this.COMMON_ENDPOINT}/shifts`, {params})
      .pipe(
        first(),
        catchError(error => {
          throw of(error)
        })
      );
  }

  getVoucherByShift(shiftId: string, fds: number[]): Observable<IReceipt[]> {
    return this.http.post<IReceipt[]>(`${this.COMMON_ENDPOINT}/shift/${shiftId}/vouchers`, { fds: fds })
      .pipe(
        first(),
        catchError(error => {
          throw of(error)
        })
      );
  }

  getXReport(shiftId: string, params): Observable<{status: number; response?: XReportModel}> {
    return this.http.get(`${this.COMMON_ENDPOINT}/shift/open-report/${shiftId}`, {params}).pipe(
      map((response: IXReport) => ({status: 200, response: new XReportModel(response)})),
      catchError((error: HttpErrorResponse) => of({status: error.status})),
    );
  }

  getOpeningReport(shiftId: string, params): Observable<{status: number; response?: XReportModel}> {
    return this.http.get(`${this.COMMON_ENDPOINT}/shift/open-report/${shiftId}`, {params}).pipe(
      map((response: IXReport) => ({status: 200, response: new XReportModel(response)})),
      catchError((error: HttpErrorResponse) => of({status: error.status})),
    );
  }

  getZReport(shiftId: string, params): Observable<{status: number; response?: ZReportModel}> {
    return this.http.get(`${this.COMMON_ENDPOINT}/shift/z-report/${shiftId}`, {params}).pipe(
      map((response: IZReport) => ({status: 200, response: new ZReportModel(response)})),
      catchError((error: HttpErrorResponse) => of({status: error.status})),
    );
  }
}
