import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private static LANG_KEY = 'app_lang';
  state: BehaviorSubject<string> = new BehaviorSubject(TranslateService.language)

  constructor() { }

  setLocale(locale: string) {
    this.state.next(locale);
  }

  static get language(): string {
    return localStorage.getItem(this.LANG_KEY) || 'ru';
  }

}

