import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import ru from '@angular/common/locales/ru';
import ky from '@angular/common/locales/ky';
import { UiModule } from '@kkm-ui/ui';
import { AppRoutingModule } from './app-routing.module';
import { SessionService } from './services/session/session.service';
import { SessionKeycloakService } from "./services/session/session-keycloak.service";
import { LanguageInterceptor } from './services/session/language.interceptor';
import { ErrorsInterceptor } from './services/session/errors.interceptor';
import { TokenInterceptor } from './services/session/token.interceptor';
import { TokenKeycloakInterceptor } from "./services/session/token-keycloak.interceptor";

import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { TranslocoRootModule } from './transloco-root.module';
import { environment } from '@ais-no/env';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {ServerErrorScreenModule} from './components/server-error-screen/server-error-screen.module';
registerLocaleData(en, 'en');
registerLocaleData(ru, 'ru');
registerLocaleData(ky, 'ky');

import { AppInitService } from './services/settings/init.service';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { SettingsService } from './services/settings/settings.service';
import { EnvironmentInitService } from '../../../../libs/ui/src/lib/services/environment.service';

const getQueryAuthToken = (): string | undefined => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('token') || localStorage.getItem('token');
}

export const initializeApp = (appInitService: EnvironmentInitService, settingsService: SettingsService, sessionService: SessionService) => {
  return () => {
    const token = getQueryAuthToken()
    return new Promise<void>(async (resolve, reject) => {
      await sessionService
        .getCurrentUser(token)
        .toPromise()
        .then(async () => {
          await appInitService.Init();
          await settingsService.initMapLang();
          resolve();
        });
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ScrollingModule,
    AppRoutingModule,
    UiModule.forRoot(environment),
    HttpClientModule,
    TranslocoRootModule,
    ServerErrorScreenModule,
    MatDialogModule,
  ],
  providers: [
    { provide: SessionService, useClass: environment.useSSO ? SessionKeycloakService : SessionService },
    AppInitService,
    { provide: MAT_DATE_LOCALE, useValue: SettingsService.language },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [EnvironmentInitService, SettingsService, SessionService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.useSSO ? TokenKeycloakInterceptor : TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {panelClass: 'kkm-dialog_panel', hasBackdrop: true }}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
