import * as moment from 'moment';
import {
  IAddress,
  IBoundaryDates,
  IDetailedReceipt,
  IOverallTicketStatistics,
  IReceipt,
  IReceiptStatistics
} from "../interfaces";
import { BackendReceiptOperationTypes, BackendReceiptTypes, SyntheticReceiptTypes } from '../enums';
import { sumBy } from 'lodash';
import { FormControl } from '@angular/forms';

export function getDefaultStatsBetweenDates(startDate: string, endDate: string): { [key: string]: IReceiptStatistics } {
  let dates = getAllDaysBetweenDates(startDate, endDate);
  return dates.reduce((p, c) => {
    return {
      ...p,
      [c]: {
        avgTicket: 0,
        income: 0,
        revenue: 0,
        ticketCorrectionCount: 0,
        ticketIncomeCount: 0,
        ticketRefundCount: 0,
        totalCorrectIncome: 0,
        totalCorrectRefund: 0,
        totalIncome: 0,
        totalRefund: 0,
        totalTicketCount: 0,
        totalVat20: 0,
        vat20Correction: 0,
        vat20Income: 0,
        vat20Refund: 0,
      }
    }
  }, {});
}

export function getAllDaysBetweenDates(startDate: string, endDate:string): string[] {
  let dates = []
  while(moment(startDate) <= moment(endDate)){
    dates.push(startDate);
    startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
  }
  return dates;
}
export function getQuarterBoundaryDates(quarter: string, year: string): IBoundaryDates {
  switch (quarter) {
    case 'Q1':
      return {
        startDate: moment(new Date(Number(year), 0, 1)).format('YYYY-MM-DD'),
        endDate: moment(new Date(Number(year), 2, 31)).format('YYYY-MM-DD'),
      };
    case 'Q2':
      return {
        startDate: moment(new Date(Number(year), 3, 1)).format('YYYY-MM-DD'),
        endDate: moment(new Date(Number(year), 5, 30)).format('YYYY-MM-DD'),
      };
    case 'Q3':
      return {
        startDate: moment(new Date(Number(year), 6, 1)).format('YYYY-MM-DD'),
        endDate: moment(new Date(Number(year), 8, 30)).format('YYYY-MM-DD'),
      };
    case 'Q4':
      return {
        startDate: moment(new Date(Number(year), 9, 1)).format('YYYY-MM-DD'),
        endDate: moment(new Date(Number(year), 11, 31)).format('YYYY-MM-DD'),
      };
  }
}

/**
 *
 * @param number Number
 * @param txt for example Russian: ['Рубль', 'Рубля', 'Рублей', 'Рубль'], English: ['Ruble', 'Rubles', 'Rubles', 'Rubles']
 * @param cases
 */
export const sklonenie = (number: number, txt: string[], cases = [2, 3, 1, 1, 1, 2]) => txt[number === 1 ? 0 : (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];


export function getMonthBoundaryDates(month: string, year: string): IBoundaryDates {
    const startDate: string = moment([year, month]).format('YYYY-MM-DD');
    const endDate: string = moment(startDate).endOf('month').format('YYYY-MM-DD');
    return {
      startDate,
      endDate
    };
}

export function getYearBoundaryDates(year: string): IBoundaryDates {
  return {
    startDate: `${year}-01-01`,
    endDate: `${year}-12-31`,
  };
}

export function getOverallStatistics(statistics: { [key: string]: IReceiptStatistics }): IOverallTicketStatistics {

  const getGovFee = (stats: IReceiptStatistics, key: SyntheticReceiptTypes): number => {
    if (stats == null || stats.ticketCounters == null || stats.ticketCounters[key] == null) {
      return 0;
    }

    return stats.ticketCounters[key].totalGovernmentFee;
  };

  return Object
    .keys(statistics)
    .reduce((p: IOverallTicketStatistics, c: string) => ({
      revenue: Math.round(p.revenue + statistics[c].revenue),
      vat: Math.round(p.vat + (sumBy(statistics[c].vat, i => i.totalValue))),
      st: Math.round(p.st + (sumBy(statistics[c].st, i => i.totalValue))),
      receipts: Math.round(p.receipts + (statistics[c] as IReceiptStatistics).totalTicketCount),
      averageReceipt: Math.round(p.averageReceipt + (statistics[c] as IReceiptStatistics).avgTicket),
      totalGovernmentFee: Math.round(p.totalGovernmentFee + (getGovFee(statistics[c], SyntheticReceiptTypes.INCOME) + getGovFee(statistics[c], SyntheticReceiptTypes.INCOME_RETURN))),
    }), {
      revenue: 0,
      vat: 0,
      st: 0,
      receipts: 0,
      averageReceipt: 0,
      totalGovernmentFee: 0,
    } as IOverallTicketStatistics);
}

export function getReceiptType(receipt: IReceipt|IDetailedReceipt): SyntheticReceiptTypes {
  if (!receipt) return SyntheticReceiptTypes.INCOME;
  const { operationType, correction } = receipt;

  if (!!correction && typeof correction === 'object') {

    switch (operationType) {
      case 1:
        return SyntheticReceiptTypes.CORRECTION_INCOME;
      case 2:
        return SyntheticReceiptTypes.CORRECTION_INCOME_RETURN;
      case 3:
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE;
      case 4:
        return SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN;
    }

  } else {

    switch (operationType) {
      case 1:
        return SyntheticReceiptTypes.INCOME;
      case 2:
        return SyntheticReceiptTypes.INCOME_RETURN;
      case 3:
        return SyntheticReceiptTypes.EXPENDITURE;
      case 4:
        return SyntheticReceiptTypes.EXPENDITURE_RETURN;
    }

  }
}

export function getSyntheticReceiptType(receipt: {type: BackendReceiptTypes, operationType: BackendReceiptOperationTypes}): SyntheticReceiptTypes {
  if (receipt == null)
    throw new Error('receipt is null');

  switch (receipt.type) {
    case BackendReceiptTypes.TICKET:
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME) return SyntheticReceiptTypes.INCOME;
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME_RETURN) return SyntheticReceiptTypes.INCOME_RETURN;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE) return SyntheticReceiptTypes.EXPENDITURE;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE_RETURN) return SyntheticReceiptTypes.EXPENDITURE_RETURN;
      break;

    case BackendReceiptTypes.CORRECTION_TICKET:
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME) return SyntheticReceiptTypes.CORRECTION_INCOME;
      if (receipt.operationType === BackendReceiptOperationTypes.INCOME_RETURN) return SyntheticReceiptTypes.CORRECTION_INCOME_RETURN;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE) return SyntheticReceiptTypes.CORRECTION_EXPENDITURE;
      if (receipt.operationType === BackendReceiptOperationTypes.EXPENDITURE_RETURN) return SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN;
      break;

    default:
      throw new Error(`unsupported receipt type ${receipt.type}`);
  }
}

export function convertAddressObjectToString(address: IAddress): string {
  if (address && typeof address === 'object') {
    return [
      address.postalCode,
      address.route,
      address.streetNumber,
      address.locality,
      address.country,
    ].filter(Boolean).join(', ')
  }
  return '';
}

export function calculateTaxes(taxes = []): number {
  return sumBy((taxes), i => i.totalValue);
}

export const checkForOnlyNumbers = (control: FormControl, invalid: any) => {
  const onlyNumbers = /^\d+$/;
  const replaceNumbers = /\D/;
  const vcOnlyNumbers = onlyNumbers.test(control.value);
  if (control.value && !vcOnlyNumbers) {
    control.setValue(control.value.replace(replaceNumbers, ''));
    control.markAsTouched();
    control.markAsDirty();
    invalid.state = true;
    return false;
  }
  return true;
}

export const checkForMaxLength = (control: FormControl, length: number) => {
  if (control.value.length > length) {
    control.markAsTouched();
    return false;
  }
  return true;
}

export const getFormatterNumber = (regNumber: string, prefix: string = '№') => {
  if (!regNumber) {
    return '';
  }
  const parts = [
    regNumber.substr(0, 4),
    regNumber.substr(4, 4),
    regNumber.substr(8, 4),
    regNumber.substr(12, 4)
  ];
  const number = parts.join(' ');
  return prefix ? `${prefix} ${number}` : `${number}`;
};
