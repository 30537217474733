import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { IPaginate, IPaginatorState } from '@common/interfaces';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { OverlayPanelComponent } from '../overlay-panel/overlay-panel.component';
import IMask from 'imask';
import { PAGE_SIZE } from '@common/constants';

@Component({
  selector: 'kkm-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.styl']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() isDataLoaded: boolean;
  @Input() pagination: IPaginate;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(OverlayPanelComponent) overlay: OverlayPanelComponent;

  public form: FormGroup;
  public showItemsPerPageSelector: boolean = false;
  public pageNumberMask;

  constructor() {
    this.pageNumberMask = new IMask.MaskedNumber({
      mask: Number,
      scale: 0,
      signed: false,
      thousandsSeparator: '',
      min: 1,
      max: Number.MAX_SAFE_INTEGER
    });
  }

  public ngOnInit(): void {
    this.form = new FormGroup({
      pageNumber: new FormControl(this.paginatorState.pageNumber, [Validators.required, Validators.min(1), this.maxPageNumber()]),
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.pageNumberMask.updateOptions({ max: this.paginatorState.totalPages});
    if (this.form) {
      this.form.setValue({ pageNumber: String(this.paginatorState.pageNumber) });
    }
  }

  public handlePageChange(page: number) {
    this.pagination.page = page - 1;
    this.form.setValue({ pageNumber: String(page) });
    this.pageChanged.emit(this.pagination.page);
  }

  public get paginatorState(): IPaginatorState {
    const isDataLoaded = this.isDataLoaded;
    const page = this.pagination?.page || 0;
    const pageNumber = page + 1;
    const totalPages = this.pagination?.totalPages || 1;
    const totalItems = this.pagination?.totalElements || 0;
    const pageSize = this.pagination?.pageSize || PAGE_SIZE;
    const hasNextPage = isDataLoaded && !!totalItems && (pageNumber * pageSize) < totalItems;
    const hasPrevPage = pageNumber > 1;
    const currentStartOffset = totalItems === 0 ? 0 : pageNumber === 1 ? 1 : (pageNumber - 1) * pageSize + 1;
    const currentEndOffset = (currentStartOffset - 1) + Math.min(pageSize, totalItems - currentStartOffset + 1);

    return {
      label: "",
      isDataLoaded,
      hasPrevPage,
      hasNextPage,
      pageNumber,
      totalPages,
      totalItems,
      currentStartOffset,
      currentEndOffset,
    };
  }

  public submitPage(): void {
    if (this.form.invalid) {
      return;
    }

    this.handlePageChange(this.pageNumberMask.typedValue);
  }

  public setPageSize(pageSize: number): void {
    this.pagination.pageSize = pageSize;
    this.handlePageChange(1);

    this.overlay.resetShowOverlay();
  }

  private maxPageNumber(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      if (control.value == null || control.value === '') {
        return null;
      }

      const pageNumber = control.value;
      const parsedPageNumber = parseFloat(pageNumber);
      if (isNaN(pageNumber) || isNaN(parsedPageNumber)) {
        return {
          pageNumber: {
            pageNumber: pageNumber,
            totalPages: this.paginatorState.totalPages,
          }
        };
      }

      return parsedPageNumber <= this.paginatorState.totalPages
        ? null
        : {
          pageNumber: {
            pageNumber: pageNumber,
            totalPages: this.paginatorState.totalPages,
          }
        };
    };
  }
}
