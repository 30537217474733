import { ChangeDetectorRef, Component, Optional } from '@angular/core';
import { MatMultiYearView } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { Directionality } from '@angular/cdk/bidi';
import { IYear } from '../kkm-datepicker.interfaces';
import { getYearsArray } from '../kkm-datepicker.helpers';

@Component({
  selector: 'kkm-multi-year-view',
  templateUrl: './kkm-multi-year-view.component.html',
  styleUrls: ['./kkm-multi-year-view.component.styl']
})
export class KkmMultiYearViewComponent<D> extends MatMultiYearView<D> {
  years: IYear[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef,
              @Optional() public dateAdapter: DateAdapter<D>,
              @Optional() private dir?: Directionality) {
    super(changeDetectorRef, dateAdapter, dir);

    this.years = getYearsArray();
  }

  selectYear(data: IYear): void {
    const year = data.year;
    this.yearSelected.emit(this._dateAdapter.createDate(year, 0, 1));

    const month = this._dateAdapter.getMonth(this.activeDate);
    const daysInMonth = this._dateAdapter.getNumDaysInMonth(this._dateAdapter.createDate(year, month, 1));
    this.selectedChange.emit(this._dateAdapter.createDate(year, month, Math.min(this._dateAdapter.getDate(this.activeDate), daysInMonth)));
  }

  yearIdentity(index: number, item: IYear): number {
    return item.year;
  }

  isYearSelected(year: IYear): boolean {
    const activeYear = this.dateAdapter.getYear(this.activeDate);
    return year.year === activeYear;
  }
}
