import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ICashRegisterDisplayOptions, IReceipt } from '@common/interfaces';
import { getReceiptType } from '@common/utils';
import { SyntheticReceiptTypes } from '@common/enums';
import { isEmpty } from 'lodash';
import {ReceiptGridService} from "../../services/receipt-grid.service";

@Component({
  selector: 'kkm-cr-details',
  templateUrl: './cr-details.component.html',
  styleUrls: ['./cr-details.component.styl']
})
export class CrDetailsComponent implements OnInit {
  private _receipts: any[] = [];

  TRANSLATION_READ_PATH: string = 'common.components.cr-details';
  @Input() isOpened = false;
  @Input() isLoading = false;
  @Input() cashRegister: any;
  @Input() public set receipts(value: IReceipt[]) {
    this._receipts = value.map((receipt: IReceipt) => this.receiptGridService.extendReceiptErrorsData(receipt));
  }
  public get receipts(): any[] {
    return this._receipts;
  }

  @Input() registrationHistoryData: any[] = [];
  @Input() cashRegisterDisplayOptions: ICashRegisterDisplayOptions = { showOnline: true, showStatus: true };
  @Output() closeCashRegisterDetails: EventEmitter<void> = new EventEmitter<void>();

  constructor(private receiptGridService: ReceiptGridService) { }

  public ngOnInit(): void {
  }

  public handleClose(): void {
    this.closeCashRegisterDetails.emit();
  }

  public translate(key: string): string {
    return translate(`${this.TRANSLATION_READ_PATH}.receipt-types.${key}`);
  }

  public getReceiptTypeTitle(receipt: IReceipt): string {
    const receiptType = getReceiptType(receipt);

    switch(receiptType) {
      case SyntheticReceiptTypes.INCOME:
        return this.translate('income');
      case SyntheticReceiptTypes.INCOME_RETURN:
        return this.translate('income-return');
      case SyntheticReceiptTypes.EXPENDITURE:
        return this.translate('expense');
      case SyntheticReceiptTypes.EXPENDITURE_RETURN:
        return this.translate('expense-return');
      case SyntheticReceiptTypes.CORRECTION_INCOME:
        return this.translate('correction-income');
      case SyntheticReceiptTypes.CORRECTION_INCOME_RETURN:
        return this.translate('correction-income-return');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE:
        return this.translate('correction-expense');
      case SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN:
        return this.translate('correction-expense-return');
    }
  }

  public isAgentReceipt(receipt: IReceipt): boolean {
    return !isEmpty(receipt.supplierTin);
  }
}
