<div class="d-flex justify-content-between columns_wrap body_text" *transloco="let t; read: TRANSLATION_READ_PATH">
  <div class="counter_column" *ngFor="let column of countersColumns; trackByField:'columnName'">
    <div class="counter_header" [ngClass]="column.className ? column.className : ''" [textContent]="column.columnName"></div>
    <div class="d-flex flex-row counter_row" *ngFor="let row of countersRows; trackByField:'name'" [ngClass]="row.cssClass ? row.cssClass : ''">
      <div class="counter_row_name muted_text" [textContent]="row.name"></div>
      <div class="counter_row_value">
        <kkm-money *ngIf="row.isMoney" [amount]="absolute(row[column.rowKey])"></kkm-money>
        <span *ngIf="!row.isMoney" [textContent]="row[column.rowKey]"></span>
      </div>
    </div>
  </div>
</div>
