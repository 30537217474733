import {IReportModel, IShiftReport} from "./index";
import {ITaxCounter} from "../index";

interface ICommonSummaryCounters {
  totalSum: number;
  totalCashSum: number;
  totalCashlessSum: number;
  operationPrePaymentSum: number;
  operationPostPaymentSum: number;
  taxCounters: ITaxCounter[];
}

export interface IIncomeSummaryCounters extends ICommonSummaryCounters {
  operationTicketsAmount: number;
}

export interface ICorrectionSummaryCounters extends ICommonSummaryCounters {
  operationTicketsAmount: number;
  correctionSelfAmount: number;
  correctionForcedAmount: number;
}

export interface IZReport extends IShiftReport {
  fdNumber: number;
  dataFormatVersion: number;
  documentFiscalMark: string;
  shiftFdsAmount: number;
  shiftTicketsAmount: number;
  unconfirmedFdsAmount: number;
  shiftSummaryCounters: {
    totalTicketsAmount: number;
    income: IIncomeSummaryCounters;
    incomeReturn: IIncomeSummaryCounters;
    expenditure: IIncomeSummaryCounters;
    expenditureReturn: IIncomeSummaryCounters;
    correctionCounters: {
      correctionTicketsAmount: number;
      income: ICorrectionSummaryCounters;
      incomeReturn: ICorrectionSummaryCounters;
      expenditure: IIncomeSummaryCounters;
      expenditureReturn: IIncomeSummaryCounters;
    };
  };
}

export class ZReportModel implements IReportModel {
  data: IZReport;

  constructor(data: IZReport) {
    this.data = data;
  }

  get incomes(): IIncomeSummaryCounters {
    return this.data.shiftSummaryCounters.income;
  }

  get returns(): IIncomeSummaryCounters {
    return this.data.shiftSummaryCounters.incomeReturn;
  }

  get expenses(): IIncomeSummaryCounters {
    return this.data.shiftSummaryCounters.expenditure;
  }

  get expenseReturns(): IIncomeSummaryCounters {
    return this.data.shiftSummaryCounters.expenditureReturn;
  }

  get corrections() {
    return {
      incomes: this.data.shiftSummaryCounters.correctionCounters.income,
      returns: this.data.shiftSummaryCounters.correctionCounters.incomeReturn,
      expenses: this.data.shiftSummaryCounters.correctionCounters.expenditure,
      expenseReturns: this.data.shiftSummaryCounters.correctionCounters.expenditureReturn
    }
  }

}
