import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {StatService} from '../../services/stat.service';
import {CashRegisterService} from '../../services/cash-register.service';
import { IBoundaryDates, ICashRegister, ICrList, IStore, ITicketStatistics } from '@common/interfaces';
import {AnalyticsDateFilterTypes} from '@common/enums'
import {getMonthBoundaryDates, getQuarterBoundaryDates, getYearBoundaryDates} from '@common/utils';
import { EventBusService } from '../../services/event-bus/event-bus.service';
import moment from "moment";
import {Quarters} from "../kkm-datepicker/kkm-datepicker.interfaces";

@Component({
  selector: 'kkm-sales-analytics',
  templateUrl: './sales-analytics.component.html',
  styleUrls: ['./sales-analytics.component.styl']
})
export class SalesAnalyticsComponent implements OnInit, OnDestroy {
  @Input() taxpayerId: string | number;
  @Input() taxpayer?: any;
  @Input() kktId?: string;
  @Input() isYaTaxiPayer: boolean = false;
  @Input() isShowFdNumber?: boolean;

  private qpSubscription: Subscription = Subscription.EMPTY;
  isLoaded = false;
  isStatLoading = true;
  receiptsCount: number = 0;
  statistics: ITicketStatistics;
  agentStatistics: ITicketStatistics;
  agentCashRegisters: ICrList[] = [];
  cashRegisters: ICrList[] = [];
  mainFilter;
  receiptFilter;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statService: StatService,
    private crService: CashRegisterService,
    private eventBus: EventBusService,
  ) {
  }

  async ngOnInit() {
    await this.loadAgentCachRegisters();

    this.qpSubscription = this.route.queryParams.subscribe(() => {
      this.receiptsCount = 0;
      this.mainFilter = this.getMainFilter();
      this.receiptFilter = this.getReceiptFilter();
      this.loadStats();
    });

    await this.loadCashRegisters();

    if (this.kktId) {
      this.applyFilter({kktList: [this.kktId]});
    }

    this.eventBus.on("receipts-summary/load-receipts-count", (receiptsCount) => this.receiptsCount = receiptsCount);
  }

  ngOnDestroy(): void {
    this.qpSubscription.unsubscribe();
  }

  get hasStatistics(): boolean {
    return (this.statistics != null && this.statistics.originalDailyStatisticsCount > 0)
      || (this.agentStatistics != null && this.agentStatistics.originalDailyStatisticsCount > 0);
  }

  get hasReceipts(): boolean {
    return this.receiptsCount > 0;
  }

  get allCashRegisters() {
    return [...this.cashRegisters, ...this.agentCashRegisters && this.agentCashRegisters];
  }

  async loadAgentCachRegisters() {
    try {
      const cashRegisters = await this.crService.getAgentCashRegisters(this.taxpayerId).toPromise();
      this.agentCashRegisters = cashRegisters.map(cr => ({
        id: cr,
        address: "",
        createdDate: null,
        regDate: null,
        modifyDate: null,
        status: null,
        sn: null,
        taxSystems: [],
        calcItemAttributes: [],
      }))
    } catch (err) {
      console.log(err);
    }
  }

  async loadStats() {
    try {
      this.isStatLoading = true;
      this.statistics = await this.statService.getStatSummary(this.taxpayerId as string, this.mainFilter, false).toPromise();
      this.agentStatistics = await this.statService.getStatSummary(this.taxpayerId as string, this.mainFilter, true).toPromise();
    } catch (err) {
      console.log(err);
    } finally {
      this.isStatLoading = false;
    }
  }

  async loadCashRegisters() {
    try {
      this.isLoaded = false;
      const cashRegisters = await this.crService.getCashRegisters(this.taxpayerId, true).toPromise();
      this.cashRegisters = cashRegisters.reduce((p: ICrList[], c: IStore) => {
        (c.cashRegisters || []).forEach((cr: ICashRegister) => p.push({
          id: cr.regNumber,
          address: cr.originalAddress,
          createdDate: cr.createdDate,
          regDate: cr.regDate,
          modifyDate: cr.modifyDate,
          status: cr.status,
          sn: cr.sn,
          taxSystems: cr.taxSystems,
          calcItemAttributes: cr.calcItemAttributes,
        }));
        return p;
      }, []);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoaded = true;
    }
  }

  getMainFilter() {
    const month = moment().month();
    const quarterNumber = moment().quarter();
    const quarter = Quarters["Q" + quarterNumber];

    const opts = this.opts?.search || {};
    return {
      dateFilterType: "MONTH",
      month: month,
      year: this.years[this.years.length - 1],
      quarter: quarter,
      ...opts,
    };
  }

  getReceiptFilter() {
    let boundaryDates: IBoundaryDates;
    const {dateFilterType, quarter, month, year, kktList = []} = this.mainFilter;
    switch (dateFilterType) {
      case AnalyticsDateFilterTypes.QUARTER:
        boundaryDates = getQuarterBoundaryDates(quarter, year);
        break;
      case AnalyticsDateFilterTypes.MONTH:
        boundaryDates = getMonthBoundaryDates(month, year);
        break;
      default:
        boundaryDates = getYearBoundaryDates(year);
    }
    return {
      lpId: this.taxpayerId,
      from: boundaryDates.startDate,
      to: boundaryDates.endDate,
      kktIds: kktList
    }
  }

  applyFilter(search) {
    this.opts = {search};
  }

  get opts() {
    const {p = null} = this.route.snapshot.queryParams || {};
    return p ? JSON.parse(
      decodeURIComponent(
        atob(p.startsWith('?') ? p.slice(1) : p)
          .split('')
          .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      )
    ) : {};
  }

  set opts(o) {
    const p: string = btoa(
      encodeURIComponent(JSON.stringify(o || {}))
        .replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(`0x${p1.toString()}`, 16)))
    );

    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParams: {p},
    });
  }

  get years() {
    let currentYear = new Date().getFullYear();
    const yearsArray: number[] = [];
    const lengthArray = 3;

    currentYear = currentYear - lengthArray;
    for (let i = 0; i < lengthArray; i++) {
      currentYear++;
      yearsArray.push(currentYear);
    }
    return yearsArray;
  }
}
