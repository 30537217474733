<div class="recovery" *transloco="let t, read: 'common.components.recovery'">
  <div class="recovery__group">
    <div class="recovery__title" *transloco="let r">
      <h1 class="decor-title bg_text text-center p_b-2" [textContent]="r(titles.title)"></h1>
      <h2 class="bg_text text-center" [textContent]="r(titles.subtitle)"></h2>
    </div>

    <div class="recovery__rec-form">
      <div class="d-flex justify-content-between align-items-baseline m_b-6">
        <h2 [textContent]=" t(state === recoveryStates.PASSWORD ? 'new-password' : 'recover-password')"></h2>
        <kkm-lang-select [default]="settings.selectedLang" [languages]="settings.languages" (selected)="selectLanguage($event)"></kkm-lang-select>
      </div>
      <div class="fail-area body_text m_b-6" *ngIf="statusCode !== 200 && failAreaErrors.length">
        <p *ngFor="let error of failAreaErrors" class="p_b-1">
          <ng-container [ngSwitch]="error">
            <ng-container *ngSwitchCase="dataAlreadyExistErrors.FnNotUnique">{{t('errors.fn-not-unique')}}</ng-container>
            <ng-container *ngSwitchDefault>{{error}}</ng-container>
          </ng-container>
        </p>
      </div>

      <div *ngIf="state === recoveryStates.EMAIL">
        <form [formGroup]="emailForm" (ngSubmit)="submitEmail()" class="register_form" focusFirstInvalid>
          <div>
            <div class="form-item">
              <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
                <mat-label>{{t('email-label')}}</mat-label>
                <input #emailInput matInput type="text" autocomplete="off" formControlName="email" clearIfCorrect>
              </mat-form-field>
              <kkm-input-popover *ngIf="isFocused(emailInput)
                                          && !emailControl.value
                                          && emailControl.dirty
                                          && emailControl.touched
                                          && emailControl.invalid"
                                 [text]="t('errors.email-required')">
              </kkm-input-popover>
              <kkm-input-popover *ngIf="isFocused(emailInput)
                                          && emailControl.value
                                          && emailControl.dirty
                                          && emailControl.touched
                                          && emailControl.invalid"
                                 [text]="t('errors.email-pattern')">
              </kkm-input-popover>
              <kkm-input-popover  *ngIf="emailControl.errors?.usedValue"
                                  class="register-form__error-popover"
                                  [text]="t('errors.email-not-unique')"></kkm-input-popover>
            </div>
            <div class="w-fluid">
              <button
                type="submit"
                class="kkm-button w-fluid"
                mat-button
                color="primary"
                [textContent]="t('submit-email')">
              </button>
            </div>
          </div>
        </form>
        <p class="body_text align-center p_t-10">
          <a class="no-text-decoration" [textContent]="t('back-to-login')" [routerLink]="['/sign-in']"></a>
        </p>
      </div>

      <div *ngIf="state === recoveryStates.SENT">
        <div class="small_text">
          <p [textContent]="t('we-sent-email')"></p>
          <p [textContent]="t('please-check-email')"></p>
        </div>
        <div class="done-disclaimer__img"></div>
        <p class="body_text align-center p_t-10">
          <a class="no-text-decoration" [textContent]="t('back-to-login')" [routerLink]="['/sign-in']"></a>
        </p>
      </div>

      <div *ngIf="state === recoveryStates.EXPIRED">
        <div class="small_text">
          <p [textContent]="t('link-expired')"></p>
        </div>
        <div class="done-disclaimer__img"></div>
        <p class="body_text align-center p_t-10">
          <a class="no-text-decoration" [textContent]="t('back-to-login')" [routerLink]="['/sign-in']"></a>
        </p>
      </div>

      <div *ngIf="state === recoveryStates.PASSWORD">
        <div class="small_text m_b-6">
          <p [textContent]="t('password-tip')"></p>
        </div>
        <form [formGroup]="passwordForm" (ngSubmit)="submitPassword()" class="register_form" focusFirstInvalid>
          <div class="form-item">
            <mat-form-field appearance="fill"
                            class="kkm-form-field w-fluid m_b-6"
                            [class.eye-button_visible-by-input]="passwordControl.value || isFocused(passwordInput)">
              <mat-label>{{t('password-label')}}</mat-label>
              <input  #passwordInput matInput
                      [type]="passwordHide ? 'password' : 'text'"
                      autocomplete="off"
                      formControlName="password"
                      clearIfCorrect>
              <button type="button"
                      class="kkm-button eye-button eye-button_visible-by-hover"
                      mat-icon-button
                      matSuffix
                      (click)="passwordHide = !passwordHide">
                <mat-icon [svgIcon]="passwordHide ? 'eye:on' : 'eye:off'"></mat-icon>
              </button>
            </mat-form-field>
            <kkm-input-popover *ngIf="isFocused(passwordInput)
                                        && !passwordControl.value
                                        && passwordControl.dirty
                                        && passwordControl.touched
                                        && passwordControl.invalid"
                                [text]="t('errors.password-required')">
            </kkm-input-popover>
            <kkm-input-popover *ngIf="isFocused(passwordInput)
                                        && (passwordControl.value
                                        || (!passwordControl.value && passwordControl.pristine))"
                                [text]="t('errors.password-hint')">
            </kkm-input-popover>
          </div>
          <div class="w-fluid">
            <button
              type="submit"
              class="kkm-button w-fluid"
              mat-button
              color="primary"
              [textContent]="t('submit-password')">
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
