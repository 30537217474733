<div class="position-relative">
  <div *ngIf="isLoading" class="preloader middle table-preloader"></div>
  <table mat-table matSort class="kkm-table currency-catalog-table w-fluid"
         *transloco="let t, read: 'common.components.currency-catalog.table'"
         [dataSource]="dataSource"
         [matSortDisabled]="isLoading"
         [class.isLoading]="isLoading"
         [formGroup]="currencyForm">

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('code') }}</th>
      <td mat-cell *matCellDef="let element" [class.p_l-2]="element.isEdit">
        <span *ngIf="!element.isEdit" [textContent]="element.code"></span>
        <div *ngIf="element.isEdit" class="position-relative">
          <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid currency-catalog-table__form-field">
            <input #codeInput matInput type="text" clearIfCorrect [placeholder]="t('form.code.label')"
                   formControlName="code" autocomplete="new-code">
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(codeInput) && controlHasError(codeControl, 'required')"
                             class="zindex-popover width-content"
                             [text]="t('form.code.required')"></kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(codeInput) && controlHasError(codeControl, 'pattern')"
                             class="zindex-popover width-content"
                             [text]="t('form.code.pattern')"></kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(codeInput) && controlHasError(codeControl, 'codeNotUnique')"
                             class="zindex-popover width-content"
                             [text]="t('form.code.code-not-unique')"></kkm-input-popover>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('name') }}</th>
      <td mat-cell *matCellDef="let element" [class.p_l-2]="element.isEdit">
        <span *ngIf="!element.isEdit" [textContent]="element.name"></span>
        <div *ngIf="element.isEdit" class="position-relative">
          <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid currency-catalog-table__form-field">
            <input #nameInput matInput required type="text" clearIfCorrect [placeholder]="t('form.name.label')"
                   formControlName="name" autocomplete="new-name">
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(nameInput) && controlHasError(nameControl, 'required')"
                             class="zindex-popover width-content"
                             [text]="t('form.name.required')"></kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(nameInput) && controlHasError(nameControl, 'nameNotUnique')"
                             class="zindex-popover width-content"
                             [text]="t('form.name.name-not-unique')"></kkm-input-popover>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('amount') }}</th>
      <td mat-cell *matCellDef="let element" [class.p_l-2]="element.isEdit">
        <span *ngIf="!element.isEdit" [textContent]="element.amount"></span>
        <div *ngIf="element.isEdit" class="position-relative">
          <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid currency-catalog-table__form-field">
            <input #amountInput matInput required type="text" clearIfCorrect [placeholder]="t('form.amount.label')"
                   formControlName="amount" autocomplete="new-amount">
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(amountInput) && controlHasError(amountControl, 'required')"
                             class="zindex-popover width-content"
                             [text]="t('form.amount.required')"></kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(amountInput) && controlHasError(amountControl, 'min')"
                             class="zindex-popover width-content"
                             [text]="t('form.amount.min')"></kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(amountInput) && controlHasError(amountControl, 'pattern')"
                             class="zindex-popover width-content"
                             [text]="t('form.amount.pattern')"></kkm-input-popover>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="sellExchangeRate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('sell') }}</th>
      <td mat-cell *matCellDef="let element" [class.p_l-2]="element.isEdit">
        <kkm-money *ngIf="!element.isEdit" [amount]="element.sellExchangeRate" [isCurrency]="true" [round]="false"
                   notRoundedDigitsInfo="1.4-4"></kkm-money>
        <div *ngIf="element.isEdit" class="position-relative">
          <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid currency-catalog-table__form-field">
            <input #sellInput matInput formControlName="sell"
                   autocomplete="new-sell"
                   [placeholder]="t('form.sell.label')"
                   [imask]="sellMask"
                   [unmask]="true">
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(sellInput) && controlHasError(sellControl, 'required')"
                             class="zindex-popover width-content"
                             [text]="t('form.sell.required')"></kkm-input-popover>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="buyExchangeRate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('buy') }}</th>
      <td mat-cell *matCellDef="let element" [class.p_l-2]="element.isEdit">
        <kkm-money *ngIf="!element.isEdit" [amount]="element.buyExchangeRate" [isCurrency]="true" [round]="false"
                   notRoundedDigitsInfo="1.4-4"></kkm-money>
        <div *ngIf="element.isEdit" class="position-relative">
          <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid currency-catalog-table__form-field">
            <input #buyInput matInput formControlName="buy"
                   autocomplete="new-buy"
                   [placeholder]="t('form.buy.label')"
                   [imask]="buyMask"
                   [unmask]="true">
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(buyInput) && controlHasError(buyControl, 'required')"
                             class="zindex-popover width-content"
                             [text]="t('form.buy.required')"></kkm-input-popover>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifyDate">
      <th mat-header-cell *matHeaderCellDef [textContent]="t('modifyDate')"></th>
      <td mat-cell *matCellDef="let element">
        <kkm-date [date]="element.modifyDate" format="mediumDate"></kkm-date>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="currency-catalog-table__btn-cell">
        <button mat-icon-button
                type="button"
                class="icon-button"
                [matTooltip]="t(element.isEdit ? 'done' : 'edit')"
                matTooltipPosition="above"
                matTooltipClass="kkm-table-tooltip-above"
                (click)="element.isEdit ? submitCurrency(element) : editCurrency(element)">
          <mat-icon [svgIcon]="element.isEdit ? 'common:checkmark-currentColor' : 'action:edit-currentColor'"
                    class="brand-color giant"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete-show-hide">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="currency-catalog__table-btn-cell">

        <!-- close edit button -->
        <button *ngIf="element.isEdit"
                mat-icon-button
                type="button"
                class="icon-button"
                [matTooltip]="t( 'cancel')"
                matTooltipPosition="above"
                matTooltipClass="kkm-table-tooltip-above"
                (click)="closeEditCurrency(element)">
          <mat-icon svgIcon="close:register" class="brand-color giant"></mat-icon>
        </button>

        <ng-container *ngIf="!element.isEdit">

          <button *ngIf="currencyType === curType.Catalog"
                  mat-icon-button
                  type="button"
                  class="icon-button"
                  [matTooltip]="t( 'delete')"
                  matTooltipPosition="above"
                  matTooltipClass="kkm-table-tooltip-above"
                  (click)="removeCurrency(element)">
            <mat-icon svgIcon="action:remove" class="brand-color giant"></mat-icon>
          </button>

          <ng-container *ngIf="currencyType === curType.Local">
            <button *ngIf="element.type === curType.Local"
                    mat-icon-button
                    type="button"
                    class="icon-button"
                    [matTooltip]="t( 'delete')"
                    matTooltipPosition="above"
                    matTooltipClass="kkm-table-tooltip-above"
                    (click)="removeCurrency(element)">
              <mat-icon svgIcon="action:remove" class="brand-color giant"></mat-icon>
            </button>

            <button *ngIf="element.type === curType.Catalog"
                    mat-icon-button
                    type="button"
                    class="icon-button"
                    [matTooltip]="t( element.isHidden ? 'show' : 'hide')"
                    matTooltipPosition="above"
                    matTooltipClass="kkm-table-tooltip-above"
                    (click)="element.hidden ? showCurrency(element) : hideCurrency(element)">
              <mat-icon [svgIcon]="element.hidden ? 'eye:off' : 'eye:on'" class="eye-icon giant"></mat-icon>
            </button>
          </ng-container>

        </ng-container>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row class="currency-catalog-table__row"
        [class.currency-catalog-table__row_editable]="row.isEdit"
        [class.currency-catalog-table__row_muted]="row.hidden"
        *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
