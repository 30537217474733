import {Component, Input, OnInit} from "@angular/core";
import {CounterRow, CountersColumn} from "@common/interfaces";

@Component({
  selector: 'kkm-shift-operations-table',
  templateUrl: './shift-operations-table.component.html',
  styleUrls: ['./shift-operations-table.component.styl']
})

export class ShiftOperationsTableComponent implements OnInit {
  TRANSLATION_READ_PATH = 'common.components.shift-report';

  @Input() countersColumns: CountersColumn[];
  @Input() countersRows: CounterRow[];

  ngOnInit() {
  }

  absolute(val: number) {
    return Math.abs(val);
  }

}
