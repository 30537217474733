<div class="sidepage" [ngClass]="['cash-register-details-data-container', isOpened ? 'cash-register-details-data-container_is-opened' : '']" *transloco="let t, read: TRANSLATION_READ_PATH">
  <div class="close-button" (click)="handleClose()">
    <mat-icon svgIcon="close:dialog"></mat-icon>
  </div>

  <!-- Информация о кассе -->
  <kkm-cr-info class="m_b-8"
               [data]="cashRegister"
               [displayOptions]="cashRegisterDisplayOptions"></kkm-cr-info>

  <!-- История регистрации -->
  <kkm-cr-reg-history *ngIf="registrationHistoryData.length > 1"
                      class="m_b-8"
                      [records]="registrationHistoryData"
                      [compact]="true"></kkm-cr-reg-history>

  <!-- Последние чеки -->
  <div *ngIf="receipts && receipts.length > 0" class="kkt-receipts-container">
    <h2 class="kkt-receipts-title" [textContent]="t('receipts-title-label')"></h2>
    <div *ngFor="let receipt of receipts; trackById" class="kkt-receipt-container body_text">
      <div class="kkt-receipt-date d-flex justify-content-between">
        <p>{{getReceiptTypeTitle(receipt)}},
          <kkm-date [date]="receipt.dateTime" format="medium"></kkm-date>
        </p>
        <div class="kkm-receipt-icons">
          <mat-icon *ngIf="isAgentReceipt(receipt)" svgIcon="common:agent-mark-currentColor"></mat-icon>
          <mat-icon *ngIf="receipt.hasErrors"
                    [svgIcon]="receipt.errorIcon"
                    class="receipt-error m_l-3"
                    [matTooltip]="t(receipt.errorTooltipCaption, {errorsCodes: receipt.errorCodes})"
                    matTooltipPosition="before"></mat-icon>
        </div>
      </div>
      <div *ngIf="isAgentReceipt(receipt)" class="kkt-receipt-info">
        <div class="kkt-receipt-info-item">{{ t('receipt-info.supplier-tin') }} <span>{{receipt.supplierTin}}</span></div>
      </div>
      <div class="kkt-receipt-info">
        <div class="kkt-receipt-info-item">{{ t('receipt-info.receipt-label') }} <span>#{{receipt.ticketNumber}}</span></div>
        <div class="kkt-receipt-info-item">{{ t('receipt-info.shift-label') }} <span>#{{receipt.crData?.shiftNumber}}</span></div>
      </div>
      <div class="kkt-receipt-footer">
        <div class="kkt-receipt-item">{{ t('receipt-info.fiscal-mark-label') }} <span>{{receipt.documentFiscalMark}}</span></div>
        <div class="total"><span><kkm-money [amount]="receipt.ticketTotalSum || 0"></kkm-money></span></div>
      </div>
    </div>
  </div>
</div>
