import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, first } from 'rxjs/operators';
import { ITokens, IUser } from './session.interfaces';
import { User } from './session.models';
import { oc } from 'ts-optchain';
import {ISessionService} from "./services.interfaces";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class SessionService implements ISessionService {
  private readonly TOKENS = 'TOKENS';

  constructor(private http: HttpClient,
              private router: Router) { }

  get tokens(): ITokens | undefined {
    const json: string = localStorage.getItem(this.TOKENS);
    return !!json ? JSON.parse(json) : undefined;
  }

  set tokens(tokens: ITokens | undefined) {
    if (tokens) {
      localStorage.setItem(this.TOKENS, JSON.stringify(tokens));
    } else {
      localStorage.removeItem(this.TOKENS);
    }
  }

  get isTokenExpired(): boolean {
    const tokens: ITokens = this.tokens;
    return (
      !tokens ||
      !tokens.accessToken ||
      !tokens.accessTokenTTL ||
      new Date() > new Date(tokens.accessTokenTTL)
    );
  }

  get isRefreshTokenExpired(): boolean {
    const tokens: ITokens = this.tokens;
    return (
      !tokens ||
      !tokens.refreshToken ||
      !tokens.refreshTokenTTL ||
      new Date() > new Date(tokens.refreshTokenTTL)
    );
  }

  open = (tokens: ITokens): void => {
    this.tokens = tokens;
  };

  close = (): void => {
    this.tokens = undefined;
    User.clearInstance();
    this.redirectToAuth();
  };

  getCurrentUser(token?: string): Observable<User> {
    return this.http.get<any>('/tax-web-control/me', { headers: { 'Access-Token': oc(this.tokens).accessToken('') } }).pipe(
      map((user: IUser) => User.getInstance(user)),
    );
  }

  doRefreshToken() {
    const tokens: ITokens = this.tokens;
    this.close();
    return this.http.post<any>(
      '/tax-web-control/refresh',
      null,
      { headers: { 'Refresh-Token': tokens.refreshToken } },
    ).pipe(
      tap((tokens: ITokens) => this.open(tokens)),
    );
  }

  public redirectToAuth() {
    this.router.navigate(['/sign-in'], {
      replaceUrl: true,
      queryParams: {_url: btoa(this.router.url)},
    });
  }
}
