import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'kkm-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.styl']
})

export class NotificationComponent implements OnInit {
  @Input() type: 'success' | 'warning' | 'danger' | 'info';
  @Input() iconSize: 'small' | 'medium' | 'large' | 'very-large' = 'small';
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  showCloseBtn: boolean;

  ngOnInit(){
    this.showCloseBtn = this.close.observers.length > 0;
  }
}
