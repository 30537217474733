import { Component, OnInit, Input } from '@angular/core';
import { ITaxpayer } from '@common/interfaces';

@Component({
  selector: 'kkm-stores-locator',
  templateUrl: './stores-locator.component.html',
  styleUrls: ['./stores-locator.component.styl']
})
export class StoresLocatorComponent implements OnInit {
  @Input() taxpayer: ITaxpayer;

  constructor() { }

  ngOnInit() {
  }

}
