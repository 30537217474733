import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, Subscription } from 'rxjs';
import {ICodeNameCheckbox} from '@common/interfaces';

@Component({
  selector: 'kkm-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.styl']
})
export class MultipleSelectComponent implements OnInit, OnDestroy {
  @Input() panelWidth: string | number = null;
  @Input() translationPath: string;
  @Input() clearSelectedItems: Observable<void>;
  @Input() set items(val: ICodeNameCheckbox[]) {
    if(val == null) {
      this.hasFilteredCheckboxItems = false;
      return;
    }

    this.checkboxItems = val;
    this.filteredCheckboxItems = of(this.checkboxItems);
    this.hasFilteredCheckboxItems = this.checkboxItems.length > 0;
    this.selectedItemsCount = val.filter((item: ICodeNameCheckbox) => item.checked).length;
  }

  @Output() selectedItems: EventEmitter<number[]> = new EventEmitter<number[]>();
  @ViewChild('itemsTrigger') itemsTrigger: MatAutocompleteTrigger;
  @ViewChild('searchEl') searchEl: ElementRef;

  public form: FormGroup;
  public filteredCheckboxItems: Observable<ICodeNameCheckbox[]>;
  public hasFilteredCheckboxItems: boolean;
  public selectedItemsCount: number = 0;

  private checkboxItems: ICodeNameCheckbox[];
  private itemsSubscription: Subscription = Subscription.EMPTY;
  private searchSubscription: Subscription = Subscription.EMPTY;
  private clearSelectedItemsSubscription: Subscription = Subscription.EMPTY;

  constructor() {
  }

  public ngOnInit(): void {
    this.form = new FormGroup({
      items: new FormArray([]),
      search: new FormControl()
    });

    this.itemsSubscription = this.itemsControl
      .valueChanges
      .subscribe(nextVal => {
        this.selectedItemsCount = nextVal?.length || 0;
        this.selectedItems.emit(nextVal);
      });

    this.searchSubscription = this.searchControl
      .valueChanges
      .subscribe((value: string) => {
        const tmpArray = this.filterCheckboxItems(value);
        this.hasFilteredCheckboxItems = tmpArray != null && tmpArray.length > 0;
        this.filteredCheckboxItems = of(tmpArray);
      });

    this.clearSelectedItemsSubscription = this.clearSelectedItems
      .subscribe(() => this.removeAll());
  }

  public ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
    this.itemsSubscription.unsubscribe();
    this.clearSelectedItemsSubscription.unsubscribe();
  }

  get itemsControl(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get searchControl(): FormControl {
    return this.form.get('search') as FormControl;
  }

  public clearSearchControl(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.searchControl.setValue(null);
  }

  public togglePanel(): void {
    this.itemsTrigger.openPanel();
  }

  public setFocus(): void {
    setTimeout(() => this.searchEl.nativeElement.focus(), 0);
  }

  public removeAll(): void {
    this.selectedItemsCount = 0;
    this.checkboxItems.forEach((item: ICodeNameCheckbox) => item.checked = false);
    this.itemsControl.clear();
  }

  public selectItem(item: ICodeNameCheckbox): void {
    const index = this.itemsControl.value.indexOf(item.code);
    const checkboxItem = this.checkboxItems.find((i: ICodeNameCheckbox) => i.code ===item.code);

    if (index > -1) {
      this.itemsControl.removeAt(index);
      checkboxItem.checked = false;
    } else {
      this.itemsControl.push(new FormControl(item.code));
      checkboxItem.checked = true;
    }
  }

  private filterCheckboxItems(filter: string): ICodeNameCheckbox[] {
    if (this.checkboxItems == null) {
      return [];
    }

    if (filter == null || filter.trim() === '') {
      return this.checkboxItems;
    }

    const filterValue = filter.toLowerCase();
    return this.checkboxItems.filter((item: ICodeNameCheckbox) => item.name.toLowerCase().includes(filterValue));
  }
}
