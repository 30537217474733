<ng-container *transloco="let t; read: 'common.components.sales-analytics.summary-info'">
  <div [scrollToTopOnByEvent]="scrollSubject"></div>
  <div class="d-flex align-items-center justify-content-between summary-info-container p_y-2 p_l-8 p_r-6">
    <div *ngIf="summaryTypesMap.length > 1" class="summary-select-wrap d-flex align-items-center" [matMenuTriggerFor]="summaryTypeMenu">
      <h2>{{t('summary-by')}}&nbsp;</h2>
      <div class="summary-select">
        <div class="d-flex align-items-center">
          <h2 class="brand-color" [textContent]="t(selectedSummaryType.text)"></h2>
          <span class="kkm-select-arrow brand-color"></span>
        </div>
        <div>
          <mat-menu #summaryTypeMenu backdropClass="kkm-mat-menu" xPosition="before">
            <button *ngFor="let typeModel of summaryTypesMap; trackByField:'type'"
                    (click)="setSummaryType(typeModel)"
                    class="body_text"
                    mat-menu-item>
              {{t(typeModel.text)}}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <h2 *ngIf="summaryTypesMap.length === 1">
      {{t('summary-by')}}&nbsp;{{t(summaryTypesMap[0].text)}}
    </h2>
    <div class="d-flex align-items-center justify-content-end"
         [class.receipts-filter-container]="isReceiptsGrid">
      <div class="d-flex align-items-center">
        <form [formGroup]="form" *ngIf="isReceiptsGrid" class="d-flex"
              (ngSubmit)="setFilter()">
          <mat-form-field appearance="fill" class="kkm-form-field compact filter-form-field m_r-6">
            <mat-icon matPrefix svgIcon="search:magnifyingGlass" [class.search-applied]="isFDFilterApplied"></mat-icon>
            <input matInput type="text" autocomplete="off" formControlName="fd" [placeholder]="t('fiscal-document')">
            <button *ngIf="form.get('fd').value" type="button" class="kkm-button kkm-icon-button" matSuffix
                    mat-icon-button (click)="clearFormField($event, 'fd')">
              <mat-icon svgIcon="close:narrowInput" class="small m_r-4"></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="fill" class="kkm-form-field compact filter-form-field">
            <mat-icon matPrefix svgIcon="search:magnifyingGlass" [class.search-applied]="isFMFilterApplied"></mat-icon>
            <input matInput type="text" autocomplete="off" formControlName="fm" [placeholder]="t('fiscal-mark')">
            <button *ngIf="form.get('fm').value" type="button" class="kkm-button kkm-icon-button" matSuffix
                    mat-icon-button (click)="clearFormField($event, 'fm')">
              <mat-icon svgIcon="close:narrowInput" class="small m_r-4"></mat-icon>
            </button>
          </mat-form-field>
          <button type="submit" [hidden]="true"></button>
        </form>

        <!-- Кнопка фильтра -->
        <button *ngIf="isReceiptsGrid"
                mat-stroked-button color="primary" type="button"
                class="kkm-button kkm-button__filters narrow fixed-height brand-color m_x-6"
                [class.kkm-button__filters_show]="showFilters"
                (click)="toggleFilter($event)">
          <mat-icon [svgIcon]="'funnel:' + color" class="m_r-2"></mat-icon>
          <span class="d-inline-flex align-items-center justify-content-center">
            {{ t('buttons.filters') }}
            <i *ngIf="appliedFiltersCount > 0" class="kkm-button__filters-count">{{ appliedFiltersCount }}</i>
          </span>
        </button>
      </div>

      <!-- Datepicker -->
      <mat-form-field *ngIf="selectedSummaryType.type === summaryTypes.BY_CASH_REGISTERS"
                      [class.range-invalid]="!rangeValid"
                      appearance="fill"
                      class="summary-info-container__datepicker kkm-form-field-datepicker-range kkm-mat-datepicker"
                      floatLabel="never"
                      (click)="picker.open()">
        <mat-date-range-input [max]="maxDate" class="d-none"
                              [min]="minDate"
                              [rangePicker]="picker"
                              [formGroup]="dateRange"
                              [separator]="end.value ? '–' : ''">
          <input #start readonly [placeholder]="t('date-placeholder')" formControlName="start" matStartDate>
          <input #end readonly formControlName="end" matEndDate>
        </mat-date-range-input>
        <span class="text-nowrap">
          <span *ngIf="!startControl.value && !endControl.value" class="field-placeholder">{{t('date-placeholder')}}</span>
          <kkm-date [date]="startControl.value" format="mediumDate"></kkm-date>
          <span *ngIf="stringDate(startControl.value) !== stringDate(endControl.value)">
            <span *ngIf="startControl.value && endControl.value"> - </span>
            <kkm-date [date]="endControl.value" format="mediumDate"></kkm-date>
          </span>
        </span>
        <mat-datepicker-toggle *ngIf="!start.value && !end.value" [for]="picker" class="kkm-datepicker-toggle" matSuffix>
          <mat-icon matDatepickerToggleIcon svgIcon="datepicker:calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-icon *ngIf="start.value || end.value" (click)="clearDates($event)" class="small mat-button p_x-0 m_l-2"
                  matDatepickerToggleIcon matSuffix svgIcon="close:narrowInput"></mat-icon>

        <mat-date-range-picker #picker (closed)="checkDates()"></mat-date-range-picker>
      </mat-form-field>

      <!-- Paginator -->
      <div class="d-flex align-items-center paginator m_l-6">
        <div class="goto-icon left" [class.disabled]="!paginatorState.hasPrev || loading" (click)="setCurrentPage(currentPage - 1)"></div>
        <div class="body_text m_x-1">{{paginatorState.label}}</div>
        <div class="goto-icon right" [class.disabled]="!paginatorState.hasNext || loading" (click)="setCurrentPage(currentPage + 1)"></div>
      </div>
    </div>
  </div>

  <kkm-receipt-details [cashRegisters]="cashRegisters"
                       [id]="selectedReceiptId"
                       [taxpayer]="taxpayer"
                       [isShowFdNumber]="isShowFdNumber"
                       (onClose)="closeReceipt($event)"></kkm-receipt-details>

  <div class="sidepage__overlay" [class.is-opened]="!!selectedReceiptId" (click)="closeReceipt($event)"></div>

  <ng-container *ngIf="!showFailedToLoadData">

    <kkm-receipts-summary *ngIf="selectedSummaryType.type === summaryTypes.BY_RECEIPTS"
                          [analyticsSummaryType]="summaryTypes.BY_RECEIPTS"
                          [isFilterActive]="showFilters"
                          [mainFilter]="mainFilter"
                          [searchFilter]="searchFilter"
                          [currentPage]="currentPage"
                          (afterLoad)="onAfterLoad($event)"
                          (onRowClick)="openReceipt($event)"
                          (clearCurrent)="setCurrentPage(1)"
                          [cashRegisters]="cashRegisters"
                          [taxpayerId]="taxpayerId"
                          [taxpayer]="taxpayer"
                          [selectedReceiptId]="selectedReceiptId"
                          [isShowFdNumber]="isShowFdNumber"
                          (appliedFiltersCount)="setAppliedFiltersCount($event)"></kkm-receipts-summary>

    <kkm-receipts-summary *ngIf="selectedSummaryType.type === summaryTypes.BY_AGENT_RECEIPTS"
                          [analyticsSummaryType]="summaryTypes.BY_AGENT_RECEIPTS"
                          [isFilterActive]="showFilters"
                          [mainFilter]="mainFilter"
                          [searchFilter]="searchFilter"
                          [currentPage]="currentPage"
                          (afterLoad)="onAfterLoad($event)"
                          (onRowClick)="openReceipt($event)"
                          (clearCurrent)="setCurrentPage(1)"
                          [cashRegisters]="agentCashRegisters"
                          [taxpayerId]="taxpayerId"
                          [taxpayer]="taxpayer"
                          [selectedReceiptId]="selectedReceiptId"
                          [isAgentsReceipts]="true"
                          [isShowFdNumber]="isShowFdNumber"
                          (appliedFiltersCount)="setAppliedFiltersCount($event)"></kkm-receipts-summary>

    <kkm-receipts-summary *ngIf="selectedSummaryType.type === summaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS"
                          [analyticsSummaryType]="summaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS"
                          [isFilterActive]="showFilters"
                          [mainFilter]="mainFilter"
                          [searchFilter]="searchFilter"
                          [currentPage]="currentPage"
                          (afterLoad)="onAfterLoad($event)"
                          (onRowClick)="openReceipt($event)"
                          (clearCurrent)="setCurrentPage(1)"
                          [cashRegisters]="cashRegisters"
                          [taxpayerId]="taxpayerId"
                          [taxpayer]="taxpayer"
                          [selectedReceiptId]="selectedReceiptId"
                          [isShowFdNumber]="isShowFdNumber"
                          (appliedFiltersCount)="setAppliedFiltersCount($event)"></kkm-receipts-summary>

    <kkm-cr-summary *ngIf="selectedSummaryType.type === summaryTypes.BY_CASH_REGISTERS"
                    [mainFilter]="mainFilter"
                    [taxpayerId]="taxpayerId"
                    [taxpayer]="taxpayer"
                    (afterLoad)="onAfterLoad($event)"
                    (onRowClick)="openReceipt($event)"
                    [selectedReceiptId]="selectedReceiptId"
                    [currentPage]="currentPage"
                    [searchFilter]="calendarFilter"
                    [selectedCashRegisters]="cashRegisters"
                    [isShowFdNumber]="isShowFdNumber"></kkm-cr-summary>

  </ng-container>
  <ng-container *ngIf="showFailedToLoadData">
    <p *transloco="let p; read: 'common.notifications'" class="white-area box_shadow body_text muted_text p_y-4 p_x-6 m_t-6">
      <span [textContent]="p('failed-to-load-data.text')"></span>
    </p>
  </ng-container>
</ng-container>
