import { Component, OnInit } from '@angular/core';
import { SettingsService } from './services/settings/settings.service';
import { TaxSystemsService } from '../../../../libs/ui/src/lib/services/dictionaries/tax-systems.service';
import { BusinessObjectsDictionaryService } from '../../../../libs/ui/src/lib/services/dictionaries/business-objects-dictionary.service';
import urlPrefix from "./api/apiPrefix";

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  constructor(
    private taxSystemsService: TaxSystemsService,
    private businessObjectsDictionaryService: BusinessObjectsDictionaryService,
    private settings: SettingsService,
  ) {
    this.taxSystemsService.refreshTaxSystems();
    this.settings.setTitle();
  }

  public async ngOnInit(): Promise<void> {
    await this.businessObjectsDictionaryService.loadDictionaries(urlPrefix.value);
  }
}
