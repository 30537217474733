import urlPrefix from "./apiPrefix";

export const putManageManufacturer = () => `${urlPrefix.value}/tax-web-control/manage/manufacturer`;
export const postManageManufacturer = () => `${urlPrefix.value}/tax-web-control/manage/manufacturer`;
export const putManageExpert = () => `${urlPrefix.value}/tax-web-control/manage/expert`;
export const postManageExpert = () => `${urlPrefix.value}/tax-web-control/manage/expert`;
export const putManageCash = () => `${urlPrefix.value}/tax-web-control/manage/cash`;
export const postManageCash = () => `${urlPrefix.value}/tax-web-control/manage/cash`;
export const putManageCashRegister = (tin: string, regNumber: string, fm: string, status: string) => `${urlPrefix.value}/tax-web-control/manage/cash-register/setstatus/${status}/where/regnum/${regNumber}/tin/${tin}/fm/${fm}`;
export const getManageManufacturerFind = () => `${urlPrefix.value}/tax-web-control/manage/manufacturer/find`;
export const getManageExpertFind = () => `${urlPrefix.value}/tax-web-control/manage/expert/find`;
export const deleteManageCash = (id: number) => `${urlPrefix.value}/tax-web-control/manage/cash/${id}`;
