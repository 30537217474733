import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ITaxSystem} from "@common/interfaces";

@Injectable({
  providedIn: 'root',
})
export class TaxSystemsService {

  private _taxSystems: ITaxSystem[] = [];

  constructor(
    @Inject('env') private env,
    private http: HttpClient
  ) { }

  get taxSystems(): ITaxSystem[] {
    return this._taxSystems;
  }

  formattedTaxSystem(userTaxSystems: number[]): string {
    let taxNames: string = '';
    for (let i = 0; i < userTaxSystems.length; i++) {
      const taxSystem = this.taxSystems.find(t => t.code === userTaxSystems[i]);
      taxNames += (taxSystem ? taxSystem.taxName : '') + ((i === (userTaxSystems.length - 1)) ? '' : ', ');
    }

    return taxNames;
  }

  async refreshTaxSystems() {
    try {
      const taxSystems = await this.http.get<ITaxSystem[]>(this.endpoint).toPromise();
      this._taxSystems = taxSystems.sort((a: ITaxSystem, b: ITaxSystem) => a.code - b.code);
    } catch (err) {
      console.error(err);
    }
  }

  get endpoint() {
    return this.env?.dictionaries?.taxSystems;
  }

}

