import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LANG_LIST } from '@common/constants';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = LANG_LIST.find(l => l.value === SettingsService.language);
    return next.handle(
      req.clone({setHeaders: {'Accept-Language': lang ? lang.langCode : ''}})
    );
  }
}
