import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[datepickirMask]'
})
export class DatepickirMaskDirective implements OnDestroy {

  mask = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  maskedInputController;

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.el.nativeElement,
      mask: this.mask
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event): void {

    const control = this.control.control;
    const val = this.el.nativeElement.value;
    // let charCode = (event.query) ? event.query : event.keyCode;

    // const allowed = charCode === 8
    //               || charCode === 9
    //               || charCode === 190
    //               || (charCode >= 48 && charCode <= 57);

    // if (!allowed) {
    //   return;
    // }

    if (control.value && this.getExpectedClaerValue(val, event.keyCode)) {
      control.markAsUntouched();
    }
  
  }

  @HostListener('keyup', ['$event'])
  onKeyup(event): void {

    const control = this.control.control;
    const val = this.el.nativeElement.value;

    control.markAsUntouched();

    if (!control.value && !this.getClaerValue(val)) {
      control.markAsPristine();
      control.markAsUntouched();
    }
  
  }

  getExpectedClaerValue(val: string, code: number) {
    val = val.split('_').join('');
    val = val.split('.').join('');
    if (code === 8 && val.length > 0) {
      val = val.slice(0, -1);
    }
    if (code > 47 && code < 58) {
      val += '0';
    }
    return val;
  }

  getClaerValue(val: string) {
    val = val.split('_').join('');
    val = val.split('.').join('');
    return val;
  }

}
