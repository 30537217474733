import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ICompanyISNAKData } from '@common/interfaces';
import { catchError, debounceTime, first } from 'rxjs/operators';
import { LegalPersonType } from '@common/enums';

interface ICompanyEndpoints {
  preregisterEndpoint: (tin: string) => string;
}

@Injectable()
export class CompanyService {
  private readonly endpoints: ICompanyEndpoints;

  constructor(@Inject('env') private env,
              private http: HttpClient) {
    this.endpoints = env?.shared?.services?.company as ICompanyEndpoints;
  }

  public fetchCompanyByTIN(tin: string): Observable<ICompanyISNAKData> {
    const url = this.endpoints.preregisterEndpoint(tin);
    return this.http.get<ICompanyISNAKData>(url)
      .pipe(
        first(),
        catchError(error => of(null))
      );
  }
}
