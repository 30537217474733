<kkm-sidebar *ngIf="!withoutSidebar else receiptView"
             [isOpened]="isOpened"
             [title]="title"
             [isLoading]="isLoading"
             (onClose)="close($event)">

  <ng-container title>
    <div class="d-flex align-items-center">
      <h1 class="sidebar-title medium" [textContent]="title"></h1>
      <mat-icon *ngIf="receipt && isAgentReceiptAsPrincipal(receipt)" svgIcon="common:agent-mark-p" class="very-large m_l-3"></mat-icon>
      <mat-icon *ngIf="receipt && isAgentReceiptAsAgent(receipt)" svgIcon="common:agent-mark-currentColor" class="very-large m_l-3"></mat-icon>
    </div>
  </ng-container>
  <ng-container *ngTemplateOutlet="receiptView"></ng-container>
</kkm-sidebar>

<ng-template #receiptView>
  <ng-container *transloco="let t; read: TRANSLATION_READ_PATH">
    <div *ngIf="receipt && !notFound">
      <div class="m_b-8">
        <div class="d-flex flex-column content-container m_b-6">
          <div class="body-container d-flex flex-column m_l-12" [ngClass]="withoutSidebar ? 'm_r-12' : 'm_r-6'" [class.bordered-bottom]="!withoutSidebar">
            <ng-container *ngIf="withoutSidebar">
              <div *ngIf="withoutSidebar" class="body_text text-center m_y-5" [textContent]="title"></div>
              <h2 *ngIf="withoutSidebar" class="text-center m_b-2" [textContent]="receipt.legalPersonName"></h2>
            </ng-container>
            <div class="body_text m_b-1" [class.text-center]="withoutSidebar">
              {{ receipt.legalPersonName || taxpayer.name }}, {{t('tin')}} {{receipt.tin || taxpayer?.tin }}
            </div>
            <div class="body_text m_b-2" [class.text-center]="withoutSidebar">
              {{receipt?.crData?.locationName}}, {{receipt?.crData?.locationAddress}}
            </div>

            <div *ngIf="isAgentReceipt(receipt)" class="body_text m_t-1 m_b-2" [class.text-center]="withoutSidebar">
              {{ t('supplier.label') }}: {{ joinStrings([receipt.legalPersonSupplierName, t('supplier.tin') + " " + receipt.supplierTin]) }}
            </div>

            <!-- Ошибки -->
            <div *ngIf="criticalErrors.length" class="receipt-errors receipt-errors__container receipt-errors__container_critical d-flex flex-column">
              <h3 class="receipt-errors__header">
                <span class="receipt-errors__counter receipt-errors__counter_critical m_r-3">{{criticalErrors.length}}</span>
                {{ t('errors.critical-error') }}
              </h3>
              <ul class="receipt-errors__list">
                <li *ngFor="let error of criticalErrors" class="small_text">
                  <span class="semi_bold">{{ t('errors.code', {errorCode: error.code}) }}</span>
                  <span>{{ error.description }}</span>
                </li>
              </ul>
            </div>

            <div *ngIf="acceptableErrors.length" class="receipt-errors receipt-errors__container receipt-errors__container_non-critical d-flex flex-column">
              <h3 class="receipt-errors__header">
                <span class="receipt-errors__counter receipt-errors__counter_non-critical m_r-3">{{acceptableErrors.length}}</span>
                {{ t('errors.non-critical-error') }}
              </h3>
              <ul class="receipt-errors__list">
                <li *ngFor="let error of acceptableErrors" class="small_text">
                  <span class="semi_bold">{{ t('errors.code', {errorCode: error.code}) }}</span>
                  <span>{{ error.description }}</span>
                </li>
              </ul>
            </div>

            <div class="body_text m_t-4 m_b-1">
              <span *ngIf="withoutSidebar" class="m_r-4" [textContent]="title"></span>
              <span><kkm-date [date]="receipt.dateTime" format="medium"></kkm-date></span>
            </div>
            <div class="d-flex flex-row m_b-4">
              <div class="body_text m_r-8"><span class="muted_text">{{t('receipt')}} </span>№{{receipt.ticketNumber}}</div>
              <div class="body_text m_r-8"><span class="muted_text">{{t('shift')}} </span>№{{receipt?.crData?.shiftNumber}}</div>
              <div class="body_text m_r-8"><span class="muted_text">{{t('cashier')}}: </span>{{receipt?.crData?.cashierName}}</div>
            </div>
            <div class="row negative-x-margin border-box m_b-4">
              <kkm-info-item class="col-3" [title]="t('cashRegisterId')">{{receipt.crRegisterNumber || '-'}}</kkm-info-item>
              <kkm-info-item class="col-3" [title]="t('fiscalMemoryDevice')">{{receipt.fnSerialNumber || '-'}}</kkm-info-item>
              <!-- номер ФД скрыть везде, кроме ЛК НП -->
              <kkm-info-item class="col-3" [title]="t('fiscalDocument')">{{isShowFdNumber ? receipt.fdNumber : '–'}}</kkm-info-item>
              <kkm-info-item class="col-3" [title]="t('fiscalMark')">{{receipt.documentFiscalMark || '-'}}</kkm-info-item>
            </div>

            <div class="m_b-4"></div>

            <div *ngIf="isCorrection">
              <h3 class="m_b-2">{{ receipt.correction.type === correctionReceiptTypes.MANUAL ? t('manualCorrection') : t('onRequestCorrection') }}</h3>
              <div class="d-flex flex-row m_b-6">
                <kkm-info-item class="m_r-8" [title]="t('incidentDate')"><kkm-date [date]="receipt.correction.date" format="mediumDate"></kkm-date></kkm-info-item>
                <kkm-info-item class="m_r-8" [title]="t('payment_type')">{{paymentType}}</kkm-info-item>
                <kkm-info-item class="m_r-8"
                                [title]="t(receipt?.correction?.type === correctionReceiptTypes.ON_REQUEST ? 'reason' : 'reasonManual')">
                  {{receipt.correction.reason || '-'}}
                </kkm-info-item>

                <!-- чек-основание -->
                <kkm-info-item *ngIf="receipt.originFdNumber" class="m_r-8" [title]="t('fiscalDocument')">{{receipt.originFdNumber}}</kkm-info-item>
                <kkm-info-item *ngIf="receipt.originFnSerialNumber" class="m_r-8" [title]="t('fiscalMemoryDevice')">{{receipt.originFnSerialNumber}}</kkm-info-item>
              </div>
            </div>
            <div *ngIf="isReturn && receipt.originFdNumber && receipt.originFnSerialNumber">
              <h3 class="m_b-2">{{ t('origin-receipt') }}</h3>
              <div class="d-flex flex-row m_b-6">
                <!-- чек-основание -->
                <kkm-info-item class="m_r-8" [title]="t('fiscalDocument')">{{receipt.originFdNumber}}</kkm-info-item>
                <kkm-info-item class="m_r-8" [title]="t('fiscalMemoryDevice')">{{receipt.originFnSerialNumber}}</kkm-info-item>
              </div>
            </div>
            <div class="d-flex flex-column items-table-container">
              <div class="d-flex flex-row items-table-header">
                <div class="col col-id small_text semi_bold">№</div>
                <div class="col col-item small_text semi_bold">{{t('item')}}</div>
                <div class="col col-price small_text semi_bold">{{t('price')}}</div>
                <div class="col col-qty small_text semi_bold">{{t('qty')}}</div>
                <div class="col col-total small_text semi_bold">{{t('total')}}</div>
              </div>
              <div class="d-flex flex-column items-table-body" [class.bordered-bottom]="withoutSidebar">
                <div class="d-flex flex-row item-container" *ngFor="let item of receipt.items; trackById let i = index">
                  <div class="col col-id p_l-6 small_text">{{i + 1}}</div>
                  <div class="col col-item p_l-6 d-flex flex-column">
                    <div class="small_text text-break">{{item.goodName}}</div>
                    <div class="small_text">{{t('vat')}} {{getTaxRate(item.goodVatCode, 'VAT')}}%, {{t('st')}} {{getTaxRate(item.goodStCode, 'ST')}}%</div>
                    <div class="small_text" *ngIf="!!(item.sgtin || checkGoodNomenclatureCode(item.goodNomenclatureCode))">
                      <div>[{{item.sgtin || checkGoodNomenclatureCode(item.goodNomenclatureCode)}}]</div>
                    </div>
                  </div>
                  <div class="col col-price p_l-6 small_text">
                    <kkm-money *ngIf="isCurrencyReceiptItem(item)" [amount]="item.goodPrice || 0" [round]="false" notRoundedDigitsInfo="1.4-4"></kkm-money>
                    <kkm-money *ngIf="!isCurrencyReceiptItem(item)" [amount]="item.goodPrice || 0"></kkm-money>
                  </div>
                  <div class="col col-qty p_l-6 small_text">{{item.goodQuantity}}</div>
                  <div class="col col-total p_r-6 small_text"><kkm-money [amount]="item.goodCost || 0"></kkm-money></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row m_l-12 justify-content-end" [ngClass]="withoutSidebar ? 'm_r-12' : 'm_r-6'">
          <div class="d-flex flex-column total-container" [ngClass]="{'big': withoutSidebar, 'small': !withoutSidebar}">

            <!-- Признак способа расчета -->
            <div *ngIf="paymentTypeMarkKey" class="d-flex justify-content-between m_b-2">
              <div class="body_text" [textContent]="t(paymentTypeMarkKey)"></div>
            </div>

            <!-- СНО -->
            <div class="d-flex m_b-2">
              <div class="body_text">{{ t('tax_system') }}: {{ taxSystem }}</div>
            </div>

            <!-- Подытог -->
            <div class="d-flex justify-content-between m_b-2">
              <div class="body_text">{{t('subtotal')}}</div>
              <div class="body_text"><kkm-money [amount]="receipt.subtotal || 0"></kkm-money></div>
            </div>
            <ng-container *ngFor="let tax of taxCounters; trackByField:'code'">
              <div class="d-flex justify-content-between m_b-2">
                <div class="body_text">{{ tax.type === taxTypes.VAT ? t('vat') : t('st') }} {{ getTaxRate(tax.code, tax.type === taxTypes.VAT ? 'VAT' : 'ST')}}%</div>
                <div class="body_text"><kkm-money [amount]="tax.sum || 0"></kkm-money></div>
              </div>
            </ng-container>

            <!-- Итого -->
            <div class="d-flex justify-content-between m_b-2">
              <div class="body_text text-uppercase bold large_text">{{t('total')}}</div>
              <div class="body_text bold large_text"><kkm-money [amount]="receipt.ticketTotalSum || 0"></kkm-money></div>
            </div>

            <!-- Аванс -->
            <div *ngIf="receipt.ticketPrePaymentSum" class="d-flex justify-content-between m_b-2">
              <div class="body_text">{{t('advance')}}</div>
              <div class="body_text"><kkm-money [amount]="receipt.ticketPrePaymentSum || 0"></kkm-money></div>
            </div>

            <!-- Кредит -->
            <div *ngIf="receipt.ticketPostPaymentSum" class="d-flex justify-content-between m_b-2">
              <div class="body_text">{{t('credit')}}</div>
              <div class="body_text"><kkm-money [amount]="receipt.ticketPostPaymentSum || 0"></kkm-money></div>
            </div>

            <!-- Received -->
            <div *ngIf="(receipt.ticketPrePaymentSum || receipt.ticketPostPaymentSum) && received > 0" class="d-flex justify-content-between m_b-2">
              <div class="body_text">{{t('received')}}</div>
              <div class="body_text"><kkm-money [amount]="received"></kkm-money></div>
            </div>

            <!-- Тип платежа -->
            <div class="d-flex justify-content-between m_t-2 m_b-2">
              <div class="body_text">{{ t('payment_type') }}</div>
              <div class="body_text text-right">{{ paymentType }}</div>
            </div>

          </div>
        </div>
      </div>
      <div *ngIf="withoutSidebar" class="p_x-6 m_b-4">

        <div class="d-flex justify-content-center">
          <qrcode [qrdata]="qrUrl" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>

        <div class="p_y-4 text-center bordered-top">
          <a class="no-text-decoration body_text" [textContent]="taxServiceWebsite.name" [href]="taxServiceWebsite.link"></a>
        </div>
      </div>
    </div>
    <div *ngIf="notFound" class="p_y-20 p_x-20 text-center body_text muted_text" [textContent]="t('notFound')"></div>
  </ng-container>
</ng-template>
