<div class="file_upload-inner">
  <button mat-button
          type="button"
          class="kkm-button file_upload-button"
          color="primary"
          [textContent]="label">
  </button>
  <input class="file_upload-file_input"
         type="file"
         accept=".xml"
         (change)="handleFileInput($event.target)">
</div>
