import {Component, Input} from '@angular/core';

type Position = "top" | "right" | "bottom" | "left";

@Component({
  selector: 'kkm-input-popover',
  templateUrl: './input-popover.component.html',
  styleUrls: ['./input-popover.component.styl']
})
export class InputPopoverComponent {
  @Input() class: string;
  @Input() text: string;
  @Input() position: Position | null;

  constructor() {
  }
}
