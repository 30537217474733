import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kkm-entity-stats',
  templateUrl: './entity-stats.component.html',
  styleUrls: ['./entity-stats.component.styl']
})
export class EntityStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
