// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useSSO: true,
  ssoEndpoints: {
    base: '/',
    login: '/identity/oauth2/authorization/keycloak',
    logout: '/identity/authentication/logout',
    user: '/identity/authentication/user',
    token: '/identity/authentication/user/token',
    tokenAuth: '/identity/authentication/user/introspect-token',
    urlPrefix: '/identity/client',
  },
  defaults: {
    language: 'en-US',
    pollingDelay: 120 * 1000,
    project: 'AIS_NO',
    mainColor: 'blue',
  },
  shared: {
    services: {
      company: {
        preregisterEndpoint: (tin) => `/tax-web-control/client/api/taxpayers/preregister-info/${tin}`,
      },
      cashRegister: {
        cashRegisterListEndpoint: (id?) => `/tax-web-control/api/lk/legal-person/${id}/locations`,
        cashRegisterStatsEndpoint: (from, to) => `/tax-web-control/api/lk/cash-register/stats/from/${from}/to/${to}`,
        agentCashRegistersEndpoint: (id: string) => `/tax-web-control/api/lk/legal-person/${id}/agent-cash-registers`,
      },
      shift: {
        commonEndpoint: '/tax-web-control/api/lk',
      },
      receipt: {
        commonEndpoint: '/tax-web-control/api/lk',
        taxRatesApi: '/tax-web-control/directory/tax-rates'
      },
      stat: {
        commonEndpoint: '/tax-web-control/stats/ticket/summary/uid',
      }
    }
  },
  dictionaries: {
    taxSystems: '/tax-web-control/directory/tax-systems',
    businessActivities: '/tax-web-control/directory/business-activities',
    entrepreneurshipObjects: '/tax-web-control/directory/entrepreneurship-objects',
    taxAuthorityDepartments: '/tax-web-control/directory/tax-authority-departments',
    legalPersonActivities: '/tax-web-control/directory/legal-person-activities',
    formatAndLogicControlErrors: '/tax-web-control/directory/fd-error-info',
  },
  tinPattern: '\\d{14}',
  isUnlimitedFm: true,
  hideCorrections: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
