import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CashRegisterService } from '../../../services/cash-register.service';
import { ShiftService } from '../../../services/shift.service';
import { sklonenie } from '@common/utils';
import {ICashRegister, IReceipt, IShift, IStore, ITaxpayer} from '@common/interfaces';
import { flatten, isEqual, map, isEmpty } from 'lodash';
import { MatAccordion } from '@angular/material/expansion';
import { EventBusService, EventData } from '../../../services/event-bus/event-bus.service';
import { SyntheticReceiptTypes } from '@common/enums';
import {Subscription} from "rxjs";

interface IShiftsMap {
  [key: string]: any[];
}

interface IPaginatorState {
  label: string;
  hasNext: boolean;
  hasPrev: boolean;
}

const defaultPaginatorState = {
  label: '-',
  hasPrev: false,
  hasNext: false,
};

@Component({
  selector: 'kkm-cr-summary',
  templateUrl: './cr-summary.component.html',
  styleUrls: ['./cr-summary.component.styl']
})
export class CrSummaryComponent implements OnInit, OnChanges, OnDestroy {
  TRANSLATION_READ_PATH = 'common.components.sales-analytics.cr-summary';
  private CR_LIMIT_PER_PAGE: number = 50;
  private openedPanelsCount = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('summaryContainer') summaryContainer: ElementRef<HTMLDivElement>;
  @ViewChildren('summaryElements') summaryElements: QueryList<HTMLDivElement>;

  @Input() mainFilter;
  @Input() set searchFilter(filter: any) {
    this.calendarFilter = filter;
    this.accordion?.closeAll();
    this.clearSummaryContainerHeight();
  };
  @Input() currentPage: number;
  @Input() taxpayerId: string | number;
  @Input() selectedReceiptId: string;
  @Input() selectedCashRegisters = [];
  @Input() taxpayer: ITaxpayer;
  @Input() isShowFdNumber?: boolean;
  @Output() onRowClick = new EventEmitter<IReceipt>();
  @Output() afterLoad = new EventEmitter<any>();
  cashRegisters: ICashRegister[] = [];
  paginatorState: IPaginatorState = defaultPaginatorState;
  filtersOpenedState: Map<string, boolean> = new Map<string, boolean>();
  filtersAppliedState: Map<string, boolean> = new Map<string, boolean>();
  filtersAppliedCount: Map<string, number> = new Map<string, number>();
  isCashRegistersLoading: boolean = false;
  cashRegisterLoadingRegNumber: string = '';
  shiftsMap: IShiftsMap = {};
  color: string;
  calendarFilter: any;
  offsetScrollToSummary: number;

  sklonenie = sklonenie;

  private getShiftsSubscription: Subscription = Subscription.EMPTY;

  constructor(
    @Inject('env') private env,
    private crService: CashRegisterService,
    private shiftService: ShiftService,
    private eventBus: EventBusService,
  ) {
    this.color = env?.defaults?.mainColor;
  }

  ngOnInit() {
    this.loadCashRegisters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {currentPage, mainFilter, searchFilter} = changes;
    if ((currentPage && !currentPage.firstChange) || (mainFilter && !mainFilter.firstChange)) {
      this.setPaginator();
      if (this.accordion) {
        this.accordion.closeAll();
        this.clearSummaryContainerHeight();
      }

      this.updateCashRegisters();
    }

    if (searchFilter && !searchFilter.firstChange && !isEqual(searchFilter.currentValue, searchFilter.previousValue)) {
      this.updateCashRegisters();
    }
  }

  ngOnDestroy(): void {
    this.getShiftsSubscription.unsubscribe();
  }

  get selectedCashRegisterIds(): string[] {
    return this.mainFilter.kktIds || [];
  }

  get filteredCashRegisters(): ICashRegister[] {
    return this.cashRegisters.filter(cr => !this.selectedCashRegisterIds.length || this.selectedCashRegisterIds.includes(cr.regNumber))
  }

  get pagedCashRegisters(): ICashRegister[] {
    const begin = (this.currentPage - 1) * this.CR_LIMIT_PER_PAGE;
    const end = begin + this.CR_LIMIT_PER_PAGE;
    return this.filteredCashRegisters.slice(begin, end);
  }

  async loadStats(): Promise<any> {
    let stats = {};
    try {
      stats = await this.crService.getCashRegistersStat({
        ...this.datesForFilter,
        lpId: this.taxpayerId,
        regNumbers: isEmpty(this.selectedCashRegisterIds)
          ? this.selectedCashRegisters.map(cr => cr.id)
          : this.selectedCashRegisterIds,
      }).toPromise();
    } catch (err) {
      console.error(err);
    }
    return stats;
  }

  async loadCashRegisters(): Promise<void> {
    try {
      this.isCashRegistersLoading = true;
      const stores = await this.crService.getCashRegisters(this.taxpayerId, true).toPromise();
      const stats = await this.loadStats();
      this.cashRegisters = flatten(map(stores, (store: IStore) => this.transformCashRegisters(store.cashRegisters, stats)));
      this.setPaginator();
    } catch (err) {
      console.error(err);
    } finally {
      this.isCashRegistersLoading = false;
    }
  }

  async updateCashRegisters(): Promise<void> {
    const stats = await this.loadStats();
    const cashRegisters = this.transformCashRegisters(this.cashRegisters, stats);
    this.cashRegisters = cashRegisters;
    this.filtersAppliedState.clear();
    this.filtersOpenedState.clear();
  }

  transformCashRegisters(cashRegisters = [], stats = {}) {
    return cashRegisters.map(cr => ({
      ...cr,
      totalStat: stats?.[cr.regNumber]?.totalStat || {},
    }));
  }

  get datesForFilter() {
    const {from, to} = this.mainFilter;
    const {from: f, to: t} = this.calendarFilter;
    return {
      from: f || from,
      to: t || to,
    }
  }

  async loadShifts(cashRegister: ICashRegister, elementIndex: number): Promise<void> {
    this.openedPanelsCount = this.openedPanelsCount + 1;
    const {regNumber} = cashRegister;

    this.setSummaryContainerHeight(elementIndex);
    this.calculateOffsetScrollToSummary(elementIndex);
    this.cashRegisterLoadingRegNumber = regNumber;
    this.getShiftsSubscription.unsubscribe();
    this.getShiftsSubscription = this.shiftService.getShifts({
      cashRegisterNumber: regNumber,
      onlyTicketFds: true,
      ...this.datesForFilter,
    }).subscribe(
      (shifts: IShift[]) => this.shiftsMap[regNumber] = shifts,
      (error: any) => console.error(error),
      () => this.cashRegisterLoadingRegNumber = '',
      );
  }

  clearShifts(cashRegister: ICashRegister): void {
    delete this.shiftsMap[cashRegister.regNumber];
    this.openedPanelsCount = this.openedPanelsCount - 1;

    if (this.openedPanelsCount === 0) {
      this.clearSummaryContainerHeight();
    }
  }

  openReceipt(receipt: IReceipt): void {
    this.onRowClick.emit(receipt);
  }

  setPaginator() {
    const itemsLength = this.filteredCashRegisters.length;
    const totalItemsPerPage = Math.min(this.pagedCashRegisters.length, this.CR_LIMIT_PER_PAGE) || 1;
    const totalPages = Math.ceil(itemsLength / this.CR_LIMIT_PER_PAGE) || 1;
    const hasNext = this.currentPage < totalPages;
    const startOffset = this.currentPage === 1 ? Math.min(1, itemsLength) : (this.currentPage - 1) * this.CR_LIMIT_PER_PAGE + 1;
    const endOffset = this.currentPage === 1 ? totalItemsPerPage : startOffset + totalItemsPerPage - 1;
    const label = `${startOffset}-${endOffset}`;
    const paginatorState = {
      hasNext: hasNext,
      hasPrev: this.currentPage > 1,
      label,
    };
    this.afterLoad.emit({
      paginatorState,
    });
  }

  toggleFilter(ev: MouseEvent, cashRegister: ICashRegister, isPanelExpanded: boolean): void {
    ev.stopPropagation();
    if (isPanelExpanded) {
      this.filtersOpenedState.set(cashRegister.regNumber, !this.filtersOpenedState.get(cashRegister.regNumber));
    }
  }

  setFiltersAppliedState(state: any) {
    this.filtersAppliedState.set(state.key, state.value);
    this.filtersAppliedCount.set(state.key, state.count);
  }

  public emitScrollToSummary(): void {
    this.eventBus.emit(new EventData("summary-info/scroll-to-summary", this.offsetScrollToSummary));
  }

  public calculateTotalAdvance(cashRegister: ICashRegister): number {
    if (cashRegister == null || cashRegister.totalStat == null || cashRegister.totalStat.ticketCounters == null) {
      return 0;
    }

    return cashRegister.totalStat.ticketCounters[SyntheticReceiptTypes.INCOME]?.totalPrePayment || 0;
  }

  public calculateTotalCredit(cashRegister: ICashRegister): number {
    if (cashRegister == null || cashRegister.totalStat == null || cashRegister.totalStat.ticketCounters == null) {
      return 0;
    }

    return cashRegister.totalStat.ticketCounters[SyntheticReceiptTypes.INCOME]?.totalPostPayment || 0;
  }

  public calculateTotalRevenue(cashRegister: ICashRegister): number {
    if (cashRegister == null || cashRegister.totalStat == null || cashRegister.totalStat.ticketCounters == null) {
      return 0;
    }

    const counters = cashRegister.totalStat.ticketCounters;
    const income = (counters[SyntheticReceiptTypes.INCOME]?.totalCash || 0) + (counters[SyntheticReceiptTypes.INCOME]?.totalCashless || 0);
    const incomeReturn = (counters[SyntheticReceiptTypes.INCOME_RETURN]?.totalCash || 0) + (counters[SyntheticReceiptTypes.INCOME_RETURN]?.totalCashless || 0);
    const expenditure = (counters[SyntheticReceiptTypes.EXPENDITURE]?.totalCash || 0) + (counters[SyntheticReceiptTypes.EXPENDITURE]?.totalCashless || 0);
    const expenditureReturn = (counters[SyntheticReceiptTypes.EXPENDITURE_RETURN]?.totalCash || 0) + (counters[SyntheticReceiptTypes.EXPENDITURE_RETURN]?.totalCashless || 0);
    const correctionIncome = (counters[SyntheticReceiptTypes.CORRECTION_INCOME]?.totalCash || 0) + (counters[SyntheticReceiptTypes.CORRECTION_INCOME]?.totalCashless || 0);
    const correctionIncomeReturn = (counters[SyntheticReceiptTypes.CORRECTION_INCOME_RETURN]?.totalCash || 0) + (counters[SyntheticReceiptTypes.CORRECTION_INCOME_RETURN]?.totalCashless || 0);
    const correctionExpenditure = (counters[SyntheticReceiptTypes.CORRECTION_EXPENDITURE]?.totalCash || 0) + (counters[SyntheticReceiptTypes.CORRECTION_EXPENDITURE]?.totalCashless || 0);
    const correctionExpenditureReturn = (counters[SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN]?.totalCash || 0) + (counters[SyntheticReceiptTypes.CORRECTION_EXPENDITURE_RETURN]?.totalCashless || 0);

    return income + incomeReturn + expenditure + expenditureReturn + correctionIncome + correctionIncomeReturn + correctionExpenditure + correctionExpenditureReturn;
  }

  private crPanelHeight(elementIndex: number): number {
    const arr = this.summaryElements.toArray();
    const marginBottom = 8;
    // @ts-ignore
    return (arr[elementIndex] as ElementRef<HTMLDivElement>).nativeElement.clientHeight + marginBottom;
  }

  private setSummaryContainerHeight(elementIndex: number): void {
    const openedPanelNumber = elementIndex + 1;
    const pagingPanelHeight = 2 * 62;
    const resultHeight = (this.crPanelHeight(elementIndex) * openedPanelNumber) + (window.innerHeight - pagingPanelHeight);

    this.summaryContainer.nativeElement.style.height = `${resultHeight}px`;
  }

  private clearSummaryContainerHeight(): void {
    this.openedPanelsCount = 0;
    if (this.summaryContainer) {
      this.summaryContainer.nativeElement.style.height = "auto";
    }
  }

  private calculateOffsetScrollToSummary(elementIndex: number): void {
    const openedPanelNumber = elementIndex + 1;
    const uiElementsHeight = 72;
    const baseOffsetTop = this.summaryContainer.nativeElement.offsetTop;
    this.offsetScrollToSummary = baseOffsetTop + (this.crPanelHeight(elementIndex) * openedPanelNumber) - (uiElementsHeight * 2);
  }
}
