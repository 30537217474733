import {Injectable} from "@angular/core";
import {merge, Subject} from "rxjs";
import {TranslocoService} from '@ngneat/transloco';
import {TranslateService} from '../../../../../../libs/ui/src/lib/services/translate.service';
import {setAPILanguage} from '@kkm-ui/utils/google-api-lang';
import {LANG_LIST, DEFAULT_LANG} from '@common/constants';
import {Title} from "@angular/platform-browser";
import {EnvironmentInitService} from "libs/ui/src/lib/services/environment.service";

@Injectable({providedIn: 'root'})
export class SettingsService {

  stateMapComponents: Subject<boolean> = new Subject();

  constructor(
    private translocoService: TranslocoService,
    private translateService: TranslateService,
    private titleService: Title,
    private environmentService: EnvironmentInitService
  ) {

  }

  private static LANG_KEY = 'app_lang';
  public static LANGUAGES = LANG_LIST;

  errorTrigger = new Subject<void>();

  public static get AVAILABLE_LANGUAGES() {
    return SettingsService.LANGUAGES.map(e => e.value);
  }

  static get language(): string {
    return localStorage.getItem(this.LANG_KEY) || DEFAULT_LANG.value;
  }

  async initMapLang() {
    await setAPILanguage(SettingsService.language, this.environmentService.config?.common?.googleApiKey, false);
  }

  async setLanguage(value: string) {
    localStorage.setItem(SettingsService.LANG_KEY, value);
    this.translateService.setLocale(value);
    this.translocoService.setActiveLang(value);
    this.reloadMaps(value);
  }

  setTitle() {
    return merge(this.translocoService.events$, this.translocoService.langChanges$).subscribe(() => {
      this.titleService.setTitle(this.translocoService.translate('page.sign-in.subtitle'));
    });
  }

  private async reloadMaps(lang: string) {
    this.stateMapComponents.next(false);
    await setAPILanguage(lang, this.environmentService.config?.common?.googleApiKey, false);
    this.stateMapComponents.next(true);
  }

}
