export const icons = [
  {
    namespace: "action",
    items: [
      {name: "add", url: "action/plus.svg"},
      {name: "add-currentColor", url: "action/plus-currentColor.svg"},
      {name: "back", url: "action/back.svg"},
      {name: "back-currentColor", url: "action/back-currentColor.svg"},
      {name: "up", url: "arrow.svg"},
      {name: "refresh-currentColor", url: "action/refresh-currentColor.svg"},
      {name: 'remove', url: 'action/trash.svg'},
      {name: 'logout', url: 'action/log-out.svg'},
      {name: 'edit', url: 'action/edit.svg'},
      {name: 'edit-currentColor', url: 'action/edit-underscored-currentColor.svg'},
      {name: 'up', url: 'action/chevron-up.svg'},
      {name: 'down', url: 'action/chevron-down.svg'},
      {name: 'up-currentColor', url: 'action/chevron-up-currentColor.svg'},
      {name: 'down-currentColor', url: 'action/chevron-down-currentColor.svg'},
    ],
  },
  {
    namespace: "eye",
    items: [
      {name: "on", url: "eye/eye-on.svg"},
      {name: "off", url: "eye/eye-off.svg"},
    ],
  },
  {
    namespace: "more",
    items: [
      {name: "horizontal", url: "more/horizontal.svg"},
      {name: "vertical", url: "more/vertical.svg"},
      {name: "horizontal-solid", url: "more/horizontal-solid.svg"}
    ],
  },
  {
    namespace: "search",
    items: [
      {name: "magnifyingGlass", url: "search/magnifying-glass-2.svg"},
    ],
  },
  {
    namespace: "close",
    items: [
      {name: "dialog", url: "close/dialog.svg"},
      {name: "dialog-currentColor", url: "close/dialog-currentColor.svg"},
      {name: "narrowInput", url: "close/narrow-input.svg"},
      {name: "register", url: "close/register.svg"},
      {name: "register-currentColor", url: "close/register-currentColor.svg"},
    ],
  },
  {
    namespace: "navigation",
    items: [
      {name: 'menu', url: 'navigation/menu.svg'}
    ]
  },
  {
    namespace: "ofd",
    items: [
      {name: 'cr-manager', url: 'ofd/cr-manager.svg'},
      {name: 'services', url: 'ofd/services.svg'},
      {name: 'vector', url: 'ofd/vector.svg'},
      {name: 'download', url: 'ofd/download.svg'},
    ]
  },
  {
    namespace: "no",
    items: [
      {name: 'download', url: 'no/download.svg'},
      {name: 'store', url: 'no/store.svg'},
      {name: 'notary', url: 'no/notary.svg'},
      {name: 'taxi', url: 'no/taxi.svg'},
      {name: 'info', url: 'no/info.svg'},
      {name: 'settings-currentColor', url: 'no/settings-currentColor.svg'},
      {name: 'down-arrow', url: 'no/down-arrow.svg'},
      {name: 'bar', url: 'no/bar.svg'},
      {name: 'email', url: 'no/email.svg'},
      {name: 'hard-drive-currentColor', url: 'no/hard-drive-currentColor.svg'},
    ]
  },
  {
    namespace: "webcr",
    items: [
      {name: 'sale', url: 'webcr/sale.svg'},
      {name: 'shift', url: 'webcr/shift.svg'},
      {name: 'settings', url: 'webcr/settings.svg'},
      {name: 'info', url: 'webcr/info.svg'},
      {name: 'info-currentColor', url: 'webcr/info-currentColor.svg'},
      {name: 'home-currentColor', url: 'webcr/home-currentColor.svg'},
      {name: 'deposit-money', url: 'webcr/deposit-money.svg'},
      {name: 'withdraw-money', url: 'webcr/withdraw-money.svg'},
      {name: 'return', url: 'webcr/return.svg'},
      {name: 'return-expense', url: 'webcr/return-expense.svg'},
      {name: 'return-income', url: 'webcr/return-income.svg'},
      {name: 'correction', url: 'webcr/adjustment.svg'},
      {name: 'x-report', url: 'webcr/x-report.svg'},
      {name: 'z-report', url: 'webcr/z-report.svg'},
      {name: 'list-inactive', url: 'webcr/list.svg'},
      {name: 'list-active', url: 'webcr/list-active.svg'},
      {name: 'grid-inactive', url: 'webcr/grid.svg'},
      {name: 'grid-active', url: 'webcr/grid-active.svg'},
      {name: 'back', url: 'webcr/back.svg'},
      {name: 'location', url: 'webcr/location.svg'},
      {name: 'locale', url: 'webcr/locale.svg'},
      {name: 'percent', url: 'webcr/percent.svg'},
      {name: 'deregister', url: 'webcr/deregister.svg'},
      {name: 'email', url: 'webcr/email.svg'},
      {name: 'qr-scanner', url: 'webcr/qr-scanner.svg'},
      {name: 'hard-drive', url: 'webcr/hard-drive.svg'},
      {name: 'menu-sale', url: 'webcr/menu-sale.svg'},
      {name: 'menu-purchase', url: 'webcr/menu-purchase.svg'},
      {name: 'arrow-back', url: 'webcr/arrow-back.svg'},
      {name: 'arrow-left', url: 'webcr/arrow-left.svg'},
      {name: 'forward-arrow', url: 'webcr/forward-arrow.svg'},
      {name: 'cancel', url: 'webcr/cancel.svg'},
      {name: 'save', url: 'webcr/save.svg'},
      {name: 'cancel-gray', url: 'webcr/cancel-gray.svg'},
      {name: 'save-green', url: 'webcr/save-green.svg'},
      {name: 'change-cr', url: 'webcr/change-cr.svg'},
      {name: 'scan-qr', url: 'webcr/scan-qr.svg'},
      {name: 'briefcase', url: 'webcr/briefcase.svg'},
      {name: 'payment-types-currentColor', url: 'webcr/payment-types-currentColor.svg'},
      {name: 'briefcase-setting', url: 'webcr/briefcase-setting.svg'},
      {name: 'more-horizontal', url: 'webcr/more-horizontal.svg'},
      {name: 'plus-circle', url: 'webcr/plus-circle.svg'}
    ]
  },
  {
    namespace: 'input',
    items: [
      {name: 'minus-disabled', url: 'input/minus-disabled.svg'},
      {name: 'minus-enabled', url: 'input/minus-enabled.svg'},
      {name: 'plus-disabled', url: 'input/plus-disabled.svg'},
      {name: 'plus-enabled', url: 'input/plus-enabled.svg'},
    ],
  },
  {
    namespace: 'datepicker',
    items: [
      {name: 'calendar', url: 'calendar.svg'},
      {name: 'triangle', url: 'triangle.svg'},
      {name: 'triangle-black', url: 'triangle-black.svg'},
      {name: 'arrow-left', url: 'arrow-left.svg'},
      {name: 'arrow-right', url: 'arrow-right.svg'},
    ],
  },
  {
    namespace: 'funnel',
    items: [
      {name: 'blue', url: 'funnel/blue.svg'},
      {name: 'white', url: 'funnel/white.svg'},
      {name: 'green', url: 'funnel/green.svg'},
    ]
  },
  {
    namespace: 'payment',
    items: [
      {name: 'cash', url: 'payment/cash.svg'},
      {name: 'card', url: 'payment/card.svg'},
      {name: 'advance-currentColor', url: 'payment/advance-currentColor.svg'},
      {name: 'credit-currentColor', url: 'payment/credit-currentColor.svg'},
    ]
  },
  {
    namespace: 'common',
    items: [
      {name: 'alert', url: 'common/alert.svg'},
      {name: 'alert-triangle', url: 'common/alert-triangle.svg'},
      {name: 'alert-triangle-currentColor', url: 'common/alert-triangle-currentColor.svg'},
      {name: 'checkmark-currentColor', url: 'common/checkmark-currentColor.svg'},
      {name: 'question-mark-currentColor', url: 'common/question-mark-currentColor.svg'},
      {name: 'done', url: 'common/done.svg'},
      {name: 'error', url: 'common/error.svg'},
      {name: 'airplay', url: 'common/airplay.svg'},
      {name: 'agent-mark-currentColor', url: 'common/agent-mark-currentColor.svg'},
      {name: 'agent-mark-p', url: 'common/agent-mark-p.svg'},
      {name: 'spin-up', url: 'common/spin-up.svg'},
      {name: 'spin-down', url: 'common/spin-down.svg'},
      {name: 'warning-filled', url: 'common/warning-filled.svg'},
    ]
  },
  {
    namespace: 'reports',
    items: [
      {name: 'bar-chart', url: 'reports/report-bar-chart.svg'},
      {name: 'home', url: 'reports/home.svg'},
      {name: 'briefcase', url: 'reports/briefcase.svg'},
      {name: 'map-pin', url: 'reports/map-pin.svg'},
      {name: 'monitor', url: 'reports/monitor.svg'},
      {name: 'report-z', url: 'reports/report-z.svg'},
      {name: 'receipts', url: 'reports/receipts.svg'},
      {name: 'info', url: 'reports/info.svg'},
      {name: 'users', url: 'reports/users.svg'},
      {name: 'list-outline', url: 'reports/list-outline.svg'},
      {name: 'filter', url: 'reports/filter.svg'},
      {name: 'warning', url: 'reports/warning.svg'}
    ]
  }
];
