import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

interface IDomRect {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Directive({
  selector: '[scrollToTopOnByEvent]',
})
export class ScrollToTopOnByEventDirective implements OnInit, OnDestroy {

  @Input() scrollToTopOnByEvent: Subject<void>;

  private subscription: Subscription = Subscription.EMPTY;

  constructor(private elementRef: ElementRef) { }

  public ngOnInit(): void {
    this.subscription = this.scrollToTopOnByEvent.subscribe(() => {
      const element = this.elementRef.nativeElement;
      const domRect: IDomRect = element.getBoundingClientRect();
      const scrollableContainer: HTMLElement = this.getScrollParent(this.elementRef.nativeElement);

      if (domRect.top < 0 || (scrollableContainer != null && element.offsetTop > scrollableContainer.scrollTop)) {
        this.elementRef.nativeElement.scrollIntoView();
        this.elementRef.nativeElement.scrollTop = 0;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getScrollParent(node: HTMLElement): HTMLElement {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      this.getScrollParent(node.parentElement);
    }
  }
}
