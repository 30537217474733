import { ChangeDetectorRef, Component, forwardRef, Inject, Optional } from '@angular/core';
import { MatCalendarHeader, MatDatepickerIntl } from '@angular/material/datepicker';
import { KkmCalendarComponent } from '../kkm-calendar/kkm-calendar.component';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { IYear, KkmMatCalendarView } from '../kkm-datepicker.interfaces';
import { getYearsArray } from '../kkm-datepicker.helpers';

let uniqueId = 0;

@Component({
  selector: 'kkm-kkm-calendar-header',
  templateUrl: './kkm-calendar-header.component.html',
  styleUrls: ['./kkm-calendar-header.component.styl']
})
export class KkmCalendarHeaderComponent<D> extends MatCalendarHeader<D> {
  years: IYear[] = [];
  _buttonDescriptionId = `mat-calendar-button-${uniqueId++}`;

  constructor(private intl: MatDatepickerIntl,
              @Inject(forwardRef(() => KkmCalendarComponent)) public calendar: KkmCalendarComponent<D>,
              @Optional() private dateAdapter: DateAdapter<D>,
              @Optional() @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
              changeDetectorRef: ChangeDetectorRef) {
    super(intl, calendar, dateAdapter, dateFormats, changeDetectorRef);

    this.years = getYearsArray();
    this.calendar.stateChanges.subscribe(() => changeDetectorRef.markForCheck());
  }

  get prevButtonLabel(): string {
    return {
      'month': this.intl.prevMonthLabel,
      'year': this.intl.prevYearLabel,
      'quarter': this.intl.prevYearLabel,
      'multi-year': this.intl.prevMultiYearLabel
    }[this.calendar.currentView];
  }

  get periodButtonText(): string {
    if ((this.calendar.currentView as string) === KkmMatCalendarView.Quarter) {
      return this.dateAdapter.getYearName(this.calendar.selectedData.date);
    }

    if (this.calendar.currentView === KkmMatCalendarView.Month) {
      return this.dateAdapter
        .format(this.calendar.selectedData.date, this.dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
    }

    if (this.calendar.currentView === KkmMatCalendarView.Year) {
      return this.dateAdapter.getYearName(this.calendar.selectedData.date);
    }

    return super.periodButtonText;
  }

  previousClicked(): void {
    let prevDate;
    const firstYear = this.years[0];

    if (this.calendar.currentView === KkmMatCalendarView.Month) {
      const firstDate = this.dateAdapter.createDate(firstYear.year, 0, 1);
      prevDate = this.dateAdapter.addCalendarMonths(this.calendar.selectedData.date, -1);

      if (prevDate < firstDate) {
        return;
      }

      this.calendar.handleDateSelected({value: prevDate, event: null});
      return;
    }

    const currentYear = this.dateAdapter.getYear(this.calendar.selectedData.date);
    if (currentYear <= firstYear.year) {
      return;
    }

    prevDate = this.dateAdapter.addCalendarYears(this.calendar.selectedData.date, -1);
    const shouldCloseDatepicker = this.calendar.currentView === KkmMatCalendarView.Year;
    this.calendar.handleMonthSelected(prevDate, !shouldCloseDatepicker);
  }

  nextClicked(): void {
    let nextDate;
    const lastYear = this.years[this.years.length - 1];

    if (this.calendar.currentView === KkmMatCalendarView.Month) {
      const lastDate = this.dateAdapter.createDate(lastYear.year, 11, 31);
      nextDate = this.dateAdapter.addCalendarMonths(this.calendar.selectedData.date, 1);

      if (lastDate < nextDate) {
        return;
      }

      this.calendar.handleDateSelected({value: nextDate, event: null});
      return;
    }

    const currentYear = this.dateAdapter.getYear(this.calendar.selectedData.date);
    if (currentYear >= lastYear.year) {
      return;
    }

    nextDate = this.dateAdapter.addCalendarYears(this.calendar.selectedData.date, 1);
    const shouldCloseDatepicker = this.calendar.currentView === KkmMatCalendarView.Year;
    this.calendar.handleMonthSelected(nextDate, !shouldCloseDatepicker);
  }

  get isYearOrMonthView(): boolean {
    return this.calendar.currentView !== KkmMatCalendarView.MultiYear;
  }

  goToPreviousView(): void {
    if ((this.calendar.currentView as string) === KkmMatCalendarView.Quarter) {
      this.calendar.currentView = KkmMatCalendarView.MultiYear;
      return;
    }

    if (this.calendar.currentView === KkmMatCalendarView.Year) {
      this.calendar.currentView = KkmMatCalendarView.MultiYear;
      return;
    }

    if (this.calendar.currentView === KkmMatCalendarView.Month) {
      this.calendar.currentView = KkmMatCalendarView.Year;
      return;
    }
  }

}
