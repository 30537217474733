import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityID, IStore } from '@common/interfaces';
import { catchError, first } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable()
export class CashRegisterService {
  private endpoints;
  constructor(
    @Inject('env') private env,
    private http: HttpClient,
  ) {
    this.endpoints = env?.shared?.services?.cashRegister;
  }

  getCashRegisters(id: EntityID, allStatuses: boolean = false) {
    let url = this.endpoints.cashRegisterListEndpoint(id);

    if (allStatuses) {
      url += `?allStatuses=${allStatuses}`;
    }

    return this.http.get<IStore[]>(url)
      .pipe(
        first(),
        catchError(error => { throw of(error) })
      );
  }

  getCashRegistersStat(params): Observable<any> {
    const { from, to, ...rest } = params;
    const url = this.endpoints.cashRegisterStatsEndpoint(from, to);
    return this.http.post<any>(url, rest)
    .pipe(
      first(),
      catchError(error => { throw of(error) })
    );
  }

  getAgentCashRegisters(taxpayerId: string | number) {
    const url = this.endpoints.agentCashRegistersEndpoint(taxpayerId);
    return this.http.get<string[]>(url);
  }
}
