import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isEmpty, union } from 'lodash';
import { AnalyticsDateFilterTypes, KktStatusEnum } from '@common/enums';
import { translate } from '@ngneat/transloco';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DateFilterEnum, ISelectedDate, Quarters } from '../../kkm-datepicker/kkm-datepicker.interfaces';
import { ICrList } from '@common/interfaces';
import { DateRange } from '@angular/material/datepicker';
import { getDateRange } from '../../kkm-datepicker/kkm-datepicker.helpers';
import moment from "moment/moment";

interface IKkt {
  id: string;
  address: string;
  createdDate: string;
  regDate: string;
  modifyDate: string;
  status: KktStatusEnum;
  checked: boolean;
}

@Component({
  selector: 'kkm-revenue-filter',
  templateUrl: './revenue-filter.component.html',
  styleUrls: ['./revenue-filter.component.styl']
})
export class RevenueFilterComponent implements OnInit, OnDestroy {
  @Input() defaults = null;
  @Input() years = [];
  @Input() isYaTaxiPayer: boolean = false;
  @Output() search = new EventEmitter<any>();

  @Input() agentKkts: ICrList[] = [];
  @Input() set kkts(s: ICrList[]) {
	  const kktList = this.defaults?.search?.kktList || [];
    this.allKkts = s.map((i: ICrList) => ({
      id: i.id,
      address: i.address,
      createdDate: i.createdDate,
      regDate: i.regDate,
      modifyDate: i.modifyDate,
      status: i.status,
      checked: kktList.includes(i.id),
    } as IKkt));

    const addresses = union(s.map(i => i.address));
    this.allAddresses = addresses;
    this.filteredAddresses = addresses;
  };

  allKkts: IKkt[] = [];
  kktCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredAddresses = [];
  allAddresses = [];
  form: FormGroup;
  dateFilterType: string = AnalyticsDateFilterTypes.YEAR;
  dateFilterArray: DateFilterEnum[] = [DateFilterEnum.Year, DateFilterEnum.Quarter, DateFilterEnum.Month];
  selectedData: ISelectedDate<Date>;
  isSearchStrFilterApplied: boolean = false;
  private defaultFilter: any = {};
  private dateRange: DateRange<Date>;
  private formSubscription: Subscription = Subscription.EMPTY;
  private kktListSubscription: Subscription = Subscription.EMPTY;
  private searchStrSubscription: Subscription = Subscription.EMPTY;
  private filteredKktRecordsSubscription: Subscription = Subscription.EMPTY;

  isAgentCrs: IKkt[] = [];
  isNotAgentCrs: IKkt[] = [];

  @ViewChild('kktInput') kktInput: ElementRef<HTMLInputElement>;
  @ViewChild('kktTrigger', { read: MatAutocompleteTrigger }) kktAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('kktAutocomplete') kktAutocomplete: MatAutocomplete;

  constructor(private fb: FormBuilder) {
    this.filteredKktRecordsSubscription = this.kktCtrl
      .valueChanges
      .pipe(
        startWith(null),
        map((kkt: string | null) => kkt
          ? this.filterKkt(kkt)
          : [...this.allKkts]))
      .subscribe(crs => {
        this.isAgentCrs = crs.filter(cr => this.isAgentCr(cr.id));
        this.isNotAgentCrs = crs.filter(cr => !this.isAgentCr(cr.id) && this.isIntersectsWithPeriod(cr));
      });
  }

  ngOnInit(): void {
    this.defaultFilter = {
      searchStr: "",
      year: this.years[this.years.length - 1],
      quarter: Quarters["Q" + moment().quarter()],
      month: moment().month(),
      kktList: [],
      dateFilterType: DateFilterEnum.Month,
      ...this.defaults?.search || {}
    };

    this.dateFilterType = this.defaultFilter.dateFilterType;
    this.form = this.fb.group({
      searchStr: [this.defaultFilter.searchStr],
      year: [this.defaultFilter.year],
      quarter: [this.defaultFilter.quarter],
      month: [this.defaultFilter.month],
      kktList: [this.defaultFilter.kktList],
    });

    this.selectedData = {
      dateFilterType: this.defaultFilter.dateFilterType,
      year: this.defaultFilter.year,
      quarter: this.defaultFilter.quarter,
      month: this.defaultFilter.month,
      day: 1,
      date: new Date(this.defaultFilter.year, this.defaultFilter.month, 1),
      dateRange: null,
    };

    this.dateRange = getDateRange<Date>(this.selectedData, this.selectedData.dateFilterType);

    if (this.defaultFilter.searchStr && this.defaultFilter.kktList.length === 0) {
      this.filterKktsList(this.defaultFilter.searchStr);
    }

    this.formSubscription = this.form.valueChanges.subscribe((v) => {
      const {year, month} = v;
      if (this.currentYear <= year && month > this.currentMonthIdx) {
        this.form.get('month')?.setValue(0);
      }
    });

    this.kktListSubscription = this.form.get('kktList').valueChanges.subscribe((nextValues: any) => {
      for (let i = 0; i < this.allKkts.length; i++) {
        const kktValue = this.allKkts[i];
        kktValue.checked = nextValues.includes(kktValue.id);
      }

      this.kktCtrl.updateValueAndValidity();
    });

    this.searchStrSubscription = this.form.get('searchStr')
      .valueChanges
      .subscribe(this.filterAddresses);

    this.isSearchStrFilterApplied = this.form.get('searchStr').value != null && this.form.get('searchStr').value.trim() !== '';
    this.kktCtrl.setValue(null);
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.kktListSubscription.unsubscribe();
    this.searchStrSubscription.unsubscribe();
    this.filteredKktRecordsSubscription.unsubscribe();
  }

  get selectedKkts() {
    return this.form.get('kktList');
  }

  get hasSelectedKkts(): boolean {
    return this.selectedKkts != null && this.selectedKkts.value != null && this.selectedKkts.value.length > 0;
  }

  get hasAddressValue(): boolean {
    return this.form.get('searchStr').value != null && this.form.get('searchStr').value.trim() !== '';
  }

  handleSearch(ev: MouseEvent) {
    this.search.emit({
      ...this.form.value,
      dateFilterType: this.dateFilterType,
    });

    this.isSearchStrFilterApplied = this.form.get('searchStr').value != null && this.form.get('searchStr').value.trim() !== '';
  }

  clearFormField(ev: MouseEvent, name: string) {
    ev.stopPropagation();
    this.form.get(name)?.setValue('');
    this.form.get('kktList').setValue([]);
  }

  filterKktsList = (value: string): void => {
    if (value) {
      const filteredKkts = this.allKkts.filter(kkt => {
        return kkt.address.toLowerCase().includes(value.toLowerCase());
      }).map(i => i.id);
      this.form.get('kktList').setValue(filteredKkts);
    } else {
      this.form.get('kktList').setValue([]);
    }
  }

  get currentMonthIdx() {
    return new Date().getMonth();
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  filterAddresses = (value: string): void => {
    const filterValue = value.toLowerCase();
    if (!!filterValue) {
      this.filteredAddresses = this.allAddresses.filter(option => option.toLowerCase().includes(filterValue));
    } else {
      this.filteredAddresses = this.allAddresses;
      this.form.get('kktList').setValue([]);
    }
  }

  translate(key: string): string {
    return translate(`common.months.${key}`);
  }

  onSelectionChange($event) {
    const searchStr = this.form.get('searchStr');
    this.filterKktsList(searchStr.value);
  }

  remove(kkt: string): void {
    const values = [...this.selectedKkts.value];
    const index = values.indexOf(kkt);
    if (index >= 0) {
      values.splice(index, 1);
    }

    this.selectedKkts.setValue([...values]);
  }

  selectKkt(kkt: string) {
    const values = [...this.selectedKkts.value];
    const index = values.indexOf(kkt);
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values.push(kkt);
    }

    this.selectedKkts.setValue([...values]);

    if (!isEmpty(this.kktCtrl.value)) {
      this.kktInput.nativeElement.focus();
    }

    setTimeout(() => this.kktAutocompleteTrigger.updatePosition(), 0);
  }

  resetFilter(): void {
    this.dateFilterType = this.defaultFilter.dateFilterType;
    this.selectedData = {
      dateFilterType: this.defaultFilter.dateFilterType,
      year: this.defaultFilter.year,
      quarter: this.defaultFilter.quarter,
      month: this.defaultFilter.month,
      day: 1,
      date: new Date(this.defaultFilter.year, this.defaultFilter.month, 1),
      dateRange: null,
    };

    this.form.setValue({
      searchStr: this.defaultFilter.searchStr,
      year: this.defaultFilter.year,
      quarter: this.defaultFilter.quarter,
      month: this.defaultFilter.month,
      kktList: this.defaultFilter.kktList,
    });

    this.handleSearch(null);
  }

  setFilterData(data: ISelectedDate<Date>): void {
    this.form.patchValue({
      year: data.year,
      quarter: data.quarter,
      month: data.month,
    });

    this.dateFilterType = data.dateFilterType;
    this.dateRange = getDateRange<Date>(data, data.dateFilterType);
    const kktControlValue = this.kktCtrl.value?.trim();
    const kkts = kktControlValue ? this.filterKkt(kktControlValue) : [...this.allKkts];
    this.isNotAgentCrs = kkts.filter(cr => !this.isAgentCr(cr.id) && this.isIntersectsWithPeriod(cr));

    const values = [...this.selectedKkts.value];
    const ids = this.isNotAgentCrs.map((kkt: IKkt) => kkt.id);
    const filteredValues = values.filter((id: string) => ids.includes(id));
    this.selectedKkts.setValue([...filteredValues]);
  }

  isAgentCr(crId: string) {
    return this.agentKkts.map(cr => cr.id).includes(crId);
  }

  selectAllAgentCrs(event: boolean) {
    const values = [...this.selectedKkts.value];

    this.agentCrList.forEach(cr => {
      if (event) {
        !values.includes(cr) && values.push(cr);
      } else {
        values.includes(cr) && values.splice(values.indexOf(cr), 1);
      }
    });

    this.selectedKkts.setValue([...values]);
  }

  selectAllTaxpayerCrs(event: boolean) {
    const values = [...this.selectedKkts.value];

    this.crList.forEach(cr => {
      if (event) {
        !values.includes(cr) && values.push(cr);
      } else {
        values.includes(cr) && values.splice(values.indexOf(cr), 1);
      }
    });

    this.selectedKkts.setValue([...values]);
  }

  selectAllCrs(event: boolean) {
    this.selectAllAgentCrs(event);
    this.selectAllTaxpayerCrs(event);
  }

  get isDefaultState() {
    const { searchStr, year, quarter, month, kktList } = this.form.value;
    return searchStr === this.defaultFilter.searchStr
      && year === this.defaultFilter.year
      && quarter === this.defaultFilter.quarter
      && month === this.defaultFilter.month
      && kktList.length === 0;
  }

  get agentCrList() {
    return this.agentKkts.map(cr => cr.id);
  }

  get crList() {
    return this.allKkts
      .filter(cr => !this.agentCrList.includes(cr.id) && this.isIntersectsWithPeriod(cr))
      .map(cr => cr.id);
  }

  get allAgentCrSelected() {
    const selected = this.selectedKkts.value;
    return this.agentCrList.every(cr => selected.includes(cr));
  }

  get allNotAgentCrSelected() {
    const selected = this.selectedKkts.value;
    return this.crList.every(cr => selected.includes(cr));
  }

  get allCrSelected() {
    const selected = this.selectedKkts.value;
    const all = [...this.crList, ...this.agentCrList];
    return all.every(cr => selected.includes(cr));
  }

  private filterKkt(value: string): IKkt[] {
    const filterValue = value.toLowerCase();

    return this.allKkts
      .filter((kkt: any) => kkt.id.toLowerCase().includes(filterValue));
  }

  private isIntersectsWithPeriod(cr: IKkt): boolean {
    const regDate = new Date(cr.regDate);
    const modifyDate = new Date(cr.modifyDate);

    if (cr.status !== KktStatusEnum.DEACTIVATED) {
      return regDate <= this.dateRange.end;
    }

    const leftIntersection = regDate <= this.dateRange.start && this.dateRange.start < modifyDate && modifyDate <= this.dateRange.end;
    const fullIntersection = this.dateRange.start <= regDate && modifyDate <= this.dateRange.end;
    const rightIntersection = this.dateRange.start <= regDate && regDate < this.dateRange.end && this.dateRange.end <= modifyDate;

    return leftIntersection || fullIntersection || rightIntersection;
  }
}
