import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Directive({
  selector: '[focusFirstInvalid]'
})
export class FocusFirstInvalidDirective {

  constructor(private el: ElementRef) { }

  @Input() formGroup: FormGroup;

  @HostListener('submit', ['$event'])
  public onSubmit(event): void {
    event.preventDefault();

    this.markGroupDirty(this.formGroup);

    if (this.formGroup.invalid) {
      const invalidElements = this.el.nativeElement.querySelectorAll('input.ng-invalid');
      (<HTMLInputElement>invalidElements[0])?.focus();
    }
  }

  markGroupDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {

      if (formGroup.get(key) instanceof FormGroup) {
        this.markGroupDirty(formGroup.get(key) as FormGroup);
      } else if (formGroup.get(key) instanceof FormArray) {
        this.markArrayDirty(formGroup.get(key) as FormArray);
      } else if (formGroup.get(key) instanceof FormControl) {
        this.markControlDirty(formGroup.get(key) as FormControl);
      }
    });
  }

  markArrayDirty(formArray: FormArray) {
    formArray.controls.forEach(control => {

      if (control instanceof FormGroup) {
        this.markGroupDirty(control as FormGroup);
      } else if (control instanceof FormArray) {
        this.markArrayDirty(control as FormArray);
      } else if (control instanceof FormControl) {
        this.markControlDirty(control as FormControl);
      }
    });
  }

  markControlDirty(formControl: FormControl) {

    if (this.formGroup.invalid) {
      formControl.markAsDirty();
      formControl.markAsTouched();
    }

    if (formControl.hasError('incorrect')) {
      delete formControl.errors['incorrect'];
      formControl.updateValueAndValidity();
    }

  }
}
