import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { forkJoin, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from "./services/settings/settings.service";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return forkJoin([
      this.http.get<Translation>(`/assets/i18n-common/${lang}.json`),
      this.http.get<Translation>(`/assets/i18n/${lang}.json`),
    ]).pipe(map(([common, translation]) => ({ ...common, ...translation })));
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: SettingsService.AVAILABLE_LANGUAGES,
        defaultLang: SettingsService.language,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
