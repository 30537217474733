<div *ngIf="!isLoaded || isStatLoading" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>
<div *ngIf="isLoaded && !isStatLoading" class="m_t-6">
  <div class="">
    <kkm-revenue-filter [defaults]="opts"
                        [years]="years"
                        (search)="applyFilter($event)"
                        [kkts]="allCashRegisters"
                        [agentKkts]="agentCashRegisters"
                        [isYaTaxiPayer]="isYaTaxiPayer"></kkm-revenue-filter>
  </div>
  <div class="p_t-6" *ngIf="hasStatistics || hasReceipts">

    <kkm-revenue-chart *ngIf="isLoaded"
                       [cashRegisters]="cashRegisters"
                       [mainFilter]="mainFilter"
                       [isLoading]="isStatLoading"
                       [statistics]="statistics"
                       [agentStatistics]="agentStatistics"
                       [isYaTaxiPayer]="isYaTaxiPayer"></kkm-revenue-chart>

    <div *ngIf="isLoaded" class="layout-container__negative-margin">
      <kkm-summary-info [cashRegisters]="cashRegisters"
                        [agentCashRegisters]="agentCashRegisters"
                        [mainFilter]="receiptFilter"
                        [taxpayerId]="taxpayerId"
                        [taxpayer]="taxpayer"
                        [isShowFdNumber]="isShowFdNumber"></kkm-summary-info>
    </div>

  </div>
  <div *ngIf="!hasStatistics && !hasReceipts"
       class="white-area box_shadow m_y-6 d-flex flex-column justify-content-center align-items-center no-data-area p_y-12">
    <div class="no-data-area__image m_b-8"></div>
    <h2 *transloco="let t, read: 'common.components.sales-analytics'">{{ t('no_sales') }}</h2>
  </div>
</div>
