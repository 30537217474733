export class CountersColumn {
  columnName: string;
  rowKey: string;
  className: string;

  constructor(columnName: string, rowKey: string, className: string = '') {
    this.columnName = columnName;
    this.rowKey = rowKey;
    this.className = className;
  }
}
