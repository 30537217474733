import { Directive, Host } from '@angular/core';
import { NgForOf } from '@angular/common';
import { EntityID } from '@common/interfaces';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngForTrackById]',
})
export class NgForTrackByIdDirective<T extends { id: EntityID }> {
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, item: T) => item.id;
  }
}

