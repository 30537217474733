import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ILang, ITokens } from '@common/interfaces';
import { TranslateService } from '../../../../../../libs/ui/src/lib/services/translate.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { DataAlreadyExistErrors } from '@common/enums';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecoveryService } from '../../services/recovery.service';
import { passwordValidator } from '@kkm-ui/utils/validation';

export enum RecoveryStates {
  EMAIL,
  SENT,
  EXPIRED,
  PASSWORD
}

export interface ITranslateKeys {
  title: string;
  subtitle: string;
}

@Component({
  selector: 'kkm-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.styl']
})
export class RecoveryComponent implements OnInit, OnDestroy {

  @Input() settings: any;
  @Input() titles: ITranslateKeys;
  @Input() openSession: (tokens: ITokens) => void;

  state: RecoveryStates = RecoveryStates.EMAIL;
  emailForm: FormGroup;
  passwordForm: FormGroup;

  passwordHide = true;

  statusCode = 200;
  isLoadingEmail = false;
  isLoadingPassword = false;
  backendRegistrationErrors: string[] = [];

  subscription: Subscription = Subscription.EMPTY;
  errorState: boolean = false;
  recoveryStates = RecoveryStates;

  token: string = null;

  public dataAlreadyExistErrors = DataAlreadyExistErrors;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private translocoService: TranslocoService,
    private fb: FormBuilder,
    private recoveryService: RecoveryService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.code) {
        this.state = RecoveryStates.PASSWORD;
        this.token = params.code;
      }
      this.emailForm = this.fb.group({
        email: [
          {value: params._email ? atob(params._email) : null, disabled: this.isLoadingEmail},
          [Validators.required, Validators.pattern(/^\S{2,}@\S{2,}\.[a-zA-Z]{2,4}$/)]
        ],
      });
    });
    this.passwordForm = this.fb.group({
      password: [
        {value: null, disabled: this.isLoadingPassword},
        [Validators.required, passwordValidator()]
      ],
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get failAreaErrors(): string[] {
    return this.backendRegistrationErrors.filter((error: string) =>
      error !== DataAlreadyExistErrors.EmailNotUnique &&
      error !== DataAlreadyExistErrors.TinNotUnique);
  }

  get emailControl(): FormControl {
    return this.emailForm.get('email') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.passwordForm.get('password') as FormControl;
  }

  submitEmail() {
    if (this.emailForm.valid && !this.isLoadingEmail) {
      this.isLoadingEmail = true;
      this.recoveryService.sendEmail(this.emailControl.value).subscribe(() => {
        this.state = RecoveryStates.SENT;
        this.isLoadingEmail = false;
      }, () => this.isLoadingEmail = false);
    }
  }

  submitPassword() {
    if (this.passwordForm.valid && !this.isLoadingPassword) {
      this.isLoadingPassword = true;
      this.recoveryService.sendPassword(this.token, this.passwordControl.value).subscribe(
        (tokens: ITokens) => {
          this.openSession(tokens);
          this.isLoadingPassword = false;
          this.router.navigate(['/app'], {queryParams: {isPwdUpdated: true}});
        }, () => {
          this.isLoadingPassword = false;
          this.state = RecoveryStates.EXPIRED;
        });
    }
  }

  selectLanguage(lang: ILang) {
    this.settings.setLang(lang.value);
    this.translateService.setLocale(lang.value);
    this.translocoService.setActiveLang(lang.value);
  }

  isFocused(el: Element) {
    return document.activeElement === el;
  }

}
