import urlPrefix from "./apiPrefix";

export const getDirectoryTaxSystems = () => `${urlPrefix.value}/tax-web-control/directory/tax-systems`;
export const getDirectoryTaxRates = () => `${urlPrefix.value}/tax-web-control/directory/tax-rates`;
export const getDirectoryTaxAuthorityDepartments = () => `${urlPrefix.value}/tax-web-control/directory/tax-authority-departments`;
export const getDirectoryManufacturers = () => `${urlPrefix.value}/tax-web-control/directory/manufacturers`;
export const getDirectoryLegalPersonActivities = () => `${urlPrefix.value}/tax-web-control/directory/legal-person-activities`;
export const getDirectoryFDErrorInfo = () => `${urlPrefix.value}/tax-web-control/directory/fd-error-info`;
export const getDirectoryExperts = () => `${urlPrefix.value}/tax-web-control/directory/experts`;
export const getDirectoryEntrepreneurshipObjects = () => `${urlPrefix.value}/tax-web-control/directory/entrepreneurship-objects`;
export const getDirectoryCurrencyRatesLoad = () => `${urlPrefix.value}/tax-web-control/directory/currency-rates/load`;
export const getDirectoryCurrencyRatesListFromDate = () => `${urlPrefix.value}/tax-web-control/directory/currency-rates/list-from-date`;
export const getDirectoryCashRegistry = () => `${urlPrefix.value}/tax-web-control/directory/cashregistry`;
export const getDirectoryCashes = () => `${urlPrefix.value}/tax-web-control/directory/cashes`;
export const getDirectoryCalcItemAttributes = () => `${urlPrefix.value}/tax-web-control/directory/calc-item-attributes`;
export const getDirectoryBusinessActivities = () => `${urlPrefix.value}/tax-web-control/directory/business-activities`;
export const getDirectoryBaseCatalogue = () => `${urlPrefix.value}/tax-web-control/directory/base-catalogue`;
export const getDirectoryBaseCatalogueItems = () => `${urlPrefix.value}/tax-web-control/directory/base-catalogue/items`;
