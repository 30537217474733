<div *transloco="let t; read: TRANSLATION_READ_PATH">
  <div *ngIf="isLoading" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>

  <!-- FILTER -->
  <form *ngIf="isLoading === false" [formGroup]="form" (ngSubmit)="filter($event)" class="filter-container p_x-8 p_y-4" [hidden]="!isFilterActive">
    <div class="row d-flex align-items-center filter-content">

      <mat-form-field appearance="fill" class="kkm-form-field shift-form-field compact p_l-0 p_r-6">
        <input matInput type="text" autocomplete="off" formControlName="shiftNumber" [placeholder]="t('shiftNumber')">
        <button *ngIf="form.get('shiftNumber').value" type="button" class="kkm-button kkm-icon-button" matSuffix
                mat-icon-button (click)="clearFormField($event, 'shiftNumber')">
          <mat-icon svgIcon="close:narrowInput" class="small"></mat-icon>
        </button>
      </mat-form-field>

      <ng-container *transloco="let t; read: 'common.components.sales-analytics.receipts-summary'">
        <!-- Receipt Types, hidden because of Bug #133121 -->
<!--        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field compact col p_l-0 p_r-6 d-none">-->
<!--          <mat-select formControlName="receiptType" disableRipple="true" panelClass="kkm-select-panel__compact">-->
<!--            <mat-option *ngFor="let type of receiptTypes; trackById" [value]="type.id">{{ t(type.name) }}</mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->

        <!-- Settlement Types -->
        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field compact receipt-operation-type p_l-0 p_r-6">
          <mat-select formControlName="receiptOperationType" disableRipple="true"
                      panelClass="kkm-select-panel__compact">
            <mat-option *ngFor="let type of receiptOperationTypes; trackById"
                        [value]="type.id">{{ t(type.name) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- Apply filter button -->
      <button mat-button type="submit" color="primary"
              class="kkm-button narrow fixed-height primary-color auto-min-width"
              [disabled]="!isFilterChanged">
        {{t('apply-button')}}
      </button>

      <!-- Reset filter button -->
      <button *ngIf="areFiltersApplied" mat-icon-button
              class="kkm-button reset-filter-button"
              [class.m_l-6]="areFiltersApplied"
              (click)="resetFilter($event)">
        <mat-icon svgIcon="close:dialog-currentColor"></mat-icon>
      </button>

    </div>
  </form>

  <kkm-no-data-label *ngIf="(!hasShifts || !hasFilteredShifts) && !isLoading"
                     class="body_text"
                     [text]="t('noShiftsFound')">
  </kkm-no-data-label>
  <div *ngFor="let shift of filteredShifts; trackById">
    <div class="d-flex justify-content-between p_x-6 p_y-4 shift-container" [class.with-filter]="isFilterActive">

      <div class="d-flex">
        <div class="d-flex m_r-8">
          <div class="body_text muted_text m_r-2">{{t('shift')}}</div>
          <div class="body_text semi_bold">№{{shift.shiftNumber}}</div>
        </div>
        <div class="d-flex m_r-8">
          <div class="body_text muted_text m_r-2">{{t('cashier')}}</div>
          <div class="body_text semi_bold">{{shift.cashierName}}</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="body_text link semi_bold m_l-8"
             (click)="openReport($event, shift, 'OPENING')">{{t('openingReport')}}</div>
        <div *ngIf="!shift.endDate" class="body_text link semi_bold m_l-8"
             (click)="openReport($event, shift, 'X')">{{t('xReport')}}</div>
        <div *ngIf="shift.endDate" class="body_text link semi_bold m_l-8"
             (click)="openReport($event, shift, 'Z')">{{t('zReport')}}</div>
        <div class="d-flex m_l-10">
          <div class="goto-icon left" (click)="changeShift(currentShiftIdx - 1)"
               [class.disabled]="currentShiftIdx === 0"></div>
          <div class="body_text m_x-3">{{currentShiftIdx + 1}}/{{totalShifts}}</div>
          <div class="goto-icon right" (click)="changeShift(currentShiftIdx + 1)"
               [class.disabled]="currentShiftIdx === (totalShifts - 1)"></div>
        </div>
      </div>
    </div>
    <kkm-receipts-grid [data]="selectedVouchers"
                       [columns]="gridColumns"
                       [type]="analyticsSummaryTypes.BY_CASH_REGISTERS"
                       [isFilterActive]="isFilterActive"
                       [selectedReceiptId]="selectedReceiptId"
                       [redrawCallback]="redrawCallback"
                       [isSkeletonLoading]="isLoadingReceipts"
                       [isShowFdNumber]="isShowFdNumber"
                       (onRowClick)="openReceipt($event)"></kkm-receipts-grid>
  </div>
  <kkm-shift-report [kktNumber]="cashRegister.regNumber"
                    [shiftId]="selectedReportId"
                    [type]="selectedReportType"
                    [calcTypes]="calculationType"
                    [taxpayerName]="taxpayer?.name"
                    [sn]="cashRegister?.sn"
                    (onClose)="clearSelectedReport($event)"></kkm-shift-report>
</div>
