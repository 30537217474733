import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { oc } from 'ts-optchain';
import { QueryParamsService } from '../query-params/query-params.service';
import { ITokens } from './session.interfaces';
import { SessionService } from './session.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<string | null>(null);

  constructor(
    private dialog: MatDialog,
    private sessionService: SessionService,
    private queryParamsService: QueryParamsService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken: string = oc(this.sessionService).tokens.accessToken('');
    const iReq: HttpRequest<any> = accessToken ? this.addToken(req, accessToken) : req;
    return next.handle(iReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next, error);
        }
        return throwError(error);
      }),
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (this.sessionService.isRefreshTokenExpired) {
      this.dialog.closeAll();
      this.router.navigate(['/sign-in'], {
        replaceUrl: true,
        queryParams: {_url: btoa(this.router.url)},
      });
      return throwError(error);
    }
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.sessionService.doRefreshToken().pipe(
        switchMap((tokens: ITokens) => {
          this.isRefreshing = false;
          const { accessToken } = tokens;
          this.refreshTokenSubject.next(accessToken);
          this.sessionService.getCurrentUser().subscribe();
          return next.handle(this.addToken(req, accessToken));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(accessToken => accessToken != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(req, accessToken));
        }),
      );
    }
  }

  private addToken(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return req.clone({setHeaders: {'Access-Token': accessToken}});
  }
}
