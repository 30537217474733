import {Component, Input} from "@angular/core";

@Component({
  selector: 'kkm-no-data-label',
  templateUrl: './no-data-label.component.html',
  styleUrls: ['./no-data-label.component.styl']
})
export class NoDataLabelComponent {
  @Input() text: string;
}
