import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IBaseCurrency, ICurrency } from '@common/interfaces';
import { CurrencyType } from '@common/enums';
import { CURRENCY_FRACTURE_PART } from '@common/constants';

@Injectable()
export class CurrencyCatalogService {

  private readonly createEndpoint: string;
  private readonly updateEndpoint: string;
  private readonly deleteEndpoint: (id: number) => string;
  private readonly listEndpoint: string;
  private readonly uploadEndpoint: string;

  constructor(@Inject('env') private env,
              private http: HttpClient) {
    this.createEndpoint = env?.shared?.services?.currency?.createEndpoint;
    this.updateEndpoint = env?.shared?.services?.currency?.updateEndpoint;
    this.deleteEndpoint = env?.shared?.services?.currency?.deleteEndpoint;
    this.listEndpoint = env?.shared?.services?.currency?.listEndpoint;
    this.uploadEndpoint = env?.shared?.services?.currency?.uploadEndpoint;
  }

  public async getCurrencyList(): Promise<ICurrency[]> {
    const currencyList = await this.http.get<IBaseCurrency[]>(this.listEndpoint).toPromise();
    const result: ICurrency[] = currencyList.map((currency: IBaseCurrency) => ({
      ...currency,
      sellExchangeRate: currency.sellExchangeRate * CURRENCY_FRACTURE_PART,
      buyExchangeRate: currency.buyExchangeRate * CURRENCY_FRACTURE_PART,
      isEdit: false,
      type: CurrencyType.Catalog,
      basePrice: 0,
    }));

    return result;
  }

  public async createCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await this.http.post(this.createEndpoint, baseCurrency).toPromise();
  }

  public async updateCurrency(currency: ICurrency): Promise<void> {
    const baseCurrency: IBaseCurrency = {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      buyExchangeRate: currency.buyExchangeRate,
      sellExchangeRate: currency.sellExchangeRate,
      modifyDate: currency.modifyDate,
      hidden: currency.hidden,
    };

    await this.http.put(this.updateEndpoint, baseCurrency).toPromise();
  }

  public async deleteCurrency(currency: ICurrency): Promise<void> {
    await this.http.delete(this.deleteEndpoint(currency.id)).toPromise();
  }

  public async uploadCurrencyFile(currencyFile: File): Promise<void> {
    if (this.uploadEndpoint == null) {
      return;
    }

    const formData = new FormData();
    formData.append('file', currencyFile, currencyFile.name);

    await this.http.post(this.uploadEndpoint, formData).toPromise();
  }
}
