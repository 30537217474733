import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../settings/settings.service';
import { EventBusService, EventData } from '@kkm-ui/ui';
import { SpecialErrors } from '@common/enums';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  private readonly urlsExcludedFrom500ErrorHandling: string[];

  // dictionaries (one url with hidden special character?!)
  private readonly taxSystemsDictionaryURL = 'directory/tax-systems';
  private readonly taxRatesDictionaryURL1 = 'directory​/tax-rates';
  private readonly taxRatesDictionaryURL2 = 'directory/tax-rates';

  // pre-registration info from outer service
  private readonly preRegisterURL1 = '/tax-web-control/client/api/taxpayers/preregister-info/';
  private readonly preRegisterURL2 = '/web-control/service-api/preregister/';
  private readonly preRegisterURL3 = '/api/info/preregister/';

  // taxpayer details: last vouchers
  private readonly lastVouchersURL = '/voucher/filter';

  constructor(
    private settings: SettingsService,
    private eventBus: EventBusService,
    private dialog: MatDialog
    ) {
    this.urlsExcludedFrom500ErrorHandling = [
      this.taxSystemsDictionaryURL,
      this.taxRatesDictionaryURL1,
      this.taxRatesDictionaryURL2,
      this.lastVouchersURL,
      this.preRegisterURL1,
      this.preRegisterURL2,
      this.preRegisterURL3,
    ];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
          // Http Response
          }
        }, err => {
          if (err instanceof HttpErrorResponse) {
            // Http Error Response
            if(err.status === 500) {
              if (this.urlsExcludedFrom500ErrorHandling.some((url: string) => err.url.includes(url))) {

                if (err.url.includes(this.lastVouchersURL) || err.url.includes(this.lastVouchersURL)) {
                  this.eventBus.emit(new EventData('special-errors/loading-error', SpecialErrors.LastVouchersLoadingError));
                  return;
                }

                if (err.url.includes(this.preRegisterURL1) || err.url.includes(this.preRegisterURL2) || err.url.includes(this.preRegisterURL3)) {
                  this.eventBus.emit(new EventData('special-errors/loading-error', SpecialErrors.PreRegisterLoadingError));
                  return;
                }

                return;
              }

              this.dialog.closeAll();
              this.settings.errorTrigger.next();
            }
          }
        })
      );
  }
}
