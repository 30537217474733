import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RegistrationMode } from '@common/enums';

export interface IEnvironmentConfig {
  aisOfd: {
    registrationMode: RegistrationMode;
    taxServiceLink: string;
    taxServiceWebsite: string;
  };
  aisWebcr: {
    cloudUrl: string;
    max_shift_duration: string;
    taxServiceLink: string;
  };
  cloudFn: {
    registrationMode: RegistrationMode;
    confirmationEmailDisabled: boolean;
  };
  aisNo: {
    hiddenMenuItems: string[];
  };
  common: {
    googleApiKey: string;
    styleMapId: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentInitService {

  private _config: IEnvironmentConfig;

  constructor(private http: HttpClient) { }

  async Init() {
    this._config = await this.http.get<IEnvironmentConfig>('/assets/runtimeConfig.json').toPromise();
  }

  get config(): IEnvironmentConfig {
    return this._config;
  }

}
