import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'kkm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.styl']
})
export class SidebarComponent implements OnInit {
  @Input() clickOutside: boolean = true;
  @Input() top: string = '0px';
  @Input() bottom: string = '0px';
  @Input() height: string = '100%';
  @Input() width: string = '78.4rem';
  @Input() titleSize: string = 'medium';
  @Input() isOpened: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() title?: string = '';
  @Output() onClose = new EventEmitter<MouseEvent>();
  @ViewChild('sidebar', { static: true }) sidebarRef;
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (this.clickOutside && !this.sidebarRef.nativeElement.contains(event.target)) {
      this.handleClose(event);
    }
  }

  handleClose(ev: MouseEvent): void {
    if (this.isOpened && !this.isLoading) {
      this.onClose.emit(ev);
    }
  }

}
