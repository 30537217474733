<div class="cr_info-inner" *transloco="let t, read: 'common.components.cr-details'">
  <h1 class="d-flex align-items-center m_b-2">
    <span [textContent]="data?.regNumber"></span>
    <i *ngIf="displayOptions?.showOnline" [ngClass]="['kkt-status', data?.online ? 'green' : 'gray']"></i>
    <i *ngIf="displayOptions?.showStatus && data?.status"
       class="status-badge m_l-3"
       [ngClass]="'status-badge_' + getStatusBadgeClass()"
       [textContent]="t(getStatusBadgeClass())"></i>
  </h1>
  <div *ngIf="data?.location?.name" class="body_text text-break m_b-1">
    <span [textContent]="data?.location?.name"></span>
  </div>
  <div class="body_text">
    <span [textContent]="data?.location?.address?.formattedString || '-'"></span>
  </div>

  <div class="hide-button small_text m_t-3" (click)="showTaxSettings = !showTaxSettings">
    {{t(showTaxSettings ? 'hide-tax-settings-button' : 'show-tax-settings-button')}}
  </div>

  <div *ngIf="showTaxSettings" class="m_t-3">
    <div class="small_text" [ngClass]="enterpreneurshipDetails.length === 0 ? 'm_b-4' : 'm_b-2'">
      <span class="muted_text">{{t('tax-system-label')}}</span>
      <span>{{formattedTaxSystems}}</span>
      <span *ngIf="this.data?.vatPayer">{{t('vat-payer')}}</span>
    </div>

    <div *ngFor="let detail of enterpreneurshipDetails;let last = last" class="small_text" [class.m_b-2]="!last" [class.m_b-4]="last">
      <span class="muted_text">{{ t(detail.key) }}: </span>
      <span>{{ detail.value }}</span>
    </div>
  </div>

  <div *ngIf="!!fnExpirationWarningDays && showExpirationWarning" class="m_t-6">
    <kkm-notification *ngIf="showExpirationWarning" class="m_b-5" type="warning" (close)="closeCrWarning()">
      <span class="m_r-1" [textContent]="t('fn-expiration-warning.prefix')"></span>
      <span class="m_r-1"
            [textContent]="t(data?.fnActivationDaysLeft === 1 ? 'fn-expiration-warning.postfix-once-day' : 'fn-expiration-warning.postfix-many-days', {days: data?.fnActivationDaysLeft}) + ':'"></span>
      <span><kkm-date [date]="fnExpirationDate" format="mediumDate"></kkm-date></span>
    </kkm-notification>

    <kkm-notification *ngIf="showFnBlocked" class="m_b-5" type="danger">
      <span [textContent]="t('fn-expired-error')"></span>
    </kkm-notification>
  </div>

  <div class="d-flex flex-row m_t-6">
    <div class="d-flex flex-column info-column">
      <div class="m_b-4">
          <div class="muted_text small_text m_b-1" [textContent]="data?.status !== crStatus.DEACTIVATED ? t('registration-date-label') : t('deregister-date-label')"></div>
          <div class="body_text"><kkm-date [date]="(data?.status !== crStatus.DEACTIVATED ? data?.regDate : data?.modifyDate)" format="mediumDate"></kkm-date></div>
      </div>
      <div>
          <div class="muted_text small_text m_b-1" [textContent]="t('fiscal-memory-device-label')"></div>
          <div class="body_text" [textContent]="data?.fnSerialNumber || '-'"></div>
      </div>
    </div>
    <div class="d-flex flex-column info-column">
      <div class="m_b-4">
        <div class="muted_text small_text m_b-1" [textContent]="t('model-label')"></div>
        <div class="body_text" [textContent]="(data?.model + ' ' + data?.version) || '-'"></div>
      </div>
      <div *ngIf="!isUnlimitedFm && !!data?.fnExpirationDate">
        <div class="muted_text small_text m_b-1" [textContent]="t('fn-expiration-date-label')"></div>
        <div class="body_text" ><kkm-date [date]="data?.fnExpirationDate" format="mediumDate"></kkm-date></div>
      </div>
    </div>
    <div class="d-flex flex-column info-column">
      <div>
          <div class="muted_text small_text m_b-1" [textContent]="t('serial-number-label')"></div>
          <div class="body_text" [textContent]="data?.sn || '–'"></div>
      </div>
    </div>
  </div>
</div>
