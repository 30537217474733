<div class="pagination box_shadow" *transloco="let t, read: 'common.components.pagination'">
  <form class="goto-links" [formGroup]="form" (ngSubmit)="submitPage()">
    <div class="previous-page"
         [class.disabled]="!isDataLoaded || !paginatorState.hasPrevPage"
         (click)="handlePageChange(paginatorState.pageNumber - 1)"></div>

    <mat-form-field appearance="fill" class="kkm-form-field compact compact__page-number pagination__page-number">
      <input matInput type="text" autocomplete="off" formControlName="pageNumber"
             [imask]="pageNumberMask"
             [unmask]="true">
    </mat-form-field>

    <div class="next-page"
         [class.disabled]="!isDataLoaded || !paginatorState.hasNextPage"
         (click)="handlePageChange(paginatorState.pageNumber + 1)"></div>
    <div class="small_text">{{ t('total-pages', { total: paginatorState.totalPages }) }}</div>
  </form>

  <div class="total-info small_text">
    <kkm-overlay-panel [showOverlay]="showItemsPerPageSelector"
                       [showCloseButton]="false"
                       (emitResetShowOverlay)="showItemsPerPageSelector = false">
      <div overlayTrigger>
        <span class="current-items-range" (click)="showItemsPerPageSelector = true">{{ paginatorState.currentStartOffset }}-{{ paginatorState.currentEndOffset }}</span>
        <span>{{ t('total-items', { total: paginatorState.totalItems }) }}</span>
      </div>
      <div overlayBody>
        <div class="page-size__container">
          <div class="page-size__item" (click)="setPageSize(20)">
            <span class="body_text" [textContent]="t('page-size', { pageSize: 20 })"></span>
          </div>
          <div class="page-size__item" (click)="setPageSize(50)">
            <span class="body_text" [textContent]="t('page-size', { pageSize: 50 })"></span>
          </div>
          <div class="page-size__item" (click)="setPageSize(100)">
            <span class="body_text" [textContent]="t('page-size', { pageSize: 100 })"></span>
          </div>
        </div>
      </div>
    </kkm-overlay-panel>
  </div>
</div>
