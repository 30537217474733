<div class="filter-container" *transloco="let t; read: 'common.components.sales-analytics.revenue-filter'">
  <form [formGroup]="form" (ngSubmit)="handleSearch($event)" class="d-flex container-fluid p-0">

    <div class="filter-container__filters row m_x-0">
      <!-- DATEPICKER -->
      <div class="kkm-form-field-datepicker p_l-0 m_r-3">
        <kkm-datepicker [dateFilterArray]="dateFilterArray"
                        [selectedData]="selectedData"
                        (selectedDataChange)="setFilterData($event)"></kkm-datepicker>
      </div>

      <!-- ADDRESS AUTOCOMPLETE -->
      <div *ngIf="!isYaTaxiPayer" class="col-4">
        <mat-form-field appearance="fill" class="kkm-form-field compact col p-0">
          <mat-icon matPrefix svgIcon="search:magnifyingGlass" [class.search-applied]="isSearchStrFilterApplied"></mat-icon>
          <input matInput type="text" formControlName="searchStr"
                 [placeholder]="t('searchStr')"
                 [matAutocomplete]="auto">
          <mat-autocomplete class="mat-autocomplete-panel2" #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
            <mat-option *ngFor="let address of filteredAddresses" [value]="address">
              {{address}}
            </mat-option>
          </mat-autocomplete>
          <button *ngIf="hasAddressValue" type="button" class="kkm-button kkm-icon-button" matSuffix mat-icon-button (click)="clearFormField($event, 'searchStr')">
            <mat-icon svgIcon="close:narrowInput" class="small m_r-4"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- KKM AUTOCOMPLETE -->
      <div class="d-flex col p_r-0">
        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field kkm-mat-chip-form-field compact">
          <mat-chip-list #chipList formControlName="kktList">
            <mat-chip
              *ngFor="let kkt of selectedKkts.value"
              [selectable]="true"
              [removable]="true"
              (removed)="remove(kkt)">
              {{ kkt }}
              <mat-icon matChipRemove [svgIcon]="'close:dialog-currentColor'"></mat-icon>
            </mat-chip>
            <input
              #kktInput
              #kktTrigger="matAutocompleteTrigger"
              [placeholder]="hasSelectedKkts ? '' : t('cashRegisterId')"
              [formControl]="kktCtrl"
              [matAutocomplete]="kktAutocomplete"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #kktAutocomplete="matAutocomplete">

            <mat-option>
              <mat-checkbox class="kkm-mat-autocomplete__checkbox d-block semi_bold w-fluid h-fluid"
                            [checked]="allCrSelected"
                            (change)="selectAllCrs($event.checked)"
                            (click)="$event.stopPropagation()">
                {{t('select-all')}}
              </mat-checkbox>
            </mat-option>

            <mat-option *ngIf="isNotAgentCrs.length" class="drop-down-bg">
              <mat-checkbox class="kkm-mat-autocomplete__checkbox d-block semi_bold w-fluid h-fluid"
                            [checked]="allNotAgentCrSelected"
                            (change)="selectAllTaxpayerCrs($event.checked)"
                            (click)="$event.stopPropagation()">
                {{t('select-taxpayers-cr')}}
              </mat-checkbox>
            </mat-option>

            <mat-option *ngFor="let kkt of isNotAgentCrs; trackById" [value]="kkt.id">
              <mat-checkbox class="m_l-8 kkm-mat-autocomplete__checkbox d-block w-fluid h-fluid"
                            [checked]="kkt.checked"
                            (change)="selectKkt(kkt.id)"
                            (click)="$event.stopPropagation()">
                {{ kkt.id }}
              </mat-checkbox>
            </mat-option>

            <mat-option *ngIf="isAgentCrs.length" class="drop-down-bg">
              <mat-checkbox class="kkm-mat-autocomplete__checkbox d-block semi_bold w-fluid h-fluid"
                            [checked]="allAgentCrSelected"
                            (change)="selectAllAgentCrs($event.checked)"
                            (click)="$event.stopPropagation()">
                  {{t('select-agents-cr')}}
              </mat-checkbox>
            </mat-option>

            <mat-option *ngFor="let kkt of isAgentCrs; trackById" [value]="kkt.id">
              <mat-checkbox class="m_l-8 kkm-mat-autocomplete__checkbox d-block w-fluid h-fluid"
                            [checked]="kkt.checked"
                            (change)="selectKkt(kkt.id)"
                            (click)="$event.stopPropagation()">
                {{ kkt.id }}
              </mat-checkbox>
            </mat-option>

          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="filter-container__buttons d-flex m_x-0">

      <button mat-button type="submit" class="filter-container__buttons_submit kkm-button narrow fixed-height m_l-6"
              color="primary" [disabled]="!form.valid">{{t('show')}}</button>

      <button *ngIf="!isDefaultState" class="kkm-button reset-filter-button m_l-6" mat-icon-button (click)="resetFilter()">
        <mat-icon svgIcon="close:dialog-currentColor"></mat-icon>
      </button>

    </div>

  </form>
</div>
