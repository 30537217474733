import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { MapTypes } from '@common/enums';
import { IStats } from '@common/interfaces';
import { Subscription } from 'rxjs';
import { EventBusService } from '@kkm-ui/ui';


@Component({
  selector: 'kkm-taxpayers-stats',
  templateUrl: './taxpayers-stats.component.html',
  styleUrls: ['./taxpayers-stats.component.styl']
})
export class TaxpayersStatsComponent implements OnInit, OnDestroy {

  @Input() stats: IStats;
  @Input() mapType: MapTypes = MapTypes.TAXI;
  @Input() isLoading: boolean = false;

  mapTypes = MapTypes;
  statsError: boolean = false;
  statsErrorSubscription = Subscription.EMPTY;

  constructor(private eventBus: EventBusService) { }

  ngOnInit(): void {
    this.statsErrorSubscription
      = this.eventBus.on("geo-locations/stats-error", (isError: boolean) => isError && (this.statsError = isError));
  }

  ngOnDestroy() {
    this.statsErrorSubscription.unsubscribe();
  }

}
