import { IUser } from './session.interfaces';

export class User implements IUser {

  static _instance: User;

  public static getInstance(opts?: IUser): User {
    if (!this._instance) {
      this._instance = new this();
    }

    if (opts) {
      this._instance.init(opts);
    }

    return this._instance;
  }

  public static clearInstance(): void {
    this._instance = undefined;
  }

  constructor(public firstName: string = '',
              public lastName: string = '',
              public fullName: string = '',
              public middleName: string = '',
              public login: string = '',
              public email: string = '',
              public position: string = '',
              public roles: string[] = [],
              public isEmpty: boolean = true) {
  }

  private init(opts: IUser) {
    this.firstName = opts.firstName;
    this.lastName = opts.lastName;
    this.fullName = opts.fullName;
    this.middleName = opts.middleName;
    this.login = opts.login;
    this.email = opts.email;
    this.position = opts.position;
    this.roles = opts.roles;
    this.isEmpty = false;
  }
}
