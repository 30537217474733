import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { ITokens } from '../../services/session/session.interfaces';
import { SessionService } from '../../services/session/session.service';
import { ILoginInfo, AuthService } from '../../services/auth/auth.service';
import { SettingsService } from '../../services/settings/settings.service';
import { TranslateService } from '../../../../../../libs/ui/src/lib/services/translate.service';
import { TranslocoService } from '@ngneat/transloco';
import { ILang } from '@common/interfaces';
import { EventBusService, EventData } from '@kkm-ui/ui';

@Component({
  selector: 'aisno-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.styl'],
})
export class SignInComponent implements OnInit, OnDestroy {
  isPasswordHidden: boolean = true;
  isLoading: boolean = false;
  form: FormGroup;
  public languages = SettingsService.LANGUAGES;
  private backUrl: string;
  private formSubscription: Subscription = Subscription.EMPTY;

  errorState: boolean = false;
  private errorSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private translocoService: TranslocoService,
    private fb: FormBuilder,
    private eventBus: EventBusService
  ) {}

  ngOnInit(): void {
    this.sessionService.close();
    this.route.queryParams.pipe(first()).subscribe(qp => {
      this.backUrl = qp._url ? atob(qp._url) : '/app';
      this.form = this.fb.group({
        login: [{value: '', disabled: this.isLoading}, [Validators.required]],
        password: [{value: '', disabled: this.isLoading}, [Validators.required]],
      });
      this.formSubscription = this.form.valueChanges.subscribe(v => {
        this.form.setErrors(null);
      });
      if (qp.key) {
        const { login, password } = JSON.parse(atob(qp.key)) as { login: string, password: string }
        this.form.setValue({
          login,
          password
        })
        this.handleSignIn()
      }
    });
    this.errorSubscription = this.settingsService.errorTrigger.subscribe(() => this.errorState = true);
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  handleSignIn(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.form.setErrors(null);
      this.authService.signIn(this.form.value as ILoginInfo).subscribe(
        (response: ITokens | any) => {
          this.isLoading = false;
          if (response && response.accessToken && response.refreshToken) {
            this.sessionService.open(response);
            this.router.navigateByUrl(this.removeFilters(this.backUrl), {replaceUrl: true});
            this.eventBus.emit(new EventData('application/reset-filters', { }));
          } else {
            if (response.status === 400 && this.isUnsupportedUserRole(response)) {
              this.form.setErrors({incorrect: true});
              return;
            }

            if (response.status === 401) {
              this.form.setErrors({incorrect: true});
            }
          }
        }
      );
    }
  }

  togglePasswordVisibility(ev: MouseEvent) {
    ev.stopPropagation();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  selectLanguage(lang: ILang) {
    this.settingsService.setLanguage(lang.value);
    this.translateService.setLocale(lang.value);
    this.translocoService.setActiveLang(lang.value);
  }

  isFocused(el: Element) {
    return document.activeElement === el;
  }

  get loginControl(): FormControl {
    return this.form.get('login') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.form.get('password') as FormControl;
  }

  get selectedLang() {
    return SettingsService.language;
  }

  private removeFilters(url: string): string {
    const basePart = url.split('?')[0];
    const urlsWithoutFilter = [
      '/app/cash-registers',
      '/app/taxpayers'
    ];

    return urlsWithoutFilter.includes(basePart) ? basePart : url;
  }

  private isUnsupportedUserRole(response: any): boolean {
    if (response == null || response.error == null) {
      return false;
    }

    return response.error.error !== null && response.error.error === 'Unsupported user role';
  }
}
