import {Component, Input, Output, EventEmitter} from "@angular/core";
import {ILang} from '@common/interfaces';

@Component({
  selector: 'kkm-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.styl'],
})

export class LangSelectComponent {

  @Input() languages: ILang[] = [];
  @Input() default: string;

  @Output() selected: EventEmitter<ILang> = new EventEmitter<ILang>();

  get selectedLanguage(): ILang {
    return this.languages.filter(e => e.value === this.default)[0];
  }

  select(value: ILang) {
    this.selected.emit(value);
  }

}
