import {environment} from "@ais-no/env";

class UrlPrefix {
  public value: string = environment.useSSO ? environment.ssoEndpoints.urlPrefix : ''

  setClient() {
    this.value = '/identity/client'
  }

  setServerResource() {
    this.value = '/identity/resource-server'
  }
}

const urlPrefix = new UrlPrefix()

export default urlPrefix;
