import {Component, Input, OnInit} from '@angular/core';
import {translate} from "@ngneat/transloco";
import {RegHistoryRecordType} from '@common/enums';
import {ICodeName, ICrRegHistoryRecord} from '@common/interfaces';
import {TaxSystemsService} from '../../services/dictionaries/tax-systems.service';
import {BusinessObjectsDictionaryService} from '../../services/dictionaries/business-objects-dictionary.service';

@Component({
  selector: 'kkm-cr-reg-history',
  templateUrl: './cr-reg-history.component.html',
  styleUrls: ['./cr-reg-history.component.styl']
})
export class CrRegHistoryComponent implements OnInit {
  @Input() records: ICrRegHistoryRecord[];
  @Input() compact: boolean;

  recordTypes = RegHistoryRecordType;
  regHistoryExpanded = false;

  TRANSLATION_READ_PATH = 'common.components.reg-history';

  constructor(
    private taxSystemsServices: TaxSystemsService,
    private businessObjectsDictionaryService: BusinessObjectsDictionaryService,
    ) { }

  ngOnInit(): void {
  }

  regHistoryToggle() {
    this.regHistoryExpanded = !this.regHistoryExpanded;
  }

  get recordView(): ICrRegHistoryRecord[] {
    const recordMapped = []
    if (this.regHistoryExpanded) {
      for (let i = 0; i < this.records.length; i++) {
        const record = this.records[i];
        const recordType = record.type;
        const prevRecord = i !== (this.records.length - 1) ? this.records[i+1] : null;
        const placeWasChanged = prevRecord && this.records[i].purchasePlace !== this.records[i+1].purchasePlace;

        if (recordType === RegHistoryRecordType.registration) {
          record.reason = this.translate('reasons.registration');
          record.viewAddress = record.purchasePlace + ', ' + record.purchaseAddress;
          record.viewTaxSystems = this.formattedTaxSystems(record.taxSystems);
          record.viewCalcItemChanged = null;
        }
        else if (recordType === RegHistoryRecordType.closeFn) {
          record.reason = this.translate('reasons.deregistered');
          record.viewAddress = '-';
          record.viewTaxSystems = null;
          record.viewCalcItemChanged = null;
        }
        else if (recordType === RegHistoryRecordType.changeAddressOrTaxSystem) {
          if (record.changeReason?.includes(1)) {

            const vatPayerWasChanged = prevRecord && this.records[i].vatPayer !== prevRecord.vatPayer
            const taxSystemsWasChanged = prevRecord && this.records[i].taxSystems.toString() !== prevRecord.taxSystems.toString();
            const calcTypeWasChanged = prevRecord && this.records[i].calcItemAttributes.toString() !== prevRecord.calcItemAttributes.toString();

            const entrepreneurshipWasChanged = prevRecord && this.records[i].entrepreneurshipObject !== prevRecord.entrepreneurshipObject;
            const businessActivityWasChanged = prevRecord && this.records[i].businessActivity !== prevRecord.businessActivity;
            const taxAuthorityWasChanged = prevRecord && this.records[i].taxAuthorityDepartment !== prevRecord.taxAuthorityDepartment;

            record.reason = this.translate('reasons.tax-system-was-changed');
            record.viewAddress = null;
            record.viewTaxSystems = vatPayerWasChanged || taxSystemsWasChanged ? this.formattedTaxSystems(record.taxSystems) : null;
            record.viewVatPayer = vatPayerWasChanged || taxSystemsWasChanged ? this.formattedVatPayer(record.vatPayer) : null;
            record.viewCalcItemChanged = calcTypeWasChanged ? this.translate('item-type-were-canged') : null;

            record.viewEntrepreneurshipObject = entrepreneurshipWasChanged ? this.displayBusinessObjectType(record.entrepreneurshipObject) : null;
            record.viewBusinessActivity = businessActivityWasChanged ? this.displayBusinessType(record.businessActivity) : null;
            record.viewTaxAuthorityDepartment = taxAuthorityWasChanged ? this.displayStateTaxAdministration(record.taxAuthorityDepartment) : null;

          } else if (record.changeReason?.includes(0)) {
            record.reason = this.translate('reasons.address-was-changed');
            record.viewAddress = record.purchasePlace + ', ' + record.purchaseAddress;
            record.viewTaxSystems = null;
            record.viewCalcItemChanged = null;
          } else {
            record.reason = this.translate('reasons.tax-system-was-changed');
            record.viewAddress = record.purchasePlace + ', ' + record.purchaseAddress;
            record.viewTaxSystems = this.formattedTaxSystems(record.taxSystems);
            record.viewVatPayer = this.formattedVatPayer(record.vatPayer);
            record.viewCalcItemChanged = null;
          }
        }
        else if (placeWasChanged) {
          record.reason = this.translate('reasons.place-was-changed');
          record.viewAddress = record.purchasePlace;
          record.viewTaxSystems = null;
          record.viewCalcItemChanged = null;
        }
        recordMapped.push(record);
      }
    }
    return recordMapped;
  }

  private translate(key: string): string {
    return translate(`${this.TRANSLATION_READ_PATH}.${key}`);
  }

  private formattedTaxSystems(taxSystems: number[]): string {
    let taxNames = '';
    if (taxSystems) {
      for (let i = 0; i < taxSystems.length; i++) {
        taxNames += this.taxSystems.find(t => t.code === taxSystems[i]).taxName
                  + (((i + 1) === taxSystems.length) ? '' : ', ');
      }
    }

    return taxNames;
  }

  private displayBusinessObjectType(code: number): string {
    return this.getDisplayNameByCode(code, this.businessObjectsDictionaryService.entrepreneurshipObjects);
  }

  private displayBusinessType(code: number): string {
    return this.getDisplayNameByCode(code, this.businessObjectsDictionaryService.businessActivities);
  }

  private displayStateTaxAdministration(code: number): string {
    return this.getDisplayNameByCode(code, this.businessObjectsDictionaryService.taxAuthorityDepartments);
  }

  private getDisplayNameByCode(code: number, dictionary: ICodeName[]): string {
    if (code == null) {
      return '';
    }

    const result = dictionary.find((codes: ICodeName) => codes.code === code);
    return result == null ? '' : result.name;
  }

  private formattedVatPayer(vatPayer: boolean): string {
    return this.translate(vatPayer ? 'yes' : 'no');
  }

  get taxSystems() {
    return this.taxSystemsServices.taxSystems;
  }
}
