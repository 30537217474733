import urlPrefix from "./apiPrefix";

export const putUpdateCashRegister = () => `${urlPrefix.value}/tax-web-control/service-api/update/cash-register`;
export const putDisableFNState = (fn: string, state: string) => `${urlPrefix.value}/tax-web-control/service-api/disable/fn/${fn}/state/${state}`;
export const postTicketWithCustomerContacts = () => `${urlPrefix.value}/tax-web-control/service-api/ticket/withCustomerContacts`;
export const postRegisterLegalPerson = () => `${urlPrefix.value}/tax-web-control/service-api/register/legal-person`;
export const postRegisterCashRegister = () => `${urlPrefix.value}/tax-web-control/service-api/register/cash-register`;
export const postMotivationCheckToken = () => `${urlPrefix.value}/tax-web-control/service-api/motivation/checkToken`;
export const postFNAdd = () => `${urlPrefix.value}/tax-web-control/service-api/fn/add`;
export const postFNAddNotExists = () => `${urlPrefix.value}/tax-web-control/service-api/fn/addNotExists`;
export const postFNAddNumber = (fn: string) => `${urlPrefix.value}/tax-web-control/service-api/fn/add/${fn}`;
export const postCustomerRegister = () => `${urlPrefix.value}/tax-web-control/service-api/customer/register`;
export const postBaseCatalogue = () => `${urlPrefix.value}/tax-web-control/service-api/base-catalogue`;
export const postRefresh = () => `${urlPrefix.value}/tax-web-control/refresh`;
export const postLogin = () => `${urlPrefix.value}/tax-web-control/login`;
export const getStatusCashRegisterFM = (fm: string) => `${urlPrefix.value}/tax-web-control/service-api/status/cash-register/fm/${fm}`;
export const getLegalPerson = (tin: string) => `${urlPrefix.value}/tax-web-control/service-api/legal-person/${tin}`;
export const getFNCreate = () => `${urlPrefix.value}/tax-web-control/service-api/fn/create`;
export const getCashRegisterFullData = (fm: string, hash: string) => `${urlPrefix.value}/tax-web-control/service-api/cash-register/fulldata/fm/${fm}/hash/${hash}`;
export const getBaseCatalogueRegNumber = (regNumber: string) => `${urlPrefix.value}/tax-web-control/service-api/base-catalogue/reg-number/${regNumber}`;
export const getMe = () => `${urlPrefix.value}/tax-web-control/me`;
