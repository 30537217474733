import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {AnalyticsSummaryTypes, SpecialErrors} from '@common/enums';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ICrList, IReceipt} from '@common/interfaces';
import moment from 'moment';
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DateRangeViewModel} from "./models/DateRangeViewModel";
import {format} from 'date-fns';
import {onlyFloatNumber} from '@kkm-ui/utils/validation';
import {EventBusService} from '../../../services/event-bus/event-bus.service';

interface ISearchFilter {
  fm?: string;
  fd?: string;
}

interface IPaginatorState {
  label: string;
  hasNext: boolean;
  hasPrev: boolean;
}

interface IOnAfterLoad {
  paginatorState: IPaginatorState,
  areFiltersApplied: boolean;
  clearSearchFilter: boolean;
}

type SummaryTypeModel = {
  id: number,
  type: AnalyticsSummaryTypes,
  text: string
}

const defaultPaginatorState = {
  label: '-',
  hasPrev: false,
  hasNext: false,
};

@Component({
  selector: 'kkm-summary-info',
  templateUrl: './summary-info.component.html',
  styleUrls: ['./summary-info.component.styl']
})
export class SummaryInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() taxpayerId: string | number;
  @Input() taxpayer?: any;
  @Input() mainFilter;
  @Input() cashRegisters: ICrList[];
  @Input() agentCashRegisters: ICrList[];
  @Input() isShowFdNumber?: boolean;

  @ViewChild('end') endInput: ElementRef;

  summaryTypesMap: SummaryTypeModel[] = [];
  selectedSummaryType: SummaryTypeModel = null;
  summaryTypes = AnalyticsSummaryTypes;
  form: FormGroup;
  dateRange: FormGroup;
  searchFilter: ISearchFilter = {};
  calendarFilter = {};
  showFilters: boolean;
  areFiltersApplied: boolean;
  appliedFiltersCount = 0;
  currentPage = 1;
  paginatorState: IPaginatorState = defaultPaginatorState;
  selectedReceiptId: string;
  minDate: Date;
  maxDate: Date;
  color: string;
  cashRegistersDateRangeViewString: string;
  loading: boolean = false;
  isFDFilterApplied: boolean = false;
  isFMFilterApplied: boolean = false;
  showFailedToLoadData: boolean = false;
  scrollSubject: Subject<void> = new Subject<void>();

  onlyFloatNumber = onlyFloatNumber;

  private destroy$ = new Subject<boolean>();
  private dateRangeChangeSubscription = new Subscription();
  private specialErrorSubscription: Subscription = Subscription.EMPTY;

  constructor(
    @Inject('env') private env,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private eventBus: EventBusService,
  ) {
    this.color = env?.defaults?.mainColor;
  }

  get startControl() {
    return this.dateRange.get('start');
  }

  get endControl() {
    return this.dateRange.get('end');
  }

  get rangeValid() {
    return (this.startControl.touched && this.startControl.dirty
      && this.endControl.touched && this.endControl.dirty && this.isNotValue)
      && (this.startControl.invalid || this.endControl.invalid);
  }

  get isNotValue() {
    return this.endInput != null && this.endInput.nativeElement.value.substring(1, 2) !== "_"; // to do
  }

  ngOnInit() {
    if (this.cashRegisters != null && this.cashRegisters.length > 0) {
      this.summaryTypesMap.push(
        {id: 1, type: AnalyticsSummaryTypes.BY_RECEIPTS, text: 'receipts'},
        {id: 3, type: AnalyticsSummaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS, text: 'receipts-with-critical-errors'},
        {id: 4, type: AnalyticsSummaryTypes.BY_CASH_REGISTERS, text: 'cash-registers'}
      );
    }

    if (this.agentCashRegisters != null && this.agentCashRegisters.length > 0) {
      this.summaryTypesMap.push(
        {id: 2, type: AnalyticsSummaryTypes.BY_AGENT_RECEIPTS, text: 'agent-receipts'}
      );
    }

    this.summaryTypesMap = this.summaryTypesMap.sort((a, b) => a.id - b.id);
    this.selectedSummaryType = this.summaryTypesMap.length ? this.summaryTypesMap[0] : null;

    this.form = this.fb.group({
      fd: [''],
      fm: [''],
    });
    this.dateRange = this.fb.group({
      start: [''],
      end: [''],
    });

    this.specialErrorSubscription = this.eventBus
      .on('special-errors/loading-error', (specialError: SpecialErrors) => this.processSpecialError(specialError));

    this.dateRangeChangeSubscription = this.dateRange.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes: { start: any, end: any }) => {
        this.cashRegistersDateRangeViewString = (new DateRangeViewModel(changes.start, changes.end)).toViewString();
      });

    this.setDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {mainFilter} = changes;
    if (mainFilter && !mainFilter.firstChange) {
      this.clearFilter();
      this.setDate();
      this.checkDates();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setDate() {
    const {from, to} = this.mainFilter;
    this.minDate = new Date(from);
    this.maxDate = new Date(to);
    this.dateRange.setValue({
      start: from,
      end: to,
    });
  }

  toggleFilter(ev: MouseEvent): void {
    this.showFilters = !this.showFilters;
  }

  setFilter() {
    this.searchFilter = this.form.value;
    this.isFDFilterApplied = this.form.get('fd').value != null && this.form.get('fd').value.trim() !== '';
    this.isFMFilterApplied = this.form.get('fm').value != null && this.form.get('fm').value.trim() !== '';
  }

  setSummaryType(summaryModel: SummaryTypeModel) {
    this.selectedSummaryType = summaryModel;
    this.clearFilter();
  }

  clearFilter() {
    this.searchFilter = {};
    this.calendarFilter = {};
    this.form.setValue({fd: '', fm: ''});
    this.setDate();
    this.isFDFilterApplied = false;
    this.isFMFilterApplied = false;
    this.setPaginatorState(defaultPaginatorState);
  }

  clearFormField(ev: MouseEvent, name: string) {
    ev.stopPropagation();
    this.form.get(name)?.setValue('');
    this.setFilter();
  }

  onAfterLoad(obj: IOnAfterLoad): void {
    const {paginatorState, areFiltersApplied, clearSearchFilter} = obj;
    this.areFiltersApplied = areFiltersApplied;
    if (clearSearchFilter) {
      this.clearFilter();
    }
    this.setPaginatorState(paginatorState);
  }

  setPaginatorState(paginatorState: IPaginatorState): void {
    this.loading = false;
    this.paginatorState = {...this.paginatorState, ...paginatorState};
    this.ref.detectChanges();
  }

  setCurrentPage(num: number): void {
    if (num > 0 && !this.loading) {
      this.loading = true;
      this.currentPage = num;
    }

    this.scrollSubject.next();
  }

  setAppliedFiltersCount(count: number): void {
    this.appliedFiltersCount = count;
  }

  openReceipt(receipt: IReceipt): void {
    this.selectedReceiptId = receipt.id;
  }

  closeReceipt(ev: MouseEvent): void {
    this.selectedReceiptId = '';
  }

  checkDates(): void {
    const start = this.dateRange.get('start');
    const end = this.dateRange.get('end');
    if (!!start.value && !end.value) {
      end.setValue(start.value);
    }
    this.calendarFilter = {
      from: moment(start.value).format('YYYY-MM-DD'),
      to: moment(end.value).format('YYYY-MM-DD'),
    }
  }

  clearDates(ev: MouseEvent) {
    ev.stopPropagation();
    this.setDate();
    this.checkDates();
  }

  stringDate(date) {
    return format(date, 'YYYY-MM-DD');
  }

  get isReceiptsGrid() {
    return this.selectedSummaryType.type === this.summaryTypes.BY_RECEIPTS
      || this.selectedSummaryType.type === this.summaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS
      || this.selectedSummaryType.type === this.summaryTypes.BY_AGENT_RECEIPTS;
  }

  private processSpecialError(specialError: SpecialErrors): void {
    switch (specialError) {
      case SpecialErrors.LastVouchersLoadingError:
        this.showFailedToLoadData = true;
        break;
    }
  }
}
