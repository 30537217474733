export const setAPILanguage = (lang: string, apiKey: string, usePlacesLibrary: boolean = false) => {
  document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
    script.remove();
  });

  try {
    if (google) delete google.maps;
  } catch { }

  let newAPI = document.createElement('script');
  newAPI.src = 'https://maps.googleapis.com/maps/api/js?libraries=geometry&key='
    + apiKey
    + '&language='
    + lang
    + '&callback=googleMapsAPILoaded'
    + (usePlacesLibrary ? '&libraries=places' : '');

  (window as any).googleMapsAPILoaded = () => {
    let event = new CustomEvent('googleMapsAPILoaded');
    window.dispatchEvent(event);
  }

  let apiLoaded = new Promise(resolve => {
    window.addEventListener('googleMapsAPILoaded', () => {
      resolve(null);
    });
  });

  document.querySelector('head').appendChild(newAPI);

  return apiLoaded;
}
