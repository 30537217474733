<ng-container *transloco="let t, read: 'common.components.user-menu'">
  <div class="user-menu-inner" [matMenuTriggerFor]="userMatMenu">
    <div class="account-info flex flex-row justify-content-between align-items-center">
      <p class="grid_header flex-grow-0 flex-shrink-1 m_r-2">{{username}}</p>
      <div class="kkm-select-arrow flex-grow-0 flex-shrink-0"></div>
    </div>
    <ng-container *ngIf="!!username && useAvatarOnMobile">
      <div class="avatar small_text">
        <span [textContent]="username.substr(0,1)"></span>
      </div>
    </ng-container>
  </div>
  <div>
    <mat-menu #userMatMenu backdropClass="kkm-mat-menu" xPosition="before">
      <div *ngIf="hasEmail" class="mat-menu-item mat-menu-item__email" [textContent]="email"></div>

      <button (click)="logout.emit()" mat-menu-item>
        <mat-icon class="brand" svgIcon="action:logout"></mat-icon>
        <span class="body_text" [textContent]="t('logout')"></span>
      </button>
    </mat-menu>
  </div>
</ng-container>
