import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[clearIfCorrect]'
})
export class ClearIfCorrectDirective {

  constructor(private control: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event): void {

    const control = this.control.control

    if (!control.value) {
      control.markAsPristine();
    } else {
      if (control.hasError('maxlength')) {
        control.markAsTouched();
      } else {
        control.markAsUntouched();
      }
    }
    
    if (control.hasError('incorrect')) {
      delete control.errors['incorrect'];
      control.updateValueAndValidity();
    }

  }
}
