<div class="app-header-container" *transloco="let t, read: 'app.header'">
  <ng-content></ng-content>
  <div class="d-flex align-items-center p_t-4">

    <kkm-user-menu [username]="user?.fullName"
                   [email]="user.email"
                   (logout)="logout()"></kkm-user-menu>

    <kkm-lang-select [default]="selectedLang"
                     [languages]="languages"
                     (selected)="selectLanguage($event)"></kkm-lang-select>
  </div>
</div>
