<ng-container *ngIf="!errorState; else isError">
  <div class="login_page-container page-sign-in__wrapper h-fluid w-fluid d-flex align-items-start" *transloco="let t, read: 'page.sign-in'">
    <div class="page-sign-in__inner d-flex justify-content-between align-items-start">
      <div class="page-sign-in__side page-sign-in__left-side align-center">

        <!-- HEADERS -->
        <div class="page_sign_in__title m_b-8">
          <h1 class="decor-title white_text text-center m_b-2" [textContent]="t('title')"></h1>
          <h2 class="white_text text-center" [textContent]="t('subtitle')"></h2>
        </div>

        <form class="page-sign-in__auth-form" [formGroup]="form" (ngSubmit)="handleSignIn()" focusFirstInvalid>

          <!-- LANGUAGE SWITCHER -->
          <div class="d-flex justify-content-between align-items-center m_b-6">
            <span class="body_text" [textContent]="t('login-msg')"></span>
            <kkm-lang-select [default]="selectedLang" [languages]="languages" (selected)="selectLanguage($event)"></kkm-lang-select>
          </div>

          <!-- ERROR -->
          <p *ngIf="form.getError('incorrect')" [textContent]="t('form.errors.incorrect')" class="fail-area w-fluid align-left m_b-6"></p>

          <!-- FORM FIELDS -->
          <ng-container *transloco="let f, read: 'page.sign-in.form.fields'">
            <div class="form-item">
              <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [formGroup]="form">
                <mat-label>{{f('login.label')}}</mat-label>
                <input #loginInput matInput type="text" autocomplete="off" formControlName="login" clearIfCorrect>
              </mat-form-field>
              <kkm-input-popover *ngIf="isFocused(loginInput) && loginControl.dirty && loginControl.touched && loginControl.invalid"
                [text]="f('login.required-error')">
              </kkm-input-popover>
            </div>

            <div class="form-item">
              <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [formGroup]="form" [class.eye-button_visible-by-input]="passwordControl.value || isFocused(passwordInput)">
                <mat-label>{{f('password.label')}}</mat-label>
                <input #passwordInput matInput [type]="isPasswordHidden ? 'password' : 'text'" autocomplete="off"
                      formControlName="password" clearIfCorrect>
                <button type="button" class="kkm-button eye-button eye-button_visible-by-hover" mat-icon-button matSuffix
                        (click)="togglePasswordVisibility($event)">
                  <mat-icon [svgIcon]="isPasswordHidden ? 'eye:on' : 'eye:off'"></mat-icon>
                </button>
              </mat-form-field>
              <kkm-input-popover *ngIf="isFocused(passwordInput) && passwordControl.dirty && passwordControl.touched && passwordControl.invalid"
                [text]="f('password.required-error')">
              </kkm-input-popover>
            </div>
          </ng-container>

          <div class="w-fluid m_b-6">
            <button
              type="submit"
              class="kkm-button w-fluid submit-button"
              mat-button
              color="primary"
              [class.kkm-button_loading]="isLoading"
              [disabled]="isLoading">
              <span *ngIf="!isLoading">{{t('form.buttons.submit')}}</span>
            </button>
          </div>
        </form>
      </div>
      <div class="page-sign-in__side page-sign-in__right-side"></div>
    </div>

  </div>
</ng-container>
<ng-template #isError>
  <kkm-aisno-server-error-screen [showHeader]="false"></kkm-aisno-server-error-screen>
</ng-template>
