import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { IQuarter, QUARTERS } from '../kkm-datepicker.interfaces';

@Component({
  selector: 'kkm-quarter-view',
  templateUrl: './kkm-quarter-view.component.html',
  styleUrls: ['./kkm-quarter-view.component.styl']
})
export class KkmQuarterViewComponent<D> {
  quarters: IQuarter[] = [];

  @Input() activeDate;

  @Output() readonly quarterSelected: EventEmitter<D> = new EventEmitter<D>();
  @Output() readonly activeDateChange: EventEmitter<D> = new EventEmitter<D>();

  constructor(@Optional() public _dateAdapter: DateAdapter<D>,) {
    this.quarters = QUARTERS;
  }

  isQuarterSelected(quarter: IQuarter): boolean {
    const month = this._dateAdapter.getMonth(this.activeDate);
    return quarter.startMonth <= month && month <= quarter.endMonth;
  }

  selectQuarter(quarter: IQuarter): void {
    const year = this._dateAdapter.getYear(this.activeDate);
    const month = quarter.startMonth;
    this.activeDate = this._dateAdapter.createDate(year, month, 1);

    this.quarterSelected.emit(this.activeDate);
    this.activeDateChange.emit(this.activeDate);
  }

  quarterIdentity(index: number, item: IQuarter): string {
    return item.quarter;
  }
}
