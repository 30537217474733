import urlPrefix from "./apiPrefix";

export const getLkShiftVouchers = (shiftId: string) => `${urlPrefix.value}/tax-web-control/api/lk/shift/${shiftId}/vouchers`;
export const postLkShiftVouchers = (shiftId: string) => `${urlPrefix.value}/tax-web-control/api/lk/shift/${shiftId}/vouchers`;
export const getLkLegalPerson = () => `${urlPrefix.value}/tax-web-control/api/lk/legal-person`;
export const postLkLegalPerson = () => `${urlPrefix.value}/tax-web-control/api/lk/legal-person`;
export const postLkLegalPersonTop = () => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/top`;
export const getLkCashRegisterStats = (from: string, to: string) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/stats/from/${from}/to/${to}`;
export const postLkCashRegisterStats = (from: string, to: string) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/stats/from/${from}/to/${to}`;
export const getLkVoucher = (id: string) => `${urlPrefix.value}/tax-web-control/api/lk/voucher/${id}`;
export const getLkVoucherRegnumberFd = (regnumber: string, fd: number) => `${urlPrefix.value}/tax-web-control/api/lk/voucher/regnumber/${regnumber}/fd/${fd}`;
export const getLkCashRegisterByIdV2 = (id: number) => `${urlPrefix.value}/tax-web-control/api/lk/v2/cash-register/${id}`;
export const getLkCashRegisterRegnumber = (number: string) => `${urlPrefix.value}/tax-web-control/api/lk/v2/cash-register/regnumber/${number}`;
export const getLkShifts = () => `${urlPrefix.value}/tax-web-control/api/lk/shifts`;
export const getLkShiftsRegnum = (cashRegisterNumber: string) => `${urlPrefix.value}/tax-web-control/api/lk/shifts/regnum/${cashRegisterNumber}`;
export const getLkShiftsGrouped = () => `${urlPrefix.value}/tax-web-control/api/lk/shifts/grouped`;
export const getLkReportsRegnumber = (regNumber: string) => `${urlPrefix.value}/tax-web-control/api/lk/reports/regnumber/${regNumber}`;
export const getLkReportsFn = (fn: string) => `${urlPrefix.value}/tax-web-control/api/lk/reports/fn/${fn}`;
export const getLkLocations = () => `${urlPrefix.value}/tax-web-control/api/lk/locations`;
export const getLkLegalPersonById = (lpId: number | string) => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/${lpId}`;
export const getLkLegalPersonTaxSystems = (lpId: number | string) => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/${lpId}/tax-systems`;
export const getLkLegalPersonLocations = (lpId: number | string) => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/${lpId}/locations`;
export const getLkLegalPersonAgentCashRegisters = (lpId: number) => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/${lpId}/agent-cash-registers`;
export const getLkLegalPersonByTin = (tin: string) => `${urlPrefix.value}/tax-web-control/api/lk/legal-person/tin/${tin}`;
export const getLkClusters = () => `${urlPrefix.value}/tax-web-control/api/lk/clusters`;
export const getLkCashRegisterById = (id: number) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/${id}`;
export const getLkCashRegisterStatsYear = (year: any) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/stats/year/${year}`;
export const getLkCashRegisterStatsYearQuarter = (year: any, quarter: `Q1` | `Q2` | `Q3` | `Q4`) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/stats/year/${year}/quarter/${quarter}`;
export const getLkCashRegisterStatsMonth = (month: any) => `${urlPrefix.value}/tax-web-control/api/lk/cash-register/stats/month/${month}`;
