<ng-container *transloco="let t; read: TRANSLATION_READ_PATH">
  <div *ngIf="hasReceipts || isSkeletonLoading"
       class="position-relative"
       [class.kkm-receipt-grid]="analyticsSummaryTypes.BY_RECEIPTS === type"
       [ngClass]="additionalClass">
    <div *ngIf="isLoading" class="preloader-shade">
      <div class="preloader middle table-preloader"></div>
    </div>

    <mat-table [dataSource]="isSkeletonLoading ? mockSource : dataSource" matSort
               class="kkm-table w-fluid position-relative"
               [class.with-filter]="isFilterActive"
               [class.by-receipts]="analyticsSummaryTypes.BY_RECEIPTS === type"
               [class.by-cash-registers]="analyticsSummaryTypes.BY_CASH_REGISTERS === type">

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef>{{t('time')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container *ngIf="!isSkeletonLoading">
            <div class="d-flex align-items-center">
              <div class="receipt-type" [class]="receipt.iconClass"></div>
              <span class="receipt-date">
                <kkm-date [date]="receipt.dateTime" format="mediumTime"></kkm-date>
              </span>
            </div>
            <mat-icon *ngIf="receipt.hasErrors"
                      [svgIcon]="receipt.errorIcon"
                      class="receipt-error m_l-3"
                      [matTooltip]="t(receipt.errorTooltipCaption, {errorsCodes: receipt.errorCodes})"
                      matTooltipPosition="above"
                      matTooltipClass="kkm-table-tooltip-above tooltip-agency-scheme"></mat-icon>
          </ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taxpayer">
        <mat-header-cell *matHeaderCellDef>{{t('taxpayer')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container></ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cashRegisterNumber">
        <mat-header-cell *matHeaderCellDef>{{t('cashRegister')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container *ngIf="!isSkeletonLoading">{{receipt.crRegisterNumber}}</ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shiftNumber">
        <mat-header-cell *matHeaderCellDef>{{t('shiftNumber')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container *ngIf="!isSkeletonLoading">{{receipt.crData.shiftNumber}}</ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fdNumber">
        <mat-header-cell *matHeaderCellDef>{{t('fiscalDocument')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container *ngIf="!isSkeletonLoading">
            <!-- номер ФД скрыть везде, кроме ЛК НП -->
            <p>{{isShowFdNumber ? receipt.fdNumber : '–'}}</p>
            <mat-icon *ngIf="isAgentReceipt(receipt)"
                      class="m_l-2"
                      [svgIcon]="isSupplierReceipt(receipt) ? 'common:agent-mark-currentColor' : 'common:agent-mark-p'"
                      [matTooltip]="t(isSupplierReceipt(receipt) ? 'agency-scheme-agent' : 'agency-scheme')"
                      matTooltipPosition="above"
                      matTooltipClass="kkm-table-tooltip-above tooltip-agency-scheme"></mat-icon>
          </ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="credit">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">{{t('credit')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null" class="justify-content-end">
          <ng-container *ngIf="receipt.ticketPostPaymentSum !== 0 && !isSkeletonLoading">
            <kkm-money [amount]="receipt.ticketPostPaymentSum"></kkm-money>
          </ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="advance">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">{{t('advance')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null" class="justify-content-end">
          <ng-container *ngIf="receipt.ticketPrePaymentSum !== 0 && !isSkeletonLoading">
            <kkm-money [amount]="receipt.ticketPrePaymentSum"></kkm-money>
          </ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ticketTotalSum">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          <span class="mr-5">{{t('total')}}</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let receipt" [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null" class="justify-content-end">
          <ng-container *ngIf="!isSkeletonLoading">
            <span class="mr-5">
              <kkm-money [amount]='receipt.ticketTotalSum || 0'></kkm-money>
            </span>
          </ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="operationType">
        <mat-header-cell *matHeaderCellDef class="kkm-table__calc-cell">{{t('type')}}</mat-header-cell>
        <mat-cell *matCellDef="let receipt" class="kkm-table__calc-cell"
                  [title]="receipt.receiptType ? (t(receipt.receiptType) + ' / ' + t(receipt.receiptOperationType)) : null">
          <ng-container *ngIf="!isSkeletonLoading">{{ t(receipt.operationTypeCaption) }}</ng-container>
          <kkm-skeleton *ngIf="isSkeletonLoading" size="20px" style="width: 100%;"></kkm-skeleton>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns; sticky: analyticsSummaryTypes.BY_RECEIPTS === type"></mat-header-row>
      <div>
        <mat-row *matRowDef="let row; columns: columns;"
                 (click)="selectReceipt($event, row)"
                 [class.isSelected]="row.id === selectedReceiptId"></mat-row>
      </div>
    </mat-table>
  </div>
  <kkm-no-data-label *ngIf="!hasReceipts && !isSkeletonLoading" class="body_text" [text]="t('noReceiptsFound')"></kkm-no-data-label>
</ng-container>
