import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "../../services/translate.service";
import { TranslocoService } from '@ngneat/transloco';
import { DEFAULT_LANG, LANG_LIST } from '@common/constants';

@Component({
  selector: 'kkm-date',
  template: `<span>{{date | date:format:null:locale}}</span>`,
  styleUrls: ['./date.component.styl']
})

export class DateComponent implements OnInit, OnDestroy {

  @Input() date: Date | string;
  @Input() format: string;
  locale: string = DEFAULT_LANG.langCode;

  subscription: Subscription;

  constructor(private translate: TranslateService,
              private translocoService: TranslocoService) { }

  public ngOnInit() {
    this.subscription = this.translate.state.subscribe(locale => this.locale = locale);
    this.locale = this.getLocaleByLang(this.translocoService.getActiveLang());
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getLocaleByLang(lang: string): string {
    const locale = LANG_LIST.find((lng) => lng.value === lang);
    return locale ? locale.langCode : DEFAULT_LANG.langCode;
  }
}
