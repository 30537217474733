<div class="taxi-stats p_x-8" *transloco="let t, read: 'common.components.taxpayers-stats'">
  <ng-container *ngIf="mapType !== mapTypes.NOTARIES">
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('taxpayers')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.dayTaxPayers || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.taxPayers || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.total')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t(mapType === mapTypes.TAXI ? 'taxis' : 'cash-registers')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <div class="d-flex align-items-center">
            <div>{{statsError ? '–' : (stats?.dayFiscalRegisters || 0) | spaces}}</div>
            <div *ngIf="!statsError" class="status-icon m_l-2"></div>
          </div>
          <kkm-input-popover [text]="t('tips.online-per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <div>{{statsError ? '–' : (stats?.fiscalRegisters || 0) | spaces}}</div>
          <kkm-input-popover [text]="t('tips.total')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t(mapType === mapTypes.TAXI ? 'trips' : 'receipts')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.dayTickets || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.tickets || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('revenue')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.dayTotal || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.total || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('vat')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.dayVat || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.vat || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('st')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.daySt || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.st || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <!-- <div class="taxi-stats__item">
        <div class="muted_text small_text">{{t('patent')}}</div>
        <div class="taxi-stats__item_val">1 694 788</div>
        <div class="taxi-stats__item_subval body_text">567 456 567</div>
    </div>
    <div class="taxi-stats__item">
        <div class="muted_text small_text">{{t('nrp')}}</div>
        <div class="taxi-stats__item_val">445 800</div>
        <div class="taxi-stats__item_subval body_text">87 503 353</div>
    </div>
    <div class="taxi-stats__item">
        <div class="muted_text small_text">{{t('single-tax')}}</div>
        <div class="taxi-stats__item_val">394 788</div>
        <div class="taxi-stats__item_subval body_text">82 123 870</div>
    </div> -->
  </ng-container>
  <ng-container *ngIf="mapType === mapTypes.NOTARIES">
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('notaries')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.dayTaxPayers || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span>{{statsError ? '–' : (stats?.taxPayers || 0) | spaces}}</span>
          <kkm-input-popover [text]="t('tips.total')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('revenue')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.dayRevenue || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.revenue || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('fee')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.dayGovernmentFee || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.governmentFee || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('vat')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.dayVat || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.vat || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
    <div class="taxi-stats__item">
      <div class="muted_text small_text">{{t('st')}}</div>
      <div class="taxi-stats__item_val kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.daySt || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-day')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="24px"></kkm-skeleton>
      </div>
      <div class="taxi-stats__item_subval small_text kkm-input-popover_hover-delay">
        <div *ngIf="!isLoading">
          <span *ngIf="statsError">–</span>
          <kkm-money *ngIf="!statsError" [amount]="stats?.st || 0" [round]="true"></kkm-money>
          <kkm-input-popover [text]="t('tips.per-year')" position="bottom"></kkm-input-popover>
        </div>
        <kkm-skeleton *ngIf="isLoading" width="130px" size="20px"></kkm-skeleton>
      </div>
    </div>
  </ng-container>
</div>
