<div role="group" class="kkm-input-number__container"
     [formGroup]="numberForm"
     [attr.aria-labelledby]="_formField?._labelId"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input #numberInput matInput
         [imask]="numberMask"
         [unmask]="true"
         type="text"
         autocomplete="off"
         formControlName="numberInput"
         (input)="handleInput()"
         (keydown.arrowUp)="increase()"
         (keydown.arrowDown)="decrease()">
  <div matSuffix class="d-flex flex-column">
    <mat-icon svgIcon="common:spin-up" class="small"
              [class.kkm-input-number__icon_disabled]="disabled"
              (mousedown)="mouseDown(increaseCallback)"
              (mouseup)="mouseUp()"></mat-icon>

    <mat-icon svgIcon="common:spin-down" class="small"
              [class.kkm-input-number__icon_disabled]="disabled"
              (mousedown)="mouseDown(decreaseCallback)"
              (mouseup)="mouseUp()"></mat-icon>
  </div>
</div>
