<div class="container-fluid line-chart-container">
  <div class="row line-chart-wrapper">
    <div *ngIf="isLoading" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>

    <div class="col-9 line-chart-canvas">
      <ng-container *transloco="let g; read: 'common.components.sales-analytics.revenue-chart'">
        <h2 class="chart-header">{{g('chartTitle', { period: chartTitle })}}</h2>
      </ng-container>
      <canvas #chart [id]="CANVAS_ID" height="432"></canvas>
    </div>
    <div class="col-3 overall-info-container" *transloco="let t; read: 'common.components.sales-analytics.revenue-chart'">
      <div class="overall-info-item">
        <div class="overall-title small_text">{{t('revenue')}}</div>
        <div class="overall-text"><kkm-money [amount]="totalRevenue" [round]="true"></kkm-money></div>
      </div>
      <div *ngIf="hasNotaryOrLawyerCashRegister" class="overall-info-item">
        <div class="overall-title small_text">{{t('government-fee')}}</div>
        <div class="overall-text"><kkm-money [amount]="totalGovernmentFee" [round]="true"></kkm-money></div>
      </div>
      <div class="overall-info-item">
        <div class="overall-title small_text">{{t('st')}}</div>
        <div class="overall-text"><kkm-money [amount]="totalSt" [round]="true"></kkm-money></div>
      </div>
      <div class="overall-info-item">
        <div class="overall-title small_text">{{t('vat')}}</div>
        <div class="overall-text"><kkm-money [amount]="totalVat" [round]="true"></kkm-money></div>
      </div>
      <div class="overall-info-item">
        <div class="overall-title small_text">{{t(isYaTaxiPayer ? 'receipts-taxi' : 'receipts')}}</div>
        <div class="overall-text">{{totalReceipts}}</div>
      </div>
      <div class="overall-info-item">
        <div class="overall-title small_text">{{t('averageReceipt')}}</div>
        <div class="overall-text"><kkm-money [amount]="averageReceipt" [round]="true"></kkm-money></div>
      </div>
    </div>
  </div>
</div>
