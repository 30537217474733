import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, first } from 'rxjs/operators';
import { AnalyticsDateFilterTypes } from '@common/enums';
import { getDefaultStatsBetweenDates, getQuarterBoundaryDates, getMonthBoundaryDates, getYearBoundaryDates, getOverallStatistics } from '@kkm-ui/utils/index';
import { ITaxpayer, IStore, IReceipt, IReceiptList, ITicketStatisticsApiResponse, ITicketStatistics, IReceiptStatistics, IBoundaryDates } from '@common/interfaces';
import { format } from 'date-fns';
import { sumBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StatService {
  COMMON_ENDPOINT = '';

constructor(
  @Inject('env') private env,
  private http: HttpClient,
) {
  this.COMMON_ENDPOINT = env?.shared?.services?.stat?.commonEndpoint || '';
}

  getStatFilter(taxpayerId: string, options: any, isAgents: boolean) {
    const { dateFilterType = AnalyticsDateFilterTypes.YEAR, quarter, year, month, kktList } = options;
    let url = `${this.COMMON_ENDPOINT}/${taxpayerId}/groupDate`;
    let boundaryDates: IBoundaryDates;
    switch (dateFilterType) {
      case AnalyticsDateFilterTypes.QUARTER:
        url = `${url}/year/${year}/quarter/${quarter}`;
        boundaryDates = getQuarterBoundaryDates(quarter, year);
        break;
      case AnalyticsDateFilterTypes.MONTH:
        const m = format(new Date(Number(year), Number(month)), 'YYYY-MM');
        url = `${url}/yearmonth/${m}`;
        boundaryDates = getMonthBoundaryDates(month, year);
        break;
      default:
        url = `${url}/year/${year}`;
        boundaryDates = getYearBoundaryDates(year);
    }
    const ids = (kktList || []).map(n => n.toString());
    let params = { ids, ...isAgents && {agentCRs: 'true'} }; //
    return {
      url,
      params,
      boundaryDates,
    }
  }

  getStatSummary(taxpayerId: string, options: any, isAgents: boolean) {
    const { url, params, boundaryDates } = this.getStatFilter(taxpayerId, options, isAgents);
    return this.http.get<ITicketStatisticsApiResponse>(url, { params })
      .pipe(
        map((response: ITicketStatisticsApiResponse) => {
          if (!response) return null;

          const { dailyStats = {}, ids: kktIds = [], count = 0 } = response;
          const { startDate, endDate } = boundaryDates;
          const dailyStatistics = {
            ...getDefaultStatsBetweenDates(startDate, endDate),
            ...dailyStats,
          };
          const overallStatistics = getOverallStatistics(dailyStatistics);

          return {
            count,
            kktIds,
            dailyStatistics,
            overallStatistics,
            originalDailyStatisticsCount: Object.keys(dailyStats).length
          };
        })
      );
  }

}
