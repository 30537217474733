export class SummaryRow {
  name: string;
  value: number;
  isMoney: boolean;

  constructor(name: string, value: number, isMoney: boolean = false) {
    this.name = name;
    this.value = value;
    this.isMoney = isMoney;
  }

  static simple(name: string, value: number): SummaryRow {
    return new SummaryRow(name, value);
  }

  static money(name: string, value: number): SummaryRow {
    return new SummaryRow(name, value, true);
  }
}
