import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IDetailedReceipt, IReceiptList, EntityID, ITaxRate } from '@common/interfaces';


@Injectable()
export class ReceiptService {
  private COMMON_ENDPOINT: string;
  private OPEN_API_COMMON_ENDPOINT: string;
  private TAX_RATES_API_ENDPOINT: string;

  private _taxRates: ITaxRate[] = [];

  constructor(
    @Inject('env') private env,
    private http: HttpClient,
  ) {
    this.COMMON_ENDPOINT = env?.shared?.services?.receipt?.commonEndpoint || '';
    this.OPEN_API_COMMON_ENDPOINT = env?.shared?.services?.receipt?.openApiCommonEndpoint || '';
    this.TAX_RATES_API_ENDPOINT = env?.shared?.services?.receipt?.taxRatesApi || '';
  }

  getReceipts(body): Observable<IReceiptList> {
    const url = `${this.COMMON_ENDPOINT}/voucher/filter`;
    return this.http.post<IReceiptList>(url, body)
      .pipe(
        first(),
        catchError(error => { throw of(error) })
      );
  }

  findReceipts(params): Observable<IReceiptList> {
    return this.http.get<IReceiptList>(`${this.COMMON_ENDPOINT}/voucher/find`, { params })
      .pipe(
        first(),
        catchError((error: HttpErrorResponse) => of(null))
      );
  }

  getReceipt(id: EntityID, isGuest: boolean = false): Observable<IDetailedReceipt> {
    const url = isGuest ? this.OPEN_API_COMMON_ENDPOINT : this.COMMON_ENDPOINT;
    const params = new HttpParams().set('include_items', 'true');
    return this.http.get<IDetailedReceipt>(`${url}/voucher/${id}`, {params: params})
      .pipe(
        first(),
        catchError(error => { throw of(error) })
      );
  }

  async getTaxRates(): Promise<ITaxRate[]> {
    if (Array.isArray(this._taxRates) && this._taxRates.length > 0) {
      return of(this._taxRates).toPromise();
    } else {
      this._taxRates = await this.http.get<ITaxRate[]>(this.TAX_RATES_API_ENDPOINT)
        .pipe(first(), catchError(error => { throw of(error) }))
        .toPromise();
      return of(this._taxRates).toPromise();
    }
  }
}
