import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {MatDatepicker} from '@angular/material/datepicker';

@Directive({
  selector: '[kkmDatePickerInput]'
})
export class DatePickerInputDirective {

  @Input('matDatepicker') matDatepicker: MatDatepicker<any>;
  @HostListener('focus') focus() {
    this.openPickerSaveFocus();
  }

  constructor(private elementRef: ElementRef) {
    this.nativeElement.setAttribute('autocomplete', 'off');
  }

  private openPickerSaveFocus() {
    if (!this.matDatepicker.opened) {
      this.matDatepicker.open();
      setTimeout(() => this.nativeElement.focus(), 0);
    }
  }

  private get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }
}
