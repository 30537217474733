import {ICrData, IReceipt} from "../index";

export {CounterRow} from './counter-row';
export {CountersColumn} from './counters-column';
export {InfoItem} from './info-item';
export {SummaryRow} from './summary-row';
export * from './x-report';
export * from './z-report';

export interface IShiftReport {
  type: number;
  cashier: string;
  tin: string;
  shiftNumber: number;
  fnSerialNumber: string;
  crRegisterNumber: string;
  locationName: string;
  locationAddress: string;
  dateTime: string;
  shiftFdsAmount: number;
  shiftTicketsAmount: number;
  shiftSummaryCounters: { 
    totalTicketsAmount: number 
    correctionCounters: { correctionTicketsAmount: number };
  };
  crData: ICrData;
  documentFiscalMark: string;
  fdNumber: number;
}

export interface IShift {
  id: string;
  shiftNumber: number;
  endDate: string;
  startDate: string;
  cashierName: string;
  kktRegisterNumber: string;
  locationAddress: string;
  locationName: string;
  vouchers: IReceipt[];
  cashSum: number;
  cashlessSum: number;
}

export interface IReportModel {
  data: IShiftReport;
}

export interface IReportSummaryCounters {

}
