import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter, OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { IShift, IReceipt, ICashRegister, IIdName, ITaxpayer } from '@common/interfaces';
import {
  BackendReceiptOperationTypes,
  BackendReceiptTypes,
  CalculationTypes,
  ReceiptOperationTypes,
  ReceiptTypes,
  ShiftReportTypes,
  SyntheticReceiptTypes
} from '@common/enums';
import { getReceiptType, getSyntheticReceiptType } from '@common/utils';
import {sumBy, isEqual, isEmpty} from 'lodash';
import {AnalyticsSummaryTypes} from '@common/enums';
import {ShiftService} from '../../../services/shift.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kkm-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.styl']
})
export class ShiftListComponent implements OnInit, OnDestroy {
  @Input() selectedReceiptId: string;
  @Input() isLoading: boolean = true;
  @Input() isFilterActive: boolean = false;
  @Input() cashRegister: ICashRegister;
  @Input() taxpayer: ITaxpayer;
  @Input() redrawCallback: () => void;
  @Input() isShowFdNumber?: boolean;

  @Input() set shifts(shifts: IShift[]) {
    const reversedShifts = Array.isArray(shifts) ? shifts.reverse() : [];
    this._shifts = reversedShifts.sort((a: IShift, b: IShift) => b.shiftNumber - a.shiftNumber);
    this.filteredShifts = reversedShifts.length > 0 ? [shifts[0]] : [];
    this.getVouchers();
  };

  @Output() onReceiptClick = new EventEmitter<IReceipt>();
  @Output() onFilter = new EventEmitter<any>();

  TRANSLATION_READ_PATH = 'common.components.sales-analytics.shift-list';
  analyticsSummaryTypes = AnalyticsSummaryTypes

  private _shifts: IShift[] = [];
  filteredShifts: IShift[] = [];
  receiptTypes: IIdName[] = [];
  receiptOperationTypes: IIdName[] = [];
  form: FormGroup;
  currentShiftIdx: number = 0;
  selectedVouchers: IReceipt[] = [];
  selectedReportId: string;
  selectedReportType: ShiftReportTypes;
  defaultFilter;
  isFilterChanged: boolean = false;
  isLoadingReceipts: boolean = false;

  private filterChangedSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private fb: FormBuilder,
    private shiftService: ShiftService,
  ) {
  }

  public ngOnInit(): void {
    this.receiptTypes = [
      { id: 'ALL', name: 'all_receipt_types' },
      { id: ReceiptTypes.TICKET, name: 'receipt' },
      { id: ReceiptTypes.CORRECTION_TICKET, name: 'correction_ticket' },
    ];

    this.receiptOperationTypes = [
      { id: 'ALL', name: 'all_receipt_operation_types' },
      { id: ReceiptOperationTypes.INCOME, name: 'income' },
      { id: ReceiptOperationTypes.INCOME_RETURN, name: 'income_return' },
      { id: ReceiptOperationTypes.EXPENDITURE, name: 'expenditure' },
      { id: ReceiptOperationTypes.EXPENDITURE_RETURN, name: 'expenditure_return' },
    ];

    this.form = this.fb.group({
      shiftNumber: [''],
      incomeReceipt: [true],
      returnReceipt: [true],
      correctionReceipt: [true],
      receiptType: ReceiptTypes.TICKET,
      receiptOperationType: 'ALL',
    });

    this.filterChangedSubscription = this.form
      .valueChanges
      .subscribe(() => this.isFilterChanged = true);

    this.defaultFilter = { ...this.form.value };
  }

  public ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
  }

  get gridColumns(): string[] {
    return [
      'dateTime',
      'fdNumber',
      'credit',
      'advance',
      'ticketTotalSum',
      'operationType',
    ];
  }

  changeShift(shiftIdx: number): void {
    this.currentShiftIdx = shiftIdx;
    this.filter();
  }

  clearFormField(ev: MouseEvent, name: string): void {
    ev.stopPropagation();
    this.form.get(name).setValue('');
  }

  shiftFilter = (shift: IShift): boolean => {
    const {shiftNumber} = this.form.value;
    return String(shift.shiftNumber).includes(shiftNumber);
  }

  get syntheticReceiptTypesFilter(): SyntheticReceiptTypes[] {
    const { receiptType, receiptOperationType } = this.form.value;

    if (receiptType === 'ALL' && receiptOperationType === 'ALL') {
      return Object.values(SyntheticReceiptTypes);
    }

    const receiptTypes: SyntheticReceiptTypes[] = [];
    if (receiptType !== 'ALL' && receiptOperationType === 'ALL') {
      const filteredReceiptOperationTypes = this.receiptOperationTypes.filter((type: IIdName) => type.id !== 'ALL');
      for (let i = 0; i < filteredReceiptOperationTypes.length; i++) {
        const fakeReceipt: IReceipt = this.createFakeReceipt(receiptType, String(filteredReceiptOperationTypes[i].id));
        const syntheticReceiptType = getSyntheticReceiptType(fakeReceipt);
        receiptTypes.push(syntheticReceiptType);
      }
    }

    if (receiptType === 'ALL' && receiptOperationType !== 'ALL') {
      const filteredReceiptTypes = this.receiptTypes.filter((type: IIdName) => type.id !== 'ALL');
      for (let i = 0; i < filteredReceiptTypes.length; i++) {
        const fakeReceipt: IReceipt = this.createFakeReceipt(String(filteredReceiptTypes[i].id), receiptOperationType);
        const syntheticReceiptType = getSyntheticReceiptType(fakeReceipt);
        receiptTypes.push(syntheticReceiptType);
      }
    }

    if (receiptType !== 'ALL' && receiptOperationType !== 'ALL') {
      const fakeReceipt: IReceipt = this.createFakeReceipt(receiptType, receiptOperationType);
      const syntheticReceiptType = getSyntheticReceiptType(fakeReceipt);
      receiptTypes.push(syntheticReceiptType);
    }

    return receiptTypes;
  }

  receiptFilter = (receipt: IReceipt): boolean => {
    const receiptType: SyntheticReceiptTypes = getReceiptType(receipt);
    return this.syntheticReceiptTypesFilter.some(type => receiptType === type);
  }

  setCurrentShift(): void {
    const {shiftNumber} = this.form.value;
    if (String(shiftNumber)) {
      this.currentShiftIdx = this._shifts.findIndex(shift => String(shift.shiftNumber) === shiftNumber)
    } else {
      if (this.currentShiftIdx < 0) {
        this.currentShiftIdx = 0;
      }
    }
  }

  filter(ev?: MouseEvent): void {
    this.setCurrentShift();

    this.filteredShifts = (this._shifts || []).filter((shift, idx) => idx === this.currentShiftIdx);
    this.isFilterChanged = false;
    const appliedFiltersCount = this.countFilters();
    this.onFilter.emit({key: this.cashRegister.regNumber, value: true, count: appliedFiltersCount});

    this.getVouchers();
  }

  get totalReceipts(): number {
    return sumBy(this.filteredShifts, shift => shift.vouchers.length);
  }

  get hasShifts(): boolean {
    return !!this._shifts?.length;
  }

  get hasFilteredShifts(): boolean {
    return !!this.filteredShifts?.length;
  }

  get totalShifts(): number {
    return this._shifts?.length || 0;
  }

  get calculationType(): CalculationTypes[] {
    const calcTypes = []; // TODO HARDCODE

    //было: отображение данных по расходам и приходам зависело от текущих настроек ППР на кассе:
    // - если есть ППР "4 Выплата денежных средств", то добавить отображение блока "Расход/Возврат расхода" в отчетах
    // - если есть любые другие ППР, с кодом не равным 4, то добавить отображение блока "Приход/Возврат прихода"

    // if (this.cashRegister.calcItemAttributes && this.cashRegister.calcItemAttributes.length > 0) {
    //   if (this.cashRegister.calcItemAttributes?.filter(c => c !== 4).length > 0) {
    //     calcTypes.push(CalculationTypes.SALE);
    //   }
    //   if (this.cashRegister.calcItemAttributes?.includes(4)) {
    //     calcTypes.push(CalculationTypes.EXPENSE);
    //   }
    // }

    // вышеописанное поведение приняли за баг и теперь необходимо отображать оба блока постоянно
    calcTypes.push(CalculationTypes.SALE);
    calcTypes.push(CalculationTypes.EXPENSE);

    return calcTypes;
  }

  openReport(ev: MouseEvent, shift: IShift, type: string): void {
    this.selectedReportId = shift.id;
    this.selectedReportType = type as ShiftReportTypes;
  }

  openReceipt(receipt: IReceipt): void {
    this.onReceiptClick.emit(receipt);
  }

  clearSelectedReport(ev: MouseEvent): void {
    this.selectedReportId = null;
    this.selectedReportType = null;
  }

  resetFilter(ev: MouseEvent): void {
    this.form.setValue(this.defaultFilter);
  }

  get areFiltersApplied(): boolean {
    const currentFilter = this.form.value;
    return !isEqual(this.defaultFilter, currentFilter);
  }

  async getVouchers() {
    if (!(this.filteredShifts && this.filteredShifts.length > 0)) {
      this.selectedVouchers = [];
      return;
    }

    const currentShiftId = this.filteredShifts[0].id;
    const fds = this.filteredShifts[0].vouchers.map(e => e.fdNumber);
    if (!currentShiftId) {
      this.selectedVouchers = [];
      return;
    }

    this.isLoadingReceipts = true;
    try {
      const receipts = await this.shiftService.getVoucherByShift(currentShiftId, fds).toPromise();
      this.selectedVouchers = receipts
        .filter(this.receiptFilter)
        .sort((a: IReceipt, b: IReceipt) => b.fdNumber - a.fdNumber);
    } catch (err) {
      this.selectedVouchers = [];
      console.error(err);
    }
    this.isLoadingReceipts = false;
  }

  private countFilters(): number {
    let count = 0;
    const { shiftNumber, receiptType, receiptOperationType } = this.form.value;
    if (isEmpty(shiftNumber) === false) {
      count = count + 1;
    }

    if (receiptType !== ReceiptTypes.TICKET) {
      count = count + 1;
    }

    if (receiptOperationType !== 'ALL') {
      count = count + 1;
    }

    return count;
  }

  private createFakeReceipt(receiptType: string, receiptOperationType: string): IReceipt {
    const fakeReceipt: IReceipt = {
      supplierTin: null,
      type: Number(BackendReceiptTypes[receiptType]),
      operationType: Number(BackendReceiptOperationTypes[receiptOperationType]),
      dateTime: null,
      fnSerialNumber: null,
      fdNumber: null,
      tin: null,
      documentFiscalMark: null,
      crRegisterNumber: null,
      ticketTotalSum: null,
      id: null,
      shiftNumber: null,
      correction: null,
      crData: null,
      dataFormatVersion: null,
      taxCounters: null,
      taxSystem: null,
      ticketNumber: null,
      ticketTotalCashlessSum: null,
      ticketTotalCashSum: null,
      ticketPrePaymentSum: null,
      ticketPostPaymentSum: null,
      errors: null,
    };

    return fakeReceipt;
  }
}
