import {Component, Input, EventEmitter, Output, OnInit, Inject} from '@angular/core';
import {KktStatusEnum} from "@common/enums";
import {TaxSystemsService} from '../../services/dictionaries/tax-systems.service';
import { KeyValue } from '@angular/common';
import { BusinessObjectsDictionaryService } from '../../services/dictionaries/business-objects-dictionary.service';
import { ICashRegisterDisplayOptions, ICodeName } from '@common/interfaces';

@Component({
  selector: 'kkm-cr-info',
  templateUrl: './cr-info.component.html',
  styleUrls: ['./cr-info.component.styl']
})
export class CashRegisterInfoComponent implements OnInit {

  @Input() data: any;
  @Input() fnExpirationWarningDays: number;
  @Input() isCrIdWarningClosed: boolean;
  @Input() displayOptions: ICashRegisterDisplayOptions;
  @Output() closeWarning: EventEmitter<number> = new EventEmitter();

  showExpirationWarning: boolean;
  showFnBlocked: boolean;
  fnExpirationDate: Date;
  crStatus = KktStatusEnum;
  showTaxSettings: boolean = false;

  constructor(
    @Inject('env') private env: any,
    private taxSystemsServices: TaxSystemsService,
    private businessObjectsDictionaryService: BusinessObjectsDictionaryService,
  ) { }

  ngOnInit() {
    if (this.data) {
      if (!!this.data.fnExpirationDate) {
        this.fnExpirationDate = new Date(this.data.fnExpirationDate);

        if (this.data.status !== this.crStatus.DEACTIVATED && this.data.fnActivationDaysLeft !== null) {
          if (this.data.fnActivationDaysLeft > 0) {
            this.showExpirationWarning = this.data.fnActivationDaysLeft < this.fnExpirationWarningDays && !this.isCrIdWarningClosed;
          } else {
            this.showFnBlocked = true;
          }
        }
      }
    }
  }

  closeCrWarning() {
    this.showExpirationWarning = false;
    this.closeWarning.emit(this.data.id);
  }

  get formattedTaxSystems(): string {
    let taxNames = '';
    if (this.data && this.data.taxSystems) {
      const userTaxSystems = this.data.taxSystems;
      for (let i = 0; i < userTaxSystems.length; i++) {
        taxNames += this.taxSystems.find(t => t.code === userTaxSystems[i]).taxName
                  + (((i + 1) === userTaxSystems.length) ? '' : ', ');
      }

      taxNames += '. '
    }
    return taxNames;
  }

  get isUnlimitedFm() {
    return this.env?.isUnlimitedFm;
  }

  get taxSystems() {
    return this.taxSystemsServices.taxSystems;
  }

  get enterpreneurshipDetails(): KeyValue<string, string>[] {
    if (this.data == null) {
      return [];
    }

    const result: KeyValue<string, string>[] = [];
    if (this.data.entrepreneurshipObject) {
      const entrepreneurshipObject = this.businessObjectsDictionaryService
        .entrepreneurshipObjects
        .find((obj: ICodeName) => obj.code === this.data.entrepreneurshipObject);
      if (entrepreneurshipObject) {
        result.push({key: 'entrepreneurship-object.title', value: entrepreneurshipObject.name});
      }
    }

    if (this.data.businessActivity) {
      const businessActivity = this.businessObjectsDictionaryService
        .businessActivities
        .find((obj: ICodeName) => obj.code === this.data.businessActivity);
      if (businessActivity) {
        result.push({key: 'business-activity.title', value: businessActivity.name});
      }
    }

    if (this.data.taxAuthorityDepartment) {
      const taxAuthorityDepartment = this.businessObjectsDictionaryService
        .taxAuthorityDepartments
        .find((obj: ICodeName) => obj.code === this.data.taxAuthorityDepartment);
      if (taxAuthorityDepartment) {
        result.push({key: 'tax-authority-department.title', value: taxAuthorityDepartment.name});
      }
    }

    return result;
  }

  getStatusBadgeClass(): string {
    if (this.data == null || this.data.status == null || this.data.status.trim() === '') {
      return;
    }

    switch (this.data.status) {
      case KktStatusEnum.DEACTIVATED: return 'deactivated';
      case KktStatusEnum.REGISTERED: return 'registered';
      case KktStatusEnum.REJECTED: return 'rejected';
      case KktStatusEnum.REQUESTED: return 'requested';
      default:
        throw new Error(`Unknown cash register status: ${this.data.status}`);
    }
  }
}
