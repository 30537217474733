<mat-button-toggle-group [formControl]="dateFilterTypeControl" aria-label="Font Style" class="kkm-calendar__header" *transloco="let t; read: 'common.dateIntervals'">
  <mat-button-toggle *ngIf="dateFilterArray.includes(dateFilterEnum.Year)" [value]="dateFilterEnum.Year" class="kkm-calendar__header-button">{{ t('year') }}</mat-button-toggle>
  <mat-button-toggle *ngIf="dateFilterArray.includes(dateFilterEnum.Quarter)" [value]="dateFilterEnum.Quarter" class="kkm-calendar__header-button">{{ t('quarter') }}</mat-button-toggle>
  <mat-button-toggle *ngIf="dateFilterArray.includes(dateFilterEnum.Month)" [value]="dateFilterEnum.Month" class="kkm-calendar__header-button">{{ t('month') }}</mat-button-toggle>
  <mat-button-toggle *ngIf="dateFilterArray.includes(dateFilterEnum.Day)" [value]="dateFilterEnum.Day" class="kkm-calendar__header-button">{{ t('day') }}</mat-button-toggle>
</mat-button-toggle-group>

<ng-template [cdkPortalOutlet]="_calendarHeaderPortal"></ng-template>

<div [ngSwitch]="currentView">

  <mat-month-view class="kkm-month-view"
    *ngSwitchCase="'month'"
    [(activeDate)]="selectedData.date"
    [selected]="selected"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [dateClass]="dateClass"
    [comparisonStart]="comparisonStart"
    [comparisonEnd]="comparisonEnd"
    (_userSelection)="handleDateSelected($event)">
  </mat-month-view>

  <mat-year-view class="kkm-year-view"
    *ngSwitchCase="'year'"
    [(activeDate)]="selectedData.date"
    [selected]="selectedData.date"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (monthSelected)="handleMonthSelected($event)"
    (selectedChange)="goToDateInView($event, dateFilterEnum.Day)">
  </mat-year-view>

  <kkm-quarter-view
    *ngSwitchCase="'quarter'"
    [(activeDate)]="selectedData.date"
    (quarterSelected)="handleQuarterSelect($event)">
  </kkm-quarter-view>

  <kkm-multi-year-view
    *ngSwitchCase="'multi-year'"
    [(activeDate)]="selectedData.date"
    [selected]="selectedData.date"
    [dateFilter]="dateFilter"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (yearSelected)="handleYearSelected($event)"
    (selectedChange)="goToDateInView($event, dateFilterEnum.Quarter)">
  </kkm-multi-year-view>

</div>
