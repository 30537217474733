import { NgModule } from '@angular/core';
import { OverlayPanelComponent } from './overlay-panel.component';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    OverlayPanelComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
  ],
  exports: [
    OverlayPanelComponent,
  ],
})
export class OverlayPanelModule {

}
