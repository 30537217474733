import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty, NumberInput } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import IMask from 'imask';

export interface IMaskedNumberOptions {
  radix: string;
  thousandsSeparator: string;
  mapToRadix: Array<string>;
  min: number | null;
  max: number | null;
  scale: number;
  signed: boolean;
  normalizeZeros: boolean;
}

@Component({
  selector: 'kkm-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.styl'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: InputNumberComponent,
    }
  ],
})
export class InputNumberComponent implements ControlValueAccessor, MatFormFieldControl<NumberInput>, OnDestroy {
  static nextId = 0;

  @ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;

  public id: string = `kkm-input-number-${InputNumberComponent.nextId++}`;
  public controlType: string = 'kkm-input-number';
  public autofilled: boolean;
  public focused: boolean = false;
  public touched: boolean = false;
  public stateChanges: Subject<void> = new Subject<void>();
  public numberForm: FormGroup;
  public numberMask: IMask.MaskedNumber;
  private maskedNumberOptions: IMaskedNumberOptions;
  public increaseCallback = this.increase.bind(this);
  public decreaseCallback = this.decrease.bind(this);
  private timer: any;

  public get empty(): boolean {
    return !this.numberForm.value.numberInput;
  }

  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  public get min(): number | null {
    return this._min;
  }
  public set min(value: number | null) {
    this._min = value;
    this.updateNumberMask();
    this.updateFormValidators();
    this.stateChanges.next();
  }
  private _min: number | null = null;

  @Input()
  public get max(): number | null {
    return this._max;
  }
  public set max(value: number | null) {
    this._max = value;
    this.updateNumberMask();
    this.updateFormValidators();
    this.stateChanges.next();
  }
  private _max: number | null = null;

  @Input()
  public get step(): number | null {
    return this._step;
  }
  public set step(value: number | null) {
    this._step = value || 1;
    this.updateNumberMask();
    this.stateChanges.next();
  }
  private _step: number = 1;

  @Input()
  public get scale(): number | null {
    return this._scale;
  }
  public set scale(value: number | null) {
    this._scale = value || 2;
    this.updateNumberMask();
    this.stateChanges.next();
  }
  private _scale: number = 2;

  @Input() showArrows: boolean = true;

  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  public get required(): boolean {
    return this._required;
  }
  public set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.updateFormValidators();
    this.stateChanges.next();
  }
  private _required: boolean = false;

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.numberForm.disable();
    } else {
      this.numberForm.enable();
    }

    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  public get value(): NumberInput {
    return this.numberForm.valid
      ? this.numberForm.value.numberInput
      : null;
  }
  public set value(value: NumberInput) {
    this.numberForm.setValue({ numberInput: value });
    this.stateChanges.next();
  }

  public get errorState(): boolean {
    return this.numberForm.invalid && this.touched;
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  constructor(private _focusMonitor: FocusMonitor,
              private _elementRef: ElementRef<HTMLElement>,
              @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
              @Optional() @Self() public ngControl: NgControl) {
    this.numberMask = new IMask.MaskedNumber({ mask: Number });
    this.updateNumberMask();

    this.numberForm = new FormGroup({
      numberInput: new FormControl(null),
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  public onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  public onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  public onContainerClick(event: MouseEvent): void {
    this._focusMonitor.focusVia(this.numberInput, 'program');
  }

  public setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.kkm-input-number__container',
    );

    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: NumberInput): void {
    this.value = obj;
  }

  public handleInput(): void {
    if (this.disabled) {
      return;
    }

    setTimeout(() => {
      this.checkRangeError(this.numberMask.typedValue);
      this.onChange(this.numberMask.value);
    });
  }

  public increase(): void {
    if (this.disabled) {
      return;
    }

    this.numberMask.typedValue = this.numberMask.typedValue + this.step;
    this.value = String(this.numberMask.typedValue);
    this.checkRangeError(this.numberMask.typedValue);
    this.onChange(this.numberMask.typedValue);
  }

  public decrease(): void {
    if (this.disabled) {
      return;
    }

    this.numberMask.typedValue = this.numberMask.typedValue - this.step;
    this.value = String(this.numberMask.typedValue);
    this.checkRangeError(this.numberMask.typedValue);
    this.onChange(this.numberMask.typedValue);
  }

  private checkRangeError(value: number): void {
    if (this.min != null && value < this.min) {
      this.numberForm.setErrors({ min: value });
    }

    if (this.max != null && this.max < value) {
      this.numberForm.setErrors({ max: value });
    }
  }

  private updateMaskedNumberOptions(min: number | null, max: number | null, scale: number): void {
    this.maskedNumberOptions = {
      scale: scale,
      signed: true,
      thousandsSeparator: '',
      normalizeZeros: true,
      radix: ',',
      mapToRadix: ['.'],
      min: null,
      max: null
    };
  }

  private updateNumberMask(): void {
    this.updateMaskedNumberOptions(this.min, this.max, this.scale);
    this.numberMask.updateOptions(this.maskedNumberOptions);
  }

  private updateFormValidators(): void {
    this.numberForm.clearValidators();
    this.numberForm.setValidators([
      this.required ? Validators.required : Validators.nullValidator,
      this.min == null ? Validators.nullValidator : Validators.min(this.min),
      this.max == null ? Validators.nullValidator : Validators.max(this.max),
    ]);
  }

  public mouseDown(increaseCallback: Function): void {
    this.timer = setInterval(() => increaseCallback(), 60);
  }

  public mouseUp(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
