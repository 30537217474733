import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyCatalogValidators } from './currency-catalog.validators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MaskServiceService } from '../../services/mask-service.service';
import { ICurrency } from '@common/interfaces';
import { CurrencyType } from '@common/enums';
import { Subject, Subscription } from 'rxjs';
import { CURRENCY_FRACTURE_PART } from '@common/constants';

@Component({
  selector: 'kkm-currency-catalog-table',
  templateUrl: './currency-catalog-table.component.html',
  styleUrls: ['./currency-catalog-table.component.styl']
})
export class CurrencyCatalogTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() public isLoading: boolean = false;
  @Input()
  public set data(val: ICurrency[]) {
    this.dataSource.data = [...val || []];

    this.codeControl.clearValidators();
    this.codeControl.setValidators([
      Validators.required,
      Validators.pattern('[A-Z]{2,3}'),
      CurrencyCatalogValidators.currencyUniqueCodeValidator(this.dataSource.data)
    ]);

    this.nameControl.clearValidators();
    this.nameControl.setValidators([
      Validators.required,
      CurrencyCatalogValidators.currencyUniqueNameValidator(this.dataSource.data)
    ]);
  }

  @Input() public currencyType: CurrencyType;
  @Input() public newCurrencySubject: Subject<void>;

  @Output() public save: EventEmitter<ICurrency> = new EventEmitter<ICurrency>();
  @Output() public delete: EventEmitter<ICurrency> = new EventEmitter<ICurrency>();
  @ViewChild(MatSort) sort: MatSort;

  public dataSource: MatTableDataSource<ICurrency> = new MatTableDataSource<ICurrency>();
  public columns: string[];
  public currencyForm: FormGroup;
  public curType = CurrencyType;
  public sellMask;
  public buyMask;

  private newCurrencySubscription: Subscription = Subscription.EMPTY;

  constructor(private maskService: MaskServiceService) {
    this.sellMask = this.maskService.localizedInputCurrencyMask;
    this.buyMask = this.maskService.localizedInputCurrencyMask;

    this.columns = [
      'code',
      'name',
      'amount',
      'sellExchangeRate',
      'buyExchangeRate',
      'modifyDate',
      'edit',
      'delete-show-hide'
    ];

    this.currencyForm = new FormGroup({
      code: new FormControl(null),
      name: new FormControl(null),
      amount: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/[0-9]*/)
      ]),
      sell: new FormControl(null, [Validators.required]),
      buy: new FormControl(null, [Validators.required])
    });
  }

  public ngOnInit(): void {
    this.newCurrencySubscription = this.newCurrencySubject.subscribe(() => {
      this.setDefaultCurrencyFormValue();
      this.currencyForm.markAsUntouched();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const isLoadingChanges = changes.isLoading;
    if (isLoadingChanges != null && !isLoadingChanges.isFirstChange()) {
      if (isLoadingChanges.currentValue) {
        this.currencyForm.disable();
      } else {
        this.currencyForm.enable();
      }
    }
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public ngOnDestroy(): void {
    this.newCurrencySubscription.unsubscribe();
  }

  get codeControl(): FormControl {
    return this.currencyForm.get('code') as FormControl;
  }

  get nameControl(): FormControl {
    return this.currencyForm.get('name') as FormControl;
  }

  get amountControl(): FormControl {
    return this.currencyForm.get('amount') as FormControl;
  }

  get sellControl(): FormControl {
    return this.currencyForm.get('sell') as FormControl;
  }

  get buyControl(): FormControl {
    return this.currencyForm.get('buy') as FormControl;
  }

  public editCurrency(currency: ICurrency): void {
    const emptyCurrency = this.dataSource.data.find((cur: ICurrency) => cur.id == null || cur.id === 0);
    if (emptyCurrency) {
      this.removeCurrency(emptyCurrency);
    }

    this.dataSource.data.forEach((cur: ICurrency) => cur.isEdit = false);
    currency.isEdit = true;
    this.currencyForm.setValue({
      code: currency.code,
      name: currency.name,
      amount: currency.amount,
      sell: String(currency.sellExchangeRate / CURRENCY_FRACTURE_PART),
      buy: String(currency.buyExchangeRate / CURRENCY_FRACTURE_PART),
    });
  }

  public submitCurrency(currency: ICurrency): void {
    if (this.currencyForm.invalid) {
      this.currencyForm.controls.code.markAsDirty();
      this.currencyForm.controls.name.markAsDirty();
      this.currencyForm.controls.amount.markAsDirty();
      this.currencyForm.controls.sell.markAsDirty();
      this.currencyForm.controls.buy.markAsDirty();
      this.currencyForm.markAllAsTouched();
      return;
    }

    const newCurrency: ICurrency = {
      id: currency.id,
      code: this.currencyForm.value.code,
      name: this.currencyForm.value.name,
      amount: Number(this.currencyForm.value.amount),
      sellExchangeRate: this.sellMask.typedValue * CURRENCY_FRACTURE_PART,
      buyExchangeRate: this.buyMask.typedValue * CURRENCY_FRACTURE_PART,
      modifyDate: new Date().toISOString(),
      isEdit: false,
      type: currency.type,
      hidden: currency.hidden,
    };

    this.save.emit(newCurrency);
  }

  public removeCurrency(currency: ICurrency): void {
    this.delete.emit(currency);
  }

  public closeEditCurrency(currency: ICurrency): void {
    currency.isEdit = false;
    this.setDefaultCurrencyFormValue();

    if (currency.id == null || currency.id === 0) {
      this.removeCurrency(currency);
    }
  }

  public showCurrency(currency: ICurrency): void {
    currency.hidden = false;
    this.save.emit(currency);
  }

  public hideCurrency(currency: ICurrency): void {
    currency.hidden = true;
    this.save.emit(currency);
  }

  public isFocused(el: Element): boolean {
    return document.activeElement === el;
  }

  public controlHasError(control: FormControl, errorCode: string): boolean {
    return control.dirty
    && control.touched
    && control.invalid
    && control.hasError(errorCode)
  }

  private setDefaultCurrencyFormValue(): void {
    this.currencyForm.reset({
      code: null,
      name: null,
      amount: null,
      sell: null,
      buy: null,
    });
  }
}
