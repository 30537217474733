import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kkm-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.styl']
})
export class InfoItemComponent implements OnInit {
  @Input() title: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
