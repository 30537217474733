import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '../../../services/session/session.service';
import { HeaderComponent } from './header.component';
import { TranslocoRootModule } from '../../../transloco-root.module';
import { UiModule } from '@kkm-ui/ui';
import { environment } from '@ais-ofd/env';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    TranslocoRootModule,
    UiModule.forRoot(environment),
  ],
  exports: [
    HeaderComponent
  ],
  bootstrap: [HeaderComponent],
  // providers: [SessionService],
})
export class HeaderModule { }
