<div cdkOverlayOrigin #originOverlay="cdkOverlayOrigin" #element>
  <ng-content select="[overlayTrigger]"></ng-content>
</div>

<ng-template cdkConnectedOverlay
             cdkConnectedOverlayHasBackdrop
             [cdkConnectedOverlayOrigin]="originOverlay"
             [cdkConnectedOverlayOpen]="showPanel$ | async"
             [cdkConnectedOverlayPositions]="positions"
             (backdropClick)="resetShowOverlay()"
             cdkConnectedOverlayBackdropClass="cdk-overlay-backdrop">
  <div class="kkm-overlay-panel" [style.width]="width">
    <ng-content select="[overlayBody]"></ng-content>
    <mat-icon *ngIf="showCloseButton" svgIcon="close:dialog" class="kkm-overlay-panel__close-button small gray" (click)="resetShowOverlay()"></mat-icon>
  </div>
</ng-template>
