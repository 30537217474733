<div class="notification-inner body_text p_x-6 p_y-3 bg-{{type}}">
  <div class="notification-content_wrap">
    <div class="notification-content">
      <ng-content></ng-content>
    </div>

  </div>
  <a *ngIf="showCloseBtn" class="d-flex align-items-center no-text-decoration" (click)="close.emit()">
    <mat-icon class="{{iconSize}} dark" svgIcon="close:dialog"></mat-icon>
  </a>
</div>
