import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ShiftService} from '../../services/shift.service';
import {translate} from "@ngneat/transloco";
import {CalculationTypes, ShiftReportTypes, TaxTypes} from "@common/enums";
import {
  CounterRow,
  CountersColumn,
  InfoItem,
  IReportModel,
  ITaxCounter,
  ITaxRate,
  SummaryRow,
  XReportModel,
  ZReportModel
} from "@common/interfaces";
import {ReceiptService} from '../../services/receipt.service';


@Component({
  selector: 'kkm-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.styl']
})
export class ShiftReportComponent implements OnInit, OnChanges {
  TRANSLATION_READ_PATH = 'common.components.shift-report';
  @Input() shiftId: string;
  @Input() kktNumber?: string;
  @Input() calcTypes?: CalculationTypes[] = [];
  @Input() tin: string;
  @Input() type: ShiftReportTypes;
  @Input() taxpayerName: string;
  @Input() sn: string;
  @Output() onClose = new EventEmitter<MouseEvent>();

  shiftReportTypes = ShiftReportTypes;
  isLoading = false;
  isOpened = false;
  reportModel: IReportModel;
  title: string;

  countersColumns: CountersColumn[];
  countersRows: CounterRow[] = [];
  correctionCountersRows: CounterRow[] = [];
  summaryRows: SummaryRow[] = [];
  infoItems: InfoItem[] = [];
  documentInfoItems: InfoItem[] = [];

  private _taxRates: ITaxRate[] = [];

  constructor(
    private shiftService: ShiftService,
    private receiptService: ReceiptService,
    @Inject('env') private env: any
  ) {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.shiftId?.currentValue !== changes.shiftId?.previousValue
        || changes.type?.currentValue !== changes.type?.previousValue) {

      this.reportModel = null;
      await this.loadData();
    }
  }

  ngOnInit() {
  }

  async loadData(): Promise<void> {
    if (this.shiftId && this.type) {
      this.title = this.translate(this.type === ShiftReportTypes.X ? 'x-title' : this.type === ShiftReportTypes.Z ? 'z-title' : 'opening-title');

      try {
        this.isOpened = true;
        this.isLoading = true;
        const params = this.kktNumber ? {cashRegisterNumber: this.kktNumber} : null;
        let response: { status: number; response?: IReportModel } = null;
        this._taxRates = await this.receiptService.getTaxRates();
        if (this.type === ShiftReportTypes.X) {
          response = await this.shiftService.getXReport(this.shiftId, params).toPromise();
        } else if (this.type === ShiftReportTypes.Z) {
          response = await this.shiftService.getZReport(this.shiftId, params).toPromise();
        } else if (this.type === ShiftReportTypes.OPENING) {
          response = await this.shiftService.getOpeningReport(this.shiftId, params).toPromise();
        }
        if (response.status === 200) {
          this.reportModel = response.response;

          const countersColumn: CountersColumn[] = [];
          if (this.calcTypes.includes(CalculationTypes.SALE) || this.calcTypes.length < 1) {
            countersColumn.push(
              new CountersColumn(this.translate('income'), 'income', 'small_text'),
              new CountersColumn(this.translate('return'), 'incomeReturn', 'small_text'),
            );
          }
          if (this.calcTypes.includes(CalculationTypes.EXPENSE) || this.calcTypes.length < 1) {
            countersColumn.push(
              new CountersColumn(this.translate('expense'), 'expense', 'small_text'),
              new CountersColumn(this.translate('expenseReturn'), 'expenseReturn', 'small_text'),
            );
          }
          this.countersColumns = countersColumn;

          if (this.type === ShiftReportTypes.X || this.type === ShiftReportTypes.Z) {
            this.countersRows =  this.mapReportCountersRows(this.reportModel as XReportModel);
            this.correctionCountersRows = this.mapReportCountersRows((this.reportModel as ZReportModel).corrections);
          }

          this.summaryRows = this.mapSummaryRows(this.reportModel, this.type);
          this.infoItems = this.mapInfoItems(this.reportModel, this.type);
          this.documentInfoItems = this.mapDocumentItems(this.reportModel);
        }

      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  private translate(key: string): string {
    return translate(`${this.TRANSLATION_READ_PATH}.${key}`);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close(null);
  }

  close(ev: MouseEvent): void {
    this.isOpened = false;
    this.onClose.emit(ev);
    setTimeout(() => {
      // this.report = null;
    }, 600);
  }

  private mapSummaryRows(report: IReportModel, type: ShiftReportTypes): SummaryRow[] {
    const result = [];

    const isXReport =  type === ShiftReportTypes.X;
    if (isXReport || type === ShiftReportTypes.Z) {
      if (isXReport) {
        result.unshift(SummaryRow.money(this.translate('summary.total-cash'), (report as XReportModel).cashTotalSum))
      }

      result.push(
        SummaryRow.simple(this.translate('summary.fiscal-documents-amount'),
        report.data.shiftSummaryCounters.totalTicketsAmount + report.data.shiftSummaryCounters.correctionCounters.correctionTicketsAmount
        ))
    }

    return result;
  }

  private mapInfoItems(report: IReportModel, type: ShiftReportTypes): InfoItem[] {
    const result = [
      new InfoItem(this.translate('cashRegisterId'), report.data.crRegisterNumber),
      new InfoItem(this.translate('fiscalMemoryDevice'), report.data.fnSerialNumber),
      new InfoItem(this.translate('serialNumber'), this.sn)
    ];

    return result;
  }

  private mapDocumentItems(report: IReportModel): InfoItem[] {
    const result = [
      new InfoItem(this.translate('fiscalDocument'), report.data.fdNumber),
      new InfoItem(this.translate('fiscalMark'), report.data.documentFiscalMark)
    ];

    return result;
  }

  private mapReportCountersRows(report: any): CounterRow[] {
    const { incomes, returns, expenses, expenseReturns } = report;

    const taxesRows = this._taxRates.map(tax => CounterRow.money(
      this.getTaxCounterDisplayName(tax.code, tax.taxRateType as TaxTypes),
      this.getTaxSum(incomes.taxCounters, tax),
      this.getTaxSum(returns.taxCounters, tax),
      this.getTaxSum(expenses.taxCounters, tax),
      this.getTaxSum(expenseReturns.taxCounters, tax),
      )
    ).filter(e => e.income !== 0 || e.incomeReturn !== 0);

    const totalRow = CounterRow.money(
      this.translate('total-row'),
      incomes.totalSum,
      returns.totalSum,
      expenses.totalSum,
      expenseReturns.totalSum,
      'value_bold name_bold border_top'
    );

    const subtotal = this.mapSubtotalRow(taxesRows, totalRow);

    return [
      CounterRow.simple(
        this.translate('receipts-row'),
        incomes.operationTicketsAmount,
        returns.operationTicketsAmount,
        expenses.operationTicketsAmount,
        expenseReturns.operationTicketsAmount,
      ),
      subtotal,
      ...taxesRows,
      totalRow,
      CounterRow.money(
        this.translate('by-cash'),
        incomes.totalCashSum,
        returns.totalCashSum,
        expenses.totalCashSum,
        expenseReturns.totalCashSum
      ),
      CounterRow.money(
        this.translate('by-card'),
        incomes.totalCashlessSum,
        returns.totalCashlessSum,
        expenses.totalCashlessSum,
        expenseReturns.totalCashlessSum
      ),
      CounterRow.money(
        this.translate('advance'),
        incomes.operationPrePaymentSum || 0,
        returns.operationPrePaymentSum || 0,
        expenses.operationPrePaymentSum || 0,
        expenseReturns.operationPrePaymentSum || 0,
      ),
      CounterRow.money(
        this.translate('credit'),
        incomes.operationPostPaymentSum || 0,
        returns.operationPostPaymentSum || 0,
        expenses.operationPostPaymentSum || 0,
        expenseReturns.operationPostPaymentSum || 0,
      ),
    ];
  }

  private getTaxSum(taxCouters: ITaxCounter[], tax: ITaxRate) {
    const taxValues = taxCouters.filter(t => t.code === tax.code && t.type === tax.taxRateType).map(a => a.sum);
    const taxSum = taxValues.reduce((a, b) => Number(a) + Number(b), 0);
    return taxSum
  }

  private mapSubtotalRow(taxesRows: CounterRow[], totalRow: CounterRow): CounterRow {
    const incomeTaxesSum = taxesRows.reduce((sum, row) => sum + row.income, 0);
    const incomeReturnTaxesSum = taxesRows.reduce((sum, row) => sum + row.incomeReturn, 0);
    const expenseTaxesSum = taxesRows.reduce((sum, row) => sum + row.expense, 0);
    const expenseReturnTaxesSum = taxesRows.reduce((sum, row) => sum + row.expenseReturn, 0);

    const incomeSubtotal = totalRow.income - incomeTaxesSum;
    const incomeReturnSubtotal = totalRow.incomeReturn - incomeReturnTaxesSum;
    const expenseSubtotal = totalRow.expense - expenseTaxesSum;
    const expenseReturnSubtotal = totalRow.expenseReturn - expenseReturnTaxesSum;

    return CounterRow.money(
      this.translate('subtotal-row'),
      incomeSubtotal,
      incomeReturnSubtotal,
      expenseSubtotal,
      expenseReturnSubtotal,
      'border_top'
    );
  }

  private getTaxCounterDisplayName(taxCode: number, type: TaxTypes): string {
    let translationKey: string;
    switch (type) {
      case TaxTypes.ST:
        translationKey = 'salesTax-row';
        break;
      case TaxTypes.VAT:
        translationKey = 'vat-row';
        break;
    }
    return `${this.translate(translationKey)} ${this.getTaxRate(taxCode, type)}%`;
  }

  getTaxRate(code: number, type: string) {
    const rateValue = this._taxRates.find(taxRate => taxRate.code === code && taxRate.taxRateType === type)?.taxRateValue;
    return rateValue !== undefined ? rateValue : '';
  }

  get hideCorrections() {
    return this.env?.hideCorrections;
  }
}
