import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { DateRange, MatCalendar, MatCalendarUserEvent, MatDatepickerIntl } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import {
  DateFilterEnum,
  IQuarter,
  ISelectedDate,
  KkmMatCalendarView,
  QUARTERS,
  Quarters
} from '../kkm-datepicker.interfaces';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};

@Component({
  selector: 'kkm-calendar',
  templateUrl: './kkm-calendar.component.html',
  styleUrls: ['./kkm-calendar.component.styl'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class KkmCalendarComponent<D> extends MatCalendar<D> implements OnInit, OnDestroy{

  dateFilterEnum = DateFilterEnum;
  dateFilterTypeControl = new FormControl();
  dateFilterTypeControlSubscription: Subscription = Subscription.EMPTY;

  @Input() dateFilterArray: DateFilterEnum[] = [DateFilterEnum.Year, DateFilterEnum.Quarter, DateFilterEnum.Month, DateFilterEnum.Day];
  @Input() isRange: boolean = false;
  @Input() selectedData: ISelectedDate<D>;
  @Output() readonly selectedDataChange: EventEmitter<ISelectedDate<D>> = new EventEmitter<ISelectedDate<D>>();
  @Output() readonly closeDatepicker: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(intl: MatDatepickerIntl,
              @Optional() private dateAdapter: DateAdapter<D>,
              @Optional() @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
              private changeDetectorRef: ChangeDetectorRef,
              private _translocoService: TranslocoService) {
    super(intl, dateAdapter, dateFormats, changeDetectorRef);

    const activeLang = this._translocoService.getActiveLang();
    this.dateAdapter.setLocale(activeLang);
  }

  get selected(): DateRange<D> | D | null {
    if (!this.selectedData) {
      return null;
    }

    return this.isRange ? this.selectedData.dateRange : this.selectedData.date;
  }
  set selected(value: DateRange<D> | D | null) {
    if (!this.selectedData) {
      return;
    }

    if (this.isRange && value instanceof DateRange) {
      this.selectedData.dateRange = value;
    } else {
      const obj = this.dateAdapter.deserialize(value);
      this.selectedData.date = this.dateAdapter.isDateInstance(obj) && this.dateAdapter.isValid(obj as D) ? obj as D : null;
    }
  }

  ngOnInit() {
    this.activeDate = this.selectedData.date;
    this.selected = this.isRange ? this.selectedData.dateRange : this.selectedData.date;

    // @ts-ignore
    this.startView = this.getMatCalendarView(this.selectedData.dateFilterType);
    this.dateFilterTypeControl.setValue(this.selectedData.dateFilterType);

    this.dateFilterTypeControlSubscription = this.dateFilterTypeControl.valueChanges.subscribe((value: DateFilterEnum) => {
      // @ts-ignore
      this.currentView = this.getMatCalendarView(value);
      const data = {
        ...this.selectedData,
        dateFilterType: value,
      };
      this.selectedDataChange.emit(data);
    });
  }

  ngOnDestroy() {
    this.dateFilterTypeControlSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  get dateFilterType(): DateFilterEnum {
    return this.dateFilterTypeControl.value as DateFilterEnum;
  }

  focusActiveCell(): void {
  }

  handleDateSelected(event: MatCalendarUserEvent<D | null>): void {
    const date = event.value;
    if (event.event == null) {
      this.selectedData.date = date;
      return;
    }

    if (this.isRange) {
      this.selectedData.date = date;
      if (this.selectedData.dateRange.start == null || date < this.selectedData.dateRange.start || this.selectedData.dateRange.end) {
        this.selectedData.dateRange = new DateRange<D>(date, null);
      } else {
        this.selectedData.dateRange = new DateRange<D>(this.selectedData.dateRange.start, date);
        const monthNumber = this.dateAdapter.getMonth(this.selectedData.dateRange.start);

        const data: ISelectedDate<D> = {
          dateFilterType: this.dateFilterType,
          year: this.dateAdapter.getYear(this.selectedData.dateRange.start),
          quarter: QUARTERS.find((quarter: IQuarter) => quarter.startMonth <= monthNumber && monthNumber <= quarter.endMonth).quarter,
          month: monthNumber,
          day: this.dateAdapter.getDate(this.selectedData.dateRange.start),
          date: this.selectedData.dateRange.start,
          dateRange: new DateRange(this.selectedData.dateRange.start, this.selectedData.dateRange.end),
        };

        this.selectedDataChange.emit(data);
        this._dateSelected(event);

        this.closeDatepicker.emit(this.dateFilterType === DateFilterEnum.Day);
      }
    } else {
      const monthNumber = this.dateAdapter.getMonth(date);

      const data: ISelectedDate<D> = {
        dateFilterType: this.dateFilterType,
        year: this.dateAdapter.getYear(date),
        quarter: QUARTERS.find((quarter: IQuarter) => quarter.startMonth <= monthNumber && monthNumber <= quarter.endMonth).quarter,
        month: monthNumber,
        day: this.dateAdapter.getDate(date),
        date: date,
        dateRange: new DateRange(null, null),
      };

      this.selectedDataChange.emit(data);
      this._dateSelected(event);

      this.closeDatepicker.emit(this.dateFilterType === DateFilterEnum.Day);
    }
  }

  handleMonthSelected(month: D & DateRange<D>, shouldCloseDatepicker: boolean = true): void {
    const monthNumber = this.dateAdapter.getMonth(month);
    const year = this.dateAdapter.getYear(month);

    const data: ISelectedDate<D> = {
      dateFilterType: this.dateFilterType,
      year: year,
      quarter: QUARTERS.find((quarter: IQuarter) => quarter.startMonth <= monthNumber && monthNumber <= quarter.endMonth).quarter,
      month: monthNumber,
      day: this.dateAdapter.getDate(month),
      date: month,
      dateRange: new DateRange<D>(null, null),
    };

    this.selectedDataChange.emit(data);
    this._monthSelectedInYearView(month);

    if (shouldCloseDatepicker) {
      this.closeDatepicker.emit(this.dateFilterType === DateFilterEnum.Month);
    }
  }

  handleQuarterSelect(date: D): void {
    const monthNumber = this.dateAdapter.getMonth(date);

    const year = this.dateAdapter.getYear(date);
    const quarterData = QUARTERS.find((q: IQuarter) => q.startMonth <= monthNumber && monthNumber <= q.endMonth);

    const data: ISelectedDate<D> = {
      dateFilterType: this.dateFilterType,
      year: year,
      quarter: quarterData.quarter,
      month: this.dateAdapter.getMonth(date),
      day: this.dateAdapter.getDate(date),
      date: date,
      dateRange: new DateRange<D>(null, null),
    };

    this.selectedDataChange.emit(data);
    this.closeDatepicker.emit(this.dateFilterType === DateFilterEnum.Quarter);
  }

  handleYearSelected(date: D): void {
    const data: ISelectedDate<D> = {
      dateFilterType: this.dateFilterType,
      year: this.dateAdapter.getYear(date),
      quarter: Quarters.Q1,
      month: this.dateAdapter.getMonth(date),
      day: this.dateAdapter.getDate(date),
      date: date,
      dateRange: new DateRange<D>(null, null),
    };

    this.selectedDataChange.emit(data);
    this._yearSelectedInMultiYearView(date);

    this.closeDatepicker.emit(this.dateFilterType === DateFilterEnum.Year);
  }

  getMatCalendarView(value: DateFilterEnum): string {
    switch (value) {
      case DateFilterEnum.Year:
        return KkmMatCalendarView.MultiYear;

      case DateFilterEnum.Quarter:
        return KkmMatCalendarView.Quarter;

      case DateFilterEnum.Month:
        return KkmMatCalendarView.Year;

      case DateFilterEnum.Day:
        return KkmMatCalendarView.Month;

      default: throw new Error(`Unknown DateFilterEnum value: ${value}`);
    }
  }

  goToDateInView(date: D, nextView: DateFilterEnum) {
    this.activeDate = date;

    switch (this.dateFilterType) {
      case DateFilterEnum.Year:
        // @ts-ignore
        this.currentView = this.getMatCalendarView(DateFilterEnum.Year);
        break;

      case DateFilterEnum.Quarter:
        if (nextView === DateFilterEnum.Month || nextView === DateFilterEnum.Day) {
          return;
        }

        // @ts-ignore
        this.currentView = this.getMatCalendarView(nextView);
        break;

      case DateFilterEnum.Month:
        if (nextView === DateFilterEnum.Day) {
          return;
        }

        if (nextView === DateFilterEnum.Quarter) {
          // @ts-ignore
          this.currentView = this.getMatCalendarView(DateFilterEnum.Month);
          return;
        }

        // @ts-ignore
        this.currentView = this.getMatCalendarView(nextView);
        break;

      case DateFilterEnum.Day:
        if (nextView === DateFilterEnum.Quarter) {
          // @ts-ignore
          this.currentView = this.getMatCalendarView(DateFilterEnum.Month);
          return;
        }

        // @ts-ignore
        this.currentView = this.getMatCalendarView(nextView);
        break;
    }
  }
}
