import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {SessionService} from "./session.service";
import {catchError} from "rxjs/operators";

@Injectable()
export class TokenKeycloakInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog,
              public sessionService: SessionService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.sessionService.tokens?.accessToken
    const authReq = accessToken ? req.clone({
      headers: req.headers.set('Authorization',  `Bearer ${accessToken}`)
    }) : req
    return next
      .handle(authReq)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.handle401Error(authReq, next, error);
          }

          return throwError(error);
        }),
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
    this.dialog.closeAll();
    this.sessionService.redirectToAuth();

    return throwError(error);
  }
}
