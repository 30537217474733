import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITaxpayer, IStore } from '@common/interfaces';
import { CashRegisterService } from '../../../services/cash-register.service';

@Component({
  selector: 'kkm-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.styl']
})
export class StoreListComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean = true;
  stores: IStore[] = [];
  filteredStores: IStore[] = [];
  @Input() taxpayer: ITaxpayer;
  constructor(
    private fb: FormBuilder,
    private crService: CashRegisterService,
  ) {}

  ngOnInit() {
    this.setForm();
    this.loadData();
  }

  get isSearchStrFilterApplied(): boolean {
    const searchStr = this.form.get('searchStr').value;
    return searchStr != null && searchStr.trim() !== '';
  }

  setForm(): void {
    this.form = this.fb.group({
      searchStr: [''],
    });
  }

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      const stores = await this.crService.getCashRegisters(this.taxpayer.id).toPromise();
      this.stores = stores;
      this.filteredStores = stores;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }

  }

  clearSearch(ev: MouseEvent):void {
  }

}
