import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ICodeName, ICompanyISNAKData, IPersonCredentials } from '@common/interfaces';
import { onlyNumber } from '@kkm-ui/utils/validation';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataAlreadyExistErrors, LegalPersonType, RegistrationMode, RegistrationStep } from '@common/enums';
import { BusinessObjectsDictionaryService } from '../../services/dictionaries/business-objects-dictionary.service';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'kkm-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.styl'],
})
export class RegistrationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tinPattern: string;
  @Input() isLoading: boolean = false;
  @Input() registrationMode: RegistrationMode;
  @Input() registrationStep: RegistrationStep = RegistrationStep.Account;
  @Input() registrationErrors: string[] = [];

  @Output() formValue: EventEmitter<IPersonCredentials> = new EventEmitter();
  @Output() setRegistrationStep: EventEmitter<RegistrationStep> = new EventEmitter<RegistrationStep>();
  registerForm: FormGroup;
  passwordHide = true;
  confirmPasswordHide = true;
  companyNotFoundError = false;
  searchTaxAuthorityDepartments: string = "";
  searchLegalPersonActivities: string = "";
  destroyed$: Subject<void> = new Subject();
  registrationStepEnum = RegistrationStep;
  legalPersonType = LegalPersonType;

  dataAlreadyExistErrors = DataAlreadyExistErrors;
  onlyNumber = onlyNumber;
  registrationModeEnum = RegistrationMode;

  private company: ICompanyISNAKData;
  private tinSubscription: Subscription = Subscription.EMPTY;
  private taxAuthorityDepartmentSubscription: Subscription = Subscription.EMPTY;
  private activityCodeSubscription: Subscription = Subscription.EMPTY;
  private legalPersonTypeSubscription: Subscription = Subscription.EMPTY;

  constructor(private fb: FormBuilder,
              private businessObjectsDictionaryService: BusinessObjectsDictionaryService,
              private companyService: CompanyService) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      firstName: [
        {value: null, disabled: this.isLoading},
        [Validators.required, Validators.pattern(/[a-zA-Zа-яА-Я-\s]/)]
      ],
      middleName: [
        {value: null, disabled: this.isLoading},
        Validators.pattern(/[a-zA-Zа-яА-Я-\s]/)
      ],
      lastName: [
        {value: null, disabled: this.isLoading},
        [Validators.required, Validators.pattern(/[a-zA-Zа-яА-Я-\s]/)]
      ],
      login: [
        {value: null, disabled: this.isLoading},
        [
          Validators.required,
          Validators.email,
          this.emailSpecialCharactersValidator(),
        ]],
      password: [
        {value: null, disabled: this.isLoading},
        [Validators.required, this.passwordValidator()]
      ],
      legalPerson: new FormGroup({
        tin: new FormControl(
          {value: null, disabled: this.isLoading},
          [Validators.required, Validators.pattern(this.tinPattern), Validators.maxLength(14)],
        ),
        companyName: new FormControl(
          {value: null, disabled: this.isLoading || this.registrationMode === RegistrationMode.Auto},
          Validators.required
        ),
        type: new FormControl(
          {value: LegalPersonType.Entity, disabled: this.isLoading || this.registrationMode === RegistrationMode.Auto},
          Validators.required
        ),
        taxAuthorityDepartment: new FormControl(
          {value: null, disabled: this.isLoading},
          Validators.required
        ),
        activityCode: new FormControl(
          {value: null, disabled: this.isLoading},
          Validators.required
        ),
        legalAddress: new FormControl(
          {value: null, disabled: this.isLoading},
          Validators.required
        )
      }),
    });

    if (this.registrationMode === RegistrationMode.Auto) {
      this.activityCode.clearValidators();

      this.tinSubscription = this.tin
        .valueChanges
        .pipe(
          debounceTime(1000)
        )
        .subscribe(async (tinValue: string) => {
          this.companyNotFoundError = false;

          if (this.tin.invalid) {
            return;
          }

          this.company = await this.companyService.fetchCompanyByTIN(tinValue).toPromise();
          this.companyNotFoundError = this.company == null;
          this.companyName.setValue(this.company?.companyName);
          this.taxAuthorityDepartment.setValue(this.company?.taxAuthorityDepartment);
          this.activityCode.setValue(this.company?.activityCode);
          this.legalAddress.setValue(this.company?.legalAddress);
        });
    } else {
      this.taxAuthorityDepartmentSubscription = this.taxAuthorityDepartment.valueChanges.subscribe((val: string) => this.searchTaxAuthorityDepartments = val);
      this.activityCodeSubscription = this.activityCode.valueChanges.subscribe((val: string) => this.searchLegalPersonActivities = val);
      this.legalPersonTypeSubscription = this.registerForm
        .get('legalPerson')
        .get('type')
        .valueChanges
        .subscribe((value: LegalPersonType) => {
          switch (value) {
            case LegalPersonType.Entity:
              this.companyName.setValue(null);
              break;
            case LegalPersonType.Individual:
              const form: IPersonCredentials = { ...this.registerForm.getRawValue() };
              const userName = [form.lastName.trim(), form.firstName.trim(), form.middleName]
                .filter((part: string) => part != null && part !== '')
                .join(' ');
              this.companyName.setValue(userName);
              break;
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors = changes.registrationErrors;
    if (errors != null && !errors.isFirstChange()) {
      this.tweakValidationRulesByErrors(errors.currentValue);
    }
  }

  ngOnDestroy() {
    this.legalPersonTypeSubscription.unsubscribe();
    this.activityCodeSubscription.unsubscribe();
    this.taxAuthorityDepartmentSubscription.unsubscribe();
    this.tinSubscription.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  tweakValidationRulesByErrors(errors: string[]): void {
    const loginControlValidators = [Validators.required, Validators.email, this.emailSpecialCharactersValidator()];
    if (errors.includes(DataAlreadyExistErrors.EmailNotUnique)) {
      loginControlValidators.push(this.equalToUsedValueValidator(this.login.value));
    }

    this.login.clearValidators();
    this.login.setValidators(loginControlValidators);
    this.login.updateValueAndValidity();
    this.login.markAsTouched();

    const tinControlValidators = [Validators.required, Validators.pattern(this.tinPattern), Validators.maxLength(14)];
    if (errors.includes(DataAlreadyExistErrors.TinNotUnique)) {
      tinControlValidators.push(this.equalToUsedValueValidator(this.tin.value));
    }

    this.tin.clearValidators();
    this.tin.setValidators(tinControlValidators);
    this.tin.updateValueAndValidity();
    this.tin.markAsTouched();
  }

  handleRegister() {
    // console.log('form: ', this.registerForm);
    // return;

    if (this.registrationMode === RegistrationMode.Manual && this.registrationStep === RegistrationStep.Account) {
      this.goToCompanyRegistrationStep();
      return;
    }

    if (this.registerForm.valid) {
      this.isLoading = true;
      const form: IPersonCredentials = { ...this.registerForm.getRawValue() };
      form.userName = [form.lastName.trim(), form.firstName.trim(), form.middleName]
        .filter((part: string) => part != null && part !== '')
        .join(' ');
      form.login = this.registerForm.value.login.trim();
      form.legalPerson.type = this.registrationMode === RegistrationMode.Auto
        ? this.company.type
        : form.legalPerson.type;
      form.legalPerson.vatPayer = this.company?.vatPayer;

      this.formValue.emit(form);
    }
  }

  get firstName(): FormControl {
    return this.registerForm.get('firstName') as FormControl;
  }

  get lastName(): FormControl {
    return this.registerForm.get('lastName') as FormControl;
  }

  get middleName(): FormControl {
    return this.registerForm.get('middleName') as FormControl;
  }

  get companyName(): FormControl {
    const legalPerson = this.registerForm.get('legalPerson') as FormGroup;
    return legalPerson.get('companyName') as FormControl;
  }

  get legalAddress(): FormControl {
    const legalPerson = this.registerForm.get('legalPerson') as FormGroup;
    return legalPerson.get('legalAddress') as FormControl;
  }

  get activityCode(): FormControl {
    const legalPerson = this.registerForm.get('legalPerson') as FormGroup;
    return legalPerson.get('activityCode') as FormControl;
  }

  get tin(): FormControl {
    const legalPerson = this.registerForm.get('legalPerson') as FormGroup;
    return legalPerson.get('tin') as FormControl;
  }

  get login(): FormControl {
    return this.registerForm.get('login') as FormControl;
  }

  get password(): FormControl {
    return this.registerForm.get('password') as FormControl;
  }

  get fiscalMemoryNumber(): FormControl {
    return this.registerForm.get('fiscalMemoryNumber') as FormControl;
  }

  get taxAuthorityDepartment(): FormControl {
    const legalPerson = this.registerForm.get('legalPerson') as FormGroup;
    return legalPerson.get('taxAuthorityDepartment') as FormControl;
  }

  get filteredTaxAuthorityDepartments() {
    return this.businessObjectsDictionaryService.taxAuthorityDepartments
      .filter((codes: ICodeName) => codes.name.toLowerCase().includes(this.searchTaxAuthorityDepartments.toLowerCase()));
  }

  get filteredLegalPersonActivities() {
    const term = this.searchLegalPersonActivities.toLowerCase();
    return this.businessObjectsDictionaryService.legalPersonActivities
      .filter((codes: ICodeName) => codes.name.toLowerCase().includes(term) || (codes.code as string).includes(term));
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const v = String(control.value);
      if (v.length < 6 || !v.match(/[!_\-\^\&]/gi)) {
        return {'password': true};
      }
      return null;
    };
  }

  isFocused(el: Element) {
    return document.activeElement === el;
  }

  displayTaxAuthorityDepartment(code: number): string {
    return this.getDisplayNameByCode(code, this.businessObjectsDictionaryService.taxAuthorityDepartments);
  }

  displayActivityCode(code: string): string {
    if (code == null) {
      return '';
    }

    const name = this.getDisplayNameByCode(code, this.businessObjectsDictionaryService.legalPersonActivities);
    return `${code} ${name}`;
  }

  private goToCompanyRegistrationStep(): void {
    if (!this.lastName.valid || !this.firstName.valid || !this.login.valid || !this.password.valid) {
      this.lastName.markAsDirty();
      this.lastName.markAsTouched();

      this.firstName.markAsDirty();
      this.firstName.markAsTouched();

      this.login.markAsDirty();
      this.login.markAsTouched();

      this.password.markAsDirty();
      this.password.markAsTouched();
      return;
    }

    if (this.registrationErrors == null || this.registrationErrors.length === 0) {
      this.registerForm.markAsUntouched();
    }

    this.setRegistrationStep.emit(RegistrationStep.Company);
  }

  private getDisplayNameByCode(code: number | string, dictionary: ICodeName[]): string {
    if (code == null) {
      return '';
    }

    const result = dictionary.find((codes: ICodeName) => codes.code === code);
    return result == null ? '' : result.name;
  }

  private equalToUsedValueValidator(usedValue: string): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (usedValue == null || usedValue === '') {
        return null;
      }

      return control.value === usedValue
        ? { usedValue: { usedValue: usedValue, actualValue: control.value } }
        : null;
    };
  }

  private emailSpecialCharactersValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      const value = control.value;
      if (value == null || value.trim() === '') {
        return null;
      }

      const specialCharacters = ['/'];
      const hasSpecialCharacter = specialCharacters.some((character: string) => value.includes(character));
      return hasSpecialCharacter
        ? { hasSpecialCharacter: { hasSpecialCharacter: true } }
        : null;
    };
  }

  private validateQuantity(control: AbstractControl) {
    const values = (control?.value?.toString().includes('.'))
      ? (control?.value?.toString().split('.').pop().length)
      : 0;
    return values > 2 ? { 'validateQuantityInvalid': true } : null;
  }
}
