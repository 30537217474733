import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';

import {TranslocoModule} from '@ngneat/transloco'
import { IMaskModule } from 'angular-imask';

import {OverlayModule} from '@angular/cdk/overlay';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {RouterModule} from '@angular/router';


import {IconComponent} from './components/icon/icon.component';
import {IconModule} from './components/icon/icon.module';
import {InputPopoverComponent} from './components/input-popover/input-popover.component';
import {MoneyComponent} from './components/money/money.component';

import {CentsToDollarsPipe} from './pipes/cents-to-dollars/cents-to-dollars.pipe';
import {ReversePipe} from './pipes/reverse/reverse.pipe';

import {RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';


import {SidebarComponent} from './components/sidebar/sidebar.component';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {ReceiptDetailsComponent} from './components/receipt-details/receipt-details.component';
import {InfoItemComponent} from './components/info-item/info-item.component';
import {CrDetailsComponent} from './components/cr-details/cr-details.component';


import {StoresLocatorComponent} from './components/stores-locator/stores-locator.component';
import {StoreListComponent} from './components/stores-locator/store-list/store-list.component';
import {ReceiptListComponent} from './components/stores-locator/receipt-list/receipt-list.component';
import {EntityStatsComponent} from './components/stores-locator/entity-stats/entity-stats.component';
import {ShiftReportComponent} from './components/shift-report/shift-report.component';
import {ShiftOperationsTableComponent} from "./components/shift-operations-table/shift-operations-table.component";

import {SalesAnalyticsComponent} from './components/sales-analytics/sales-analytics.component';
import {CrSummaryComponent} from './components/sales-analytics/cr-summary/cr-summary.component';
import {ReceiptsSummaryComponent} from './components/sales-analytics/receipts-summary/receipts-summary.component';
import {ShiftListComponent} from './components/sales-analytics/shift-list/shift-list.component';
import {ReceiptsGridComponent} from './components/sales-analytics/receipts-grid/receipts-grid.component';
import {RevenueChartComponent} from './components/sales-analytics/revenue-chart/revenue-chart.component';
import {RevenueFilterComponent} from './components/sales-analytics/revenue-filter/revenue-filter.component';
import {SummaryInfoComponent} from './components/sales-analytics/summary-info/summary-info.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {RecoveryComponent} from './components/recovery/recovery.component';
import {SkeletonComponent} from './components/skeleton/skeleton.component';

import { CurrencyCatalogFilterComponent } from './components/currency-catalog-filter/currency-catalog-filter.component';
import { CurrencyCatalogTableComponent } from './components/currency-catalog-table/currency-catalog-table.component';

import {ReceiptService} from './services/receipt.service';
import {CashRegisterService} from './services/cash-register.service';
import {ShiftService} from './services/shift.service';
import {StatService} from './services/stat.service';
import { CompanyService } from './services/company.service';
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {NotificationComponent} from "./components/notification/notification.component";
import {LangSelectComponent} from './components/lang-select/lang-select.component';
import {UserMenuComponent} from "./components/user-menu/user-menu.component";
import {NoDataLabelComponent} from "./components/no-data-label/no-data-label.component";
import {CashRegisterInfoComponent} from './components/cr-info/cr-info.component';
import {CrRegHistoryComponent} from './components/cr-reg-history/cr-reg-history.component';
import {DatePickerInputDirective} from "./directives/date-picker-input.directive";
import {DateRangePickerInputDirective} from "./directives/date-range-picker-input.directive";
import {DisableControlDirective} from "./directives/disableControl.directive";
import {ReceiptGridService} from './services/receipt-grid.service';
import {DateComponent} from './components/date/date.component';
import {NgForTrackByIdDirective} from './directives/ng-for-track-by-id.directive';
import {NgForTrackByFieldDirective} from './directives/ng-for-track-by-field.directive';
import {FocusFirstInvalidDirective} from './directives/focus-first-invalid.directive';
import {ClearIfCorrectDirective} from './directives/clear-if-correct.directive';
import { AutocompletePositionDirective } from './directives/autocomplete-position.directive';
import {RegistrationComponent} from './components/registration/registration.component';
import {DatepickirMaskDirective} from './directives/datepicker-mask.directive';
import {KkmDatepickerComponent} from './components/kkm-datepicker/kkm-datepicker.component';
import {KkmCalendarComponent} from './components/kkm-datepicker/kkm-calendar/kkm-calendar.component';
import {KkmDatepickerModule} from './components/kkm-datepicker/kkm-datepicker.module';
import {SpacesPipe} from './pipes/spaces/spaces.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayPanelModule } from './components/overlay-panel/overlay-panel.module';
import { TaxpayersStatsComponent } from './components/taxpayers-stats/taxpayers-stats.component';
import { MatRadioModule } from '@angular/material/radio';
import { CurrencyCatalogService } from './services/currency-catalog.service';
import { QRCodeModule } from 'angularx-qrcode';
import { AbbreviatePipe } from './pipes/abbreviate/abbreviate.pipe';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { ScrollToTopOnByEventDirective } from './directives/scroll-to-top-on-by-event.directive';
import {MultipleSelectComponent} from "./components/multiple-select/multiple-select.component";
import {TaxAuthorityDepartmentValidator} from "./validators/tax-authority-department-validator";
import {LegalPersonActivityValidator} from "./validators/legal-person-activity-validator";

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};


@NgModule({
  declarations: [
    IconComponent,
    InputPopoverComponent,
    NotificationComponent,
    MoneyComponent,
    CentsToDollarsPipe,
    ReversePipe,
    SpacesPipe,
    AbbreviatePipe,
    SidebarComponent,
    ReceiptDetailsComponent,
    InfoItemComponent,
    CrDetailsComponent,
    ShiftReportComponent,
    ShiftOperationsTableComponent,

    PaginatorComponent,
    FileUploadComponent,

    StoresLocatorComponent,
    ReceiptListComponent,
    StoreListComponent,
    EntityStatsComponent,
    SummaryInfoComponent,
    ReceiptsSummaryComponent,
    CrSummaryComponent,
    ShiftListComponent,
    ReceiptsGridComponent,
    RevenueChartComponent,
    RevenueFilterComponent,
    SalesAnalyticsComponent,
    LangSelectComponent,
    UserMenuComponent,
    NoDataLabelComponent,
    CashRegisterInfoComponent,
    CrRegHistoryComponent,
    DatePickerInputDirective,
    DateRangePickerInputDirective,
    DisableControlDirective,
    DateComponent,
    NgForTrackByIdDirective,
    NgForTrackByFieldDirective,
    FocusFirstInvalidDirective,
    ClearIfCorrectDirective,
    AutocompletePositionDirective,
    ScrollToTopOnByEventDirective,
    RegistrationComponent,
    DatepickirMaskDirective,
    PaginationComponent,
    RecoveryComponent,
    TaxpayersStatsComponent,
    MultipleSelectComponent,
    SkeletonComponent,
    AbbreviatePipe,
    InputNumberComponent,
    CurrencyCatalogFilterComponent,
    CurrencyCatalogTableComponent,

    TaxAuthorityDepartmentValidator,
    LegalPersonActivityValidator,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    IconModule,
    OverlayModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    TranslocoModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    KkmDatepickerModule,
    OverlayPanelModule,
    RouterModule,
    IMaskModule,
    QRCodeModule
  ],
  exports: [
    IconComponent,
    InputPopoverComponent,
    NotificationComponent,
    CentsToDollarsPipe,
    ReversePipe,
    SpacesPipe,
    AbbreviatePipe,
    SidebarComponent,
    ReceiptDetailsComponent,
    InfoItemComponent,
    CrDetailsComponent,
    ShiftReportComponent,
    ShiftOperationsTableComponent,
    MoneyComponent,
    PaginatorComponent,
    FileUploadComponent,

    StoresLocatorComponent,
    ReceiptListComponent,
    StoreListComponent,
    EntityStatsComponent,
    SummaryInfoComponent,
    ReceiptsSummaryComponent,
    CrSummaryComponent,
    ShiftListComponent,
    ReceiptsGridComponent,
    RevenueChartComponent,
    RevenueFilterComponent,
    SalesAnalyticsComponent,
    LangSelectComponent,
    UserMenuComponent,
    NoDataLabelComponent,
    CashRegisterInfoComponent,
    CrRegHistoryComponent,
    DatePickerInputDirective,
    DateRangePickerInputDirective,
    DisableControlDirective,
    DateComponent,
    NgForTrackByIdDirective,
    NgForTrackByFieldDirective,
    FocusFirstInvalidDirective,
    ClearIfCorrectDirective,
    AutocompletePositionDirective,
    ScrollToTopOnByEventDirective,
    RegistrationComponent,
    DatepickirMaskDirective,
    KkmDatepickerModule,
    OverlayPanelModule,
    PaginationComponent,
    RecoveryComponent,
    TaxpayersStatsComponent,
    MultipleSelectComponent,
    CurrencyCatalogFilterComponent,
    CurrencyCatalogTableComponent,
    SkeletonComponent,
    InputNumberComponent,

    TaxAuthorityDepartmentValidator,
    LegalPersonActivityValidator,
  ],
})
export class UiModule {
  static forRoot(env: any): ModuleWithProviders<any> {
    return {
      ngModule: UiModule,
      providers: [
        {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
        ReceiptService,
        ReceiptGridService,
        CashRegisterService,
        ShiftService,
        StatService,
        CompanyService,
        CurrencyCatalogService,
        {provide: 'env', useValue: env},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
      ]
    }
  }
}
