import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import Chart from 'chart.js/dist/Chart.js';
import {ICrList, IReceiptStatistics, ITicketStatistics} from '@common/interfaces';
import * as moment from 'moment';
import { translate, TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '../../../services/translate.service';
import { Subscription } from 'rxjs';
import { merge, cloneDeep } from 'lodash';
import {CalculationTypesCodes} from "@common/enums";
import {DEFAULT_LANG, LANG_LIST} from "@common/constants";

@Component({
  selector: 'kkm-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.styl'],
  providers: [CurrencyPipe],
})
export class RevenueChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cashRegisters: ICrList[] = [];
  @Input() statistics: ITicketStatistics;
  @Input() agentStatistics: ITicketStatistics;
  @Input() mainFilter;
  @Input() isLoading: boolean;
  @Input() isYaTaxiPayer: boolean = false;
  @ViewChild('chart', {static: true}) chartElementRef: ElementRef;
  Linechart: Chart;
  activeLang: string;
  locale: string;
  engMonthsLabels: string[];
  localeMothsLabels: string[];

  CANVAS_ID = 'taxpayer_sales_statistics';
  langSubscription: Subscription = Subscription.EMPTY;

  constructor(private currencyPipe: CurrencyPipe,
              private translateService: TranslateService,
              private translocoService: TranslocoService,) {
  }

  ngOnInit(): void {
    this.locale = this.getLocaleByLang(this.translocoService.getActiveLang());
    this.engMonthsLabels = moment.localeData('en').monthsShort();

    this.langSubscription = this.translocoService
      .langChanges$
      .subscribe((value: string) => {
        this.activeLang = value;
        this.locale = this.getLocaleByLang(this.translocoService.getActiveLang());
        this.localeMothsLabels = moment.localeData(value).monthsShort();
        this.Linechart?.update();
      });

    this.setChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['statistics']) {
      if (this.Linechart?.data?.datasets) {
        this.Linechart.data.labels = this.labels;
        this.Linechart.data.datasets[0].data = this.data;
        if (this.mainFilter.dateFilterType === 'MONTH') {
          this.Linechart.scales['x-axis-0'].options.time.stepSize = 1;
          this.Linechart.scales['x-axis-0'].options.time.unit = 'day';
          this.Linechart.scales['x-axis-1'].options.display = false;
        } else if (this.mainFilter.dateFilterType === 'YEAR') {
          this.Linechart.scales['x-axis-0'].options.time.stepSize = 1;
          this.Linechart.scales['x-axis-0'].options.time.unit = 'month';
          this.Linechart.scales['x-axis-1'].options.display = false;
        } else if (this.mainFilter.dateFilterType === 'QUARTER') {
          this.Linechart.scales['x-axis-0'].options.time.stepSize = 5;
          this.Linechart.scales['x-axis-0'].options.time.unit = 'day';
          this.Linechart.scales['x-axis-1'].options.display = true;
        }
        this.Linechart?.update();
      }
    }
  }

  ngOnDestroy() {
    this.Linechart && this.Linechart.destroy();
    this.langSubscription.unsubscribe();
  }

  get hasNotaryOrLawyerCashRegister(): boolean {
    return this.cashRegisters.some((cr: ICrList) => cr.calcItemAttributes.includes(CalculationTypesCodes.StateTax));
  }

  get data() {
    const stats = this.getIncomeStats(this.statistics?.dailyStatistics, 'income');
    const agentStats = this.getIncomeStats(this.agentStatistics?.dailyStatistics, 'agentIncome');
    const mergedStats = merge(cloneDeep(agentStats), cloneDeep(stats));

    return Object.keys(mergedStats || []).map(key => ({
      t: moment(key),
      y: (mergedStats[key].income / 100) || 0 + (mergedStats[key].agentIncome / 100) || 0,
    }));
  }

  get labels() {
    return Object.keys(merge(cloneDeep(this.statistics.dailyStatistics), cloneDeep(this.agentStatistics.dailyStatistics)));
  }

  get chartTitle(): string {
    const {dateFilterType, month, quarter, year} = this.mainFilter;
    if (dateFilterType === 'YEAR') {
      return year;
    } else if (dateFilterType === 'QUARTER') {
      return `${quarter} ${year}`;
    } else {
      return `${this.translate(this.getMoth(month).toLowerCase())} ${year}`;
    }
  }

  getColors() {
    const cs = getComputedStyle(document.documentElement);
    return {
      borderColor: cs.getPropertyValue('--lc-border-color').trim(),
      pointBackgroundColor: cs.getPropertyValue('--lc-point-background-color').trim(),
      pointBorderColor: cs.getPropertyValue('--lc-point-border-color').trim(),
      pointHoverBackgroundColor: cs.getPropertyValue('--lc-point-hover-background-color').trim(),
      backgroundColor: cs.getPropertyValue('--lc-background-color').trim(),
      tooltipBorderColor: cs.getPropertyValue('--lc-tooltip-border-color').trim(),
      tooltipTitleFontColor: cs.getPropertyValue('--lc-tooltip-title-font-color').trim(),
      tooltipBodyFontColor: cs.getPropertyValue('--lc-tooltip-body-font-color').trim(),
      tooltipBackgroundColor: cs.getPropertyValue('--lc-tooltip-background-color').trim(),
    }
  }


  setChart(): void {
    const colors = this.getColors();
    this.Linechart = new Chart(this.chartElementRef.nativeElement, {
      type: 'line',
      data: {
        datasets: [
          {
            data: this.data,
            borderColor: colors.borderColor,
            borderWidth: 2,
            pointBackgroundColor: colors.pointBackgroundColor,
            pointBorderColor: colors.pointBorderColor,
            pointBorderWidth: 4,
            pointHoverBorderWidth: 4,
            pointHoverBackgroundColor: colors.pointHoverBackgroundColor,
            pointRadius: 0,
            pointHoverRadius: 9,
            pointHitRadius: 9,
            backgroundColor: colors.backgroundColor,
            cubicInterpolationMode: 'monotone',
          }
        ]
      },
      options: {
        layout: {
          padding: {
            bottom: 16,
            top: 80,
            left: 20,
          }
        },
        responsive: true,
        aspectRatio: 1,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          borderColor: colors.tooltipBorderColor,
          borderWidth: 0.5,
          titleFontSize: 16,
          titleFontColor: colors.tooltipTitleFontColor,
          titleFontFamily: 'Inter',
          bodyFontColor: colors.tooltipBodyFontColor,
          bodyFontFamily: 'Inter',
          bodyFontSize: 13,
          displayColors: false,
          titleAlign: 'center',
          bodyAlign: 'center',
          cornerRadius: 4,
          caretPadding: 16,
          caretSize: 10,
          backgroundColor: colors.tooltipBackgroundColor,
          xPadding: 60,
          yPadding: 16,
          callbacks: {
            title: (tooltipItem) => {
              return this.currencyPipe.transform(tooltipItem[0].yLabel, '', '', '1.0-0');
            },
            beforeBody: (tooltipItem) => {
              return moment(tooltipItem[0].xLabel, null, this.activeLang).format('D MMMM');
            },
            label: () => '',
          }

        },
        spanGaps: true,
        maintainAspectRatio: false,

        scales: {
          yAxes: [{
            display: true,
            position: 'right',
            ticks: {
              beginAtZero: true,
              // stepSize: 1,
              callback: value => {
                return this.currencyPipe.transform(value, "", "", "1.0-0", this.locale);
              }
            }
          }],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              type: 'time',
              ticks: {
                display: true,
                labelOffset: 0,
                callback: this.mainFilter.dateFilterType === 'YEAR' ? this.translateMonths.bind(this) : null,
              },
              time: {
                stepSize: this.mainFilter.dateFilterType === 'QUARTER' ? 5 : 1,
                unit: this.mainFilter.dateFilterType === 'YEAR' ? 'month' : 'day',
                displayFormats: {
                  day: 'D',
                  month: 'MMM',
                },
                isoWeekday: true,
              },
              display: true,
            },
            {
              gridLines: {
                display: false,
              },
              type: 'time',
              beginAtZero: true,
              position: 'center',
              ticks: {
                display: true,
                labelOffset: 0,
                callback: this.translateMonths.bind(this),
              },
              time: {
                stepSize: 1,
                unit: 'month',
                displayFormats: {
                  month: 'MMM',
                  quarter: 'MMM'
                },
              },
              display: this.mainFilter.dateFilterType !== 'YEAR',
            }
          ],
        }
      },
    });
  }

  private getMoth(month: any) {
    return moment(month + 1, 'M').format('MMMM');
  }

  private translate(key: string): string {
    return translate(`common.months.${key}`);
  }

  private translateMonths(monthLabel: string, monthIndex: number): string {
    const engMonthIndex = this.engMonthsLabels.findIndex((engMonthLabel: string) => engMonthLabel === monthLabel);
    return engMonthIndex > -1
      ? this.localeMothsLabels[engMonthIndex]
      : this.localeMothsLabels[monthIndex];
  }

  private getIncomeStats(stats: {[key: string]: IReceiptStatistics}, key: string) {
    const incomeStats = {};
    Object.keys(stats || []).forEach(s => incomeStats[s] = {[key]: stats[s].income});

    return incomeStats;
  }

  private getLocaleByLang(lang: string): string {
    const locale = LANG_LIST.find((lng) => lng.value === lang);
    return locale ? locale.langCode : DEFAULT_LANG.langCode;
  }

  get totalRevenue() {
    return (this.statistics?.overallStatistics?.revenue || 0) + (this.agentStatistics?.overallStatistics?.revenue || 0);
  }

  get totalGovernmentFee(): number {
    return this.statistics?.overallStatistics?.totalGovernmentFee || 0;
  }

  get totalSt() {
    return (this.statistics?.overallStatistics?.st || 0) + (this.agentStatistics?.overallStatistics?.st || 0);
  }

  get totalVat() {
    return (this.statistics?.overallStatistics?.vat || 0) + (this.agentStatistics?.overallStatistics?.vat || 0);
  }

  get totalReceipts() {
    return (this.statistics?.overallStatistics?.receipts || 0) + (this.agentStatistics?.overallStatistics?.receipts || 0);
  }

  get averageReceipt() {
    const revenue = (this.statistics?.overallStatistics?.revenue || 0) + (this.agentStatistics?.overallStatistics?.revenue || 0);
    const receipts = (this.statistics?.overallStatistics?.receipts || 0) + (this.agentStatistics?.overallStatistics?.receipts || 0);
    return revenue / receipts;
  }
}
