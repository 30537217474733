import { Component, Input } from '@angular/core';

@Component({
  selector: 'kkm-skeleton',
  template: `<div class="loader" [style.width]="width" [style.height]="size"></div>`,
  styleUrls: ['./skeleton.component.styl']
})
export class SkeletonComponent {

  @Input() width: string = '100%';
  @Input() size: string = '1em';

}