<div class="d-flex flex-column">
  <div class="d-flex flex-row align-items-center p_l-8 p_r-6 p_y-4 search-container">
    <form [formGroup]="form" class="m_r-4 flex-grow-1">
      <mat-form-field appearance="fill" class="kkm-form-field compact w-fluid">
        <mat-icon matPrefix svgIcon="search:magnifyingGlass" [class.search-applied]="isSearchStrFilterApplied"></mat-icon>
        <input matInput type="text" autocomplete="off" formControlName="searchStr" placeholder="Search by address or cash register #">
        <button *ngIf="form.get('searchStr').value" type="button" class="kkm-button kkm-icon-button" matSuffix mat-icon-button (click)="clearSearch($event)">
          <mat-icon svgIcon="close:narrowInput" class="small"></mat-icon>
        </button>
      </mat-form-field>
    </form>
    <div class="more-info">
      <mat-icon svgIcon="more:horizontal"></mat-icon>
    </div>
  </div>

  <div class="d-flex flex-column">
    <ng-container *ngFor="let store of filteredStores; trackById">
      <mat-expansion-panel (opened)="true">
        <mat-expansion-panel-header>
          <div class="store-info">
            <div class="store-title">{{store.name}}</div>
          </div>
          <kkm-entity-stats></kkm-entity-stats>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-container>

  </div>
</div>
