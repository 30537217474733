import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { icons } from './icon.constants';

@Component({
  selector: 'kkm-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.styl'],
  host: {
    class: 'kkm-icon'
  },
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  @Input() name: string;
  @Input() matStyles: any;
  @Input() matClass: string;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    (icons || []).forEach((iconSet) => {
      (iconSet.items || []).forEach((icon) => {
        iconRegistry.addSvgIconInNamespace(
          iconSet.namespace,
          icon.name,
          sanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${icon.url}`),
        );
      });
    });
  }
}