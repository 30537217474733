import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaces'
})
export class SpacesPipe implements PipeTransform {

  transform(values: number): string {
    if (values || values === 0) {
      return this.spaces(values);
    }
  }

  spaces(val: number) {
    return String(val).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
  }

}
