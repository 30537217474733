import {
  TaxSystemEnum,
  KktStatusEnum,
  TaxTypes,
  VatTypes,
  StTypes,
  RegHistoryRecordType,
  LegalPersonType,
  BackendReceiptTypes,
  CurrencyType,
  PaymentTypeMarks,
  CalculationTypesCodes,
  FormatAndLogicControlErrorsSeverityType,
} from '../enums';
import { EnumTaxpayersRisks } from '../../apps/ais-no/src/app/inner-app/components/taxpayers/taxpayers.interfaces';

export * from './shift';
export * from './taxi-map';

export type EntityID = string | number;

export interface IKeyValue {
  key: string;
  value: string;
}

export interface ITranslocoKeyValue extends IKeyValue {
}

export interface IIdName {
  id: EntityID;
  name: string;
}

export interface ICodeName extends IIdName {
  code: number | string;
}

export interface ICodeNameCheckbox extends ICodeName{
  checked: boolean;
}

export interface ISubmitEvent {
  item: ICodeName;
  close: () => void;
  error: (code: number) => void;
}

export interface ITaxpayersItemStat {
  agentCashRegisters: number;
  cashRegisters: number;
  checks: number;
  total: number;
  totalVat: number;
  average: number;
  risk: EnumTaxpayersRisks;
  riskValue: number;
  lastCheckSum: number;
}

export interface ITaxpayer {
  id: number;
  tin: string;
  name: string;
  stat: ITaxpayersItemStat;
  taxSystem: TaxSystemEnum;

  taxSystems: number[];
  activityCode: string;
  legalAddress: string;
  taxAuthorityDepartment: number;
  type: string;
  vatPayer: boolean;
  liquidated: boolean;
}

export interface IAddress {
  administrativeArea1: string;
  administrativeArea2: string;
  country: string;
  formattedString: string;
  locality: string;
  location: string;
  postalCode: string;
  route: string;
  streetNumber: string;
}

export interface IStatTax {
  code: number;
  correction: number;
  income: number;
  rate: number;
  refund: number;
  totalValue: number;
}

export interface IStat {
  avgTicket: number;
  income: number;
  ticketCorrectionCount: number;
  ticketIncomeCount: number;
  ticketRefundCount: number;
  totalCashIncome: number;
  totalCashRefund: number;
  totalCashlessIncome: number;
  totalCashlessRefund: number;
  totalCorrectIncome: number;
  totalCorrectRefund: number;
  totalIncome: number;
  totalRefund: number;
  totalTicketCount: number;
  totalVat20: number;
  vat: IStatTax[];
  st: IStatTax[];
}

export interface ICashRegister {
  createdDate: string;
  deviceType: string;
  fnSerialNumber: string;
  id: number;
  internalNumber: string;
  legalPersonId: number;
  location: ILocation;
  model: string;
  nomenclatureId: number;
  online: boolean;
  modifyDate: string;
  regDate: string;
  regNumber: string;
  sn: string;
  status: KktStatusEnum;
  taxSystems: number[];
  stats: any;
  totalStat: any;
  originalAddress: string;
  calcItemAttributes?: number[];
  name?: string;
}

export interface ICashRegisterDisplayOptions {
  showOnline: boolean;
  showStatus: boolean;
}

export interface IFNModel {
  fnNumber: string;
  validationCode: string;
}

export interface ILocation {
  address: IAddress;
  addresses: IAddress[];
  coord: ICoord;
  id: number;
  tin: string;
  cashRegisters: any[];
  lpId: number;
  name: string;
}

export interface IStore {
  id: number;
  name: string;
  lpId: number;
  coord: ICoord;
  totalStat: IStat;
  address: IAddress;
  addresses?: IAddress[];
  cashRegisters: ICashRegister[];
  originalAddress: string;
}

export interface IListResult {
  hasNext: boolean;
  page: number;
  size: number;
  total: number;
}


export interface ICoord {
  lat: number;
  lng: number;
}

// структура данных из ИСНАК - Информационная система налогового администрирования Кыргызстана
export interface ICompanyISNAKData {
  tin: string;
  companyName: string;
  type: LegalPersonType;
  legalAddress: string;
  taxAuthorityDepartment: number;
  activityCode: string;
  vatPayer: boolean;
}

export interface IPersonCredentials {
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  login: string;
  password: string;
  legalPerson: ICompanyISNAKData;
}

export interface IRegistryResponse {
  status: number;
  errors?: string[];
}

export interface ILoginInfo {
  login: string;
  password: string;
}

export interface IReceiptStatistics {
  avgTicket: number;
  income: number;
  revenue: number;
  ticketCorrectionCount: number;
  ticketIncomeCount: number;
  ticketRefundCount: number;
  totalCorrectIncome: number;
  totalCorrectRefund: number;
  totalIncome: number;
  totalRefund: number;
  totalTicketCount: number;
  totalVat20: number;
  vat20Correction: number;
  vat20Income: number;
  vat20Refund: number;
  ticketCounters: any;
  correctionCounters: any;
  vat: any[];
  st: any[];
}

export interface ITicketStatisticsApiResponse {
  ids: number[];
  dailyStats?: {
    [key: string]: IReceiptStatistics
  }
  count: number;
}

export interface IBoundaryDates {
  startDate: string;
  endDate: string;
}

export interface IOverallTicketStatistics {
  revenue: number;
  vat: number;
  st: number;
  receipts: number;
  averageReceipt: number;
  totalGovernmentFee: number;
}

export interface ITicketStatistics {
  dailyStatistics: {
    [key: string]: IReceiptStatistics
  }
  overallStatistics: IOverallTicketStatistics;
  originalDailyStatisticsCount: number;
  count: number;
  kktIds: number[];
}


// NEW

export interface IListResult {
  hasNext: boolean;
  total: number;
}


export interface IReceiptItem {
  goodCost: number;
  goodName: string;
  goodNomenclatureCode: string;
  calcAttributeCode: CalculationTypesCodes;
  goodPrice: number;
  goodQuantity: number;
  goodStCode: number;
  goodStRate: number;
  goodVatCode: number;
  goodVatRate: number;
  paymentType: PaymentTypeMarks;
  id: number;
  sgtin?: string;
}

export interface ITaxCounter {
  code: number;
  rate?: number;
  sum: number;
  type: TaxTypes
}

export interface IBaseCatalog {
  taxRates: ITaxRate[];
  items: IPositionBaseCatalog[];
}

export interface IPrincipalBaseCatalog extends IBaseCatalog {
  tin: string;
}

export interface IPositionBaseCatalog {
  code: number;
  taxSystemCode: number;
  name: string;
  vatCode: VatTypes;
  stCashCode: StTypes;
  stCashlessCode: StTypes;
  modified: string;
  isDisabledByCalcItemAttribute: boolean;
}

export interface ITaxRate {
  id?: number;
  code: number;
  taxRateValue: number;
  taxRateType: string;
  modifyDate?: string;
  active?: boolean;
  version?: number;
  baseCatalogueVersionId?: number;
}

export interface IBaseCatalogResponse {
  items: IPositionBaseCatalog[];
  taxes: {code: number, type: string, value: number}[];
}

export interface ITaxSystem {
  id: number;
  code: number;
  modifyDate: string;
  version: number;
  taxName: string;
  vatPayer: boolean;
  vatNonPayer: boolean;
  baseCatalogueVersionId: number;
}

export interface ICalcItemAttributes {
  id: number;
  code: number;
  version: number;
  attributeName: string;
  modifyDate: string;
  baseCatalogueVersionId: number;
}

export interface ICorrection {
  date: string;
  reason: string;
  type: number;
}

export interface IFormatAndLogicControlError {
  // идентификатор
  id: number;

  // Код ошибки, например, 1001
  code: number;

  // Описание ошибки, например, "Несоответствие версии ФФД"
  description: string;

  // Уровень критичности ошибки
  type: FormatAndLogicControlErrorsSeverityType;
}

export interface IFDErrors {
  [code: string]: number[];
}

export interface IReceipt {
  correction: ICorrection;
  dataFormatVersion: number;
  dateTime: string;
  documentFiscalMark: string;
  fdNumber: number;
  fnSerialNumber: string;
  id: string;
  crRegisterNumber: string;
  operationType: number;
  shiftNumber: number;
  supplierTin: string;
  taxCounters: ITaxCounter[];
  taxSystem: number;
  ticketNumber: number;
  ticketTotalCashSum: number;
  ticketTotalCashlessSum: number;
  ticketPrePaymentSum: number;
  ticketPostPaymentSum: number;
  ticketTotalSum: number;
  type: BackendReceiptTypes;
  tin: string;
  crData: {
    shiftNumber: number
  };
  errors: IFDErrors;
}

export interface IDetailedReceipt extends IReceipt {
  cashierName: string;
  items: IReceiptItem[];
  locationAddress: string;
  locationName: string;
  legalPersonName: string;
  legalPersonSupplierName: string;
  shiftId: string;
  crData: ICrData;
  originFdNumber: number;
  originFnSerialNumber: string;
}

export interface ICrData {
  cashierName: string;
  locationAddress: string;
  locationName: string;
  shiftNumber: number;
}

export interface ICrRegHistoryRecord {
  type: RegHistoryRecordType;
  dateTime: string;
  purchaseAddress: string;
  purchasePlace: string;
  counters: any;
  reason: string;
  taxSystems: number[];
  calcItemAttributes: number[];
  changeReason: number[];
  viewTaxSystems: string;
  viewVatPayer: string;
  viewAddress: string;
  viewCalcItemChanged: string;
  vatPayer: boolean;
  entrepreneurshipObject: number;
  businessActivity: number;
  taxAuthorityDepartment: number;
  viewEntrepreneurshipObject: string;
  viewBusinessActivity: string;
  viewTaxAuthorityDepartment: string;
}

export interface IReceiptList extends IListResult {
  items: IReceipt[];
}

export interface IStore1 {

}

export interface ILang {
  value: string;
  name: string;
}

export interface ISpringPageable {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: any;
}

export interface IPaginate {
  page: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}

export interface IPaginatorState {
  isDataLoaded: boolean;
  label: string;
  hasNextPage: boolean | number;
  hasPrevPage: boolean;
  pageNumber: number;
  totalPages: number;
  totalItems: number;
  currentStartOffset: number;
  currentEndOffset: number;
}

export interface ITokens {
  accessToken: string;
  accessTokenTTL: Date;
  refreshToken: string;
  refreshTokenTTL: Date;
}

export interface IStats {
  dayFiscalRegisters: number;
  dayTaxPayers: number;
  dayTickets: number;
  dayReturns: number;
  dayRevenue: number;
  dayTotal: number;
  dayVat: number;
  daySt: number;
  dayGovernmentFee: number;

  fiscalRegisters: number;
  taxPayers: number;
  tickets: number;
  returns: number;
  revenue: number;
  total: number;
  vat: number;
  st: number;
  governmentFee: number;
}

export interface ICrList {
  id: string;
  address: string;
  createdDate: string;
  regDate: string;
  modifyDate: string;
  status: KktStatusEnum;
  sn: string;
  taxSystems: number[];
  calcItemAttributes?: number[];
}

export interface IBaseCurrency {
  id: number;
  code: string;
  name: string;
  amount: number;
  sellExchangeRate: number;
  buyExchangeRate: number;
  modifyDate: string;
  hidden: boolean;
}

export interface ICurrency extends IBaseCurrency {
  isEdit: boolean;
  type: CurrencyType;
}

// очередные мудацкие счетчики
export interface ICounterItem {
  count: number;
  totalCash: number;
  totalCashless: number;
  totalPrePayment: number;
  totalPostPayment: number;
  totalGovernmentFee: number;
  totalRevenue: number;
  total: number;
}

export interface ICounters {
  INCOME: ICounterItem;
  INCOME_RETURN: ICounterItem;
  EXPENDITURE: ICounterItem;
  EXPENDITURE_RETURN: ICounterItem;
}

export interface IKktStat {
  avgTicket: number;
  income:  number;
  revenue: number;
  totalValue: number;
  ticketCorrectionCount: number;
  ticketIncomeCount: number;
  ticketRefundCount: number;
  totalCashIncome: number;
  totalCashRefund: number;
  totalCashlessIncome: number;
  totalCashlessRefund: number;
  totalCorrectIncome: number;
  totalCorrectRefund: number;
  totalIncome: number;
  totalRevenue: number;
  totalRefund: number;
  totalTicketCount: number;

  ticketCounters: ICounters;
  correctionCounters: ICounters;

  vat: any[];
  st: any[];
}
