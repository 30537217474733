<div class="d-flex flex-column" *transloco="let t; read: 'common.components.sales-analytics.receipts-summary'" #summaryEl>
  <form [formGroup]="form" (ngSubmit)="loadReceipts(true)" class="filter-container layout-container" [hidden]="!isFilterActive">
    <div class="d-flex flex-row">
      <div class='filter-container__filters row d-flex p_t-4 p_b-4'>

        <!-- Shift No -->
        <mat-form-field appearance="fill" class="kkm-form-field compact filter-container__shift-no p_l-0 p_r-3">
          <input matInput type="text" autocomplete="off" formControlName="shiftNumber" [placeholder]="t('shift-placeholder')">
          <button *ngIf="form.get('shiftNumber').value" type="button" class="kkm-button kkm-icon-button" matSuffix
                  mat-icon-button (click)="clearFormField($event, 'shiftNumber')">
            <mat-icon svgIcon="close:narrowInput" class="small"></mat-icon>
          </button>
        </mat-form-field>

        <!-- Datepicker -->
        <mat-form-field appearance="fill"
                        class="kkm-form-field kkm-field-error compact kkm-mat-datepicker filter-container__datepicker p_x-3"
                        floatLabel="never"
                        (click)="picker.open()">
          <mat-date-range-input formGroupName="date" [rangePicker]="picker" [min]="minDate" class="d-none"
                                [max]="maxDate" [separator]="end.value ? '-' : ''">
            <input matStartDate readonly formControlName="start" [placeholder]="t('date-placeholder')" #start>
            <input matEndDate readonly formControlName="end" #end>
          </mat-date-range-input>
          <span class="text-nowrap">
              <span class="field-placeholder" *ngIf="!startControl.value && !endControl.value">{{t('date-placeholder')}}</span>
              <kkm-date [date]="startControl.value" format="mediumDate"></kkm-date>
              <span *ngIf="stringDate(startControl.value) !== stringDate(endControl.value)">
                <span *ngIf="startControl.value && endControl.value"> - </span>
                <kkm-date [date]="endControl.value" format="mediumDate"></kkm-date>
              </span>
            </span>
          <mat-datepicker-toggle class="kkm-datepicker-toggle" *ngIf="!start.value && !end.value" matSuffix
                                 [for]="picker">
            <mat-icon matDatepickerToggleIcon svgIcon="datepicker:calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-icon *ngIf="start.value || end.value" class="medium mat-button p_x-0 m_l-2" matSuffix
                    matDatepickerToggleIcon svgIcon="close:narrowInput" (click)="clearDates($event)"></mat-icon>

          <mat-date-range-picker #picker (closed)="checkDates()"></mat-date-range-picker>
        </mat-form-field>

        <!-- Receipt Types (hidden) -->
        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field compact col p_r-3 d-none">
          <mat-select formControlName="receiptType" disableRipple="true" panelClass="kkm-select-panel__compact">
            <mat-option *ngFor="let type of receiptTypes; trackById" [value]="type.id">{{ t(type.name) }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Settlement Types -->
        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field compact col p_l-3 p_r-3">
          <mat-select formControlName="receiptOperationType" disableRipple="true" panelClass="kkm-select-panel__compact">
            <mat-option *ngFor="let type of receiptOperationTypes; trackById" [value]="type.id">{{ t(type.name) }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Receipts with Errors: All / Valid Only / Errors Only -->
        <mat-form-field floatLabel="never" appearance="fill" class="kkm-form-field compact col p_l-3 p_r-0"
                        [class.d-none]="analyticsSummaryType === analyticsSummaryTypes.BY_RECEIPTS_WITH_CRITICAL_ERRORS">
          <mat-select formControlName="errorsFilter" disableRipple="true" panelClass="kkm-select-panel__compact">
            <mat-option *ngFor="let state of fdErrorFilters; trackById" [value]="state.id">{{ t(state.name) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter-container__buttons d-flex justify-content-between align-items-center p_t-4 p_b-4">

        <button class="kkm-button narrow fixed-height filter-container__buttons_submit primary-color m_l-6"
                color="primary" mat-button type="submit"
                [disabled]="!isFilterChanged">
          {{t('apply-button')}}
        </button>

        <button *ngIf="areFiltersApplied"
                class="kkm-button reset-filter-button m_l-6" mat-icon-button
                (click)="resetFilter()" >
          <mat-icon svgIcon="close:dialog-currentColor"></mat-icon>
        </button>

      </div>
    </div>
  </form>
  <div *ngIf="isLoading && !receipts.length" class="w-fluid h-fluid p_x-6 p_y-4 preloader middle"></div>
  <kkm-no-data-label *ngIf="!isLoading && !this.receipts.length" class="body_text" [text]="t('no-receipts-found')"></kkm-no-data-label>
  <kkm-receipts-grid *ngIf="receipts.length"
                     [isLoading]="isLoading"
                     [data]="receipts"
                     [tin]="taxpayer.tin"
                     [columns]="gridColumns"
                     [type]="analyticsSummaryTypes.BY_RECEIPTS"
                     [isFilterActive]="isFilterActive"
                     [selectedReceiptId]="selectedReceiptId"
                     [isShowFdNumber]="isShowFdNumber"
                     (onRowClick)="openReceipt($event)"></kkm-receipts-grid>
</div>
